import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment"

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button
} from "reactstrap";
import AddModal from './modals/AddModal';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useUser from "hooks/useUser";
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import DataExportCSV from "./exports/DataExportCSV";
import useAsset from "hooks/useAsset";
import DetailModal from "./modals/DetailModal";
import EditModal from "./modals/EditModal";
import DeleteModal from "./modals/DeleteModal";
import ApprovalModal from "./modals/ApprovalModal";
import ExportFilterRowModal from "./modals/ExportFilterRowModal";
const DataTables = ({ id, lazyState, setlazyState, datas, totalRecords, expandedRows, setExpandedRows, loading, paramStatus }) => {
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const adminAction = (data) => {

        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <ApprovalModal rowData={data} />
                </div>
                {data.order_status_id == 1 &&
                    <div>
                        <EditModal rowData={data} />
                    </div>
                }
                {data.order_status_id == 1 &&
                    <div>
                        <DeleteModal rowData={data} />
                    </div>
                }
            </div>
        )
    }

    const photoDocumentations = (data) => {
        const documentFiles = JSON.parse(data.document_images);
        return (
            <ul>
                {documentFiles.length > 0 ? (
                    documentFiles.map((file, index) => (
                        <li key={index} style={{ marginBottom: '10px' }}>
                            <a href={file}>Documentation {index + 1}</a>
                        </li>
                    ))
                ) : (
                    <p>No Document</p>
                )}
            </ul>
        );
    }

    const inputDateTime = data => {
        return data.due_date ? moment(data.due_date).format("MM/DD/YYYY") : '-'
    }

    const inputWeight = data => {
        return simplify(data.weight, 3)
    }
    const inputPrice = data => {
        return currencyFormatter(data.price, 'IDR')
    }
    const inputCompany = data => {
        return data.company_id ? data.company_name : '-'
    }

    const inputRealWeight = data => {
        return <DetailModal data={data} />
    }
    const inputCreatedTime = data => {
        return data.created_at ? moment(data.created_at).format("MM/DD/YYYY H:mm") : '-'
    }

    const columns = [
        // { field: '', header: '#', body: incrementId },
        { field: 'order_code', header: 'Order ID', body: '', className: '' },
        // { field: 'purchase_id', header: 'Buyer Purchase Order Number', body: '', className: '' },
        { field: '', header: 'Company', body: inputCompany, className: '' },
        // { field: 'customer_name', header: 'Name', body: '', className: '' },
        // { field: 'customer_phone', header: 'Phone Number', body: '', className: '' },
        // { field: 'customer_email', header: 'E-mail', body: '', className: '' },
        // { field: '', header: 'Qty In Kg', body: inputWeight, className: '' },
        { field: '', header: 'Quantity (kg)', body: inputWeight, className: '' },
        { field: '', header: 'Actual Weight (kg)', body: inputRealWeight, className: '' },
        // { field: '', header: 'Price/Liter (IDR)', body: inputPrice, className: '' },
        { field: '', header: 'Due Date', body: inputDateTime, className: '' },
        { field: '', header: 'Created Time', body: inputCreatedTime, className: '' },
        { field: 'orderstatus_name', header: 'Status', body: '', className: '' },
        paramStatus == 'active' ? { field: '', header: 'Action', body: adminAction, className: '' } : {},

    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const rowClassName = (rowData) => {
        if (rowData.active == 'INACTIVE') {
            return 'redBg';
        } else if (rowData.active == 'DECOMISSIONED') {
            return 'warningBg';
        }

        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <div className="d-flex flex-row justify-content-around p-3">
                    <div>
                        <h5>Customer Information</h5>
                        <ul>
                            <li>Company : {data.company_name ?? '-'}</li>
                            <li>Name : {data.customer_name}</li>
                            <li>Phone : {data.customer_phone}</li>
                            <li>Email : {data.customer_email}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <h5>Order Information</h5>
                        <ul>
                            <li>Order ID : {data.order_code}</li>
                            <li>Buyer Purchase Order Number : {data.purchase_id}</li>
                            {/* <li>Qty In Kg : {inputWeight(data)}</li> */}
                            <li>Quantity (kg) : {inputWeight(data)}</li>
                            <li>Actual Weight (kg) : {inputRealWeight(data)} &nbsp;
                                <ExportFilterRowModal
                                    rowData={data}
                                    totalRecords={data.real_weight ?? 0}
                                    lazyState={lazyState}
                                    anonymous={true}
                                /></li>
                            {/* <li>Price/liter (IDR): {inputPrice(data)}</li>
                            <li>Total Price (IDR): {inputTotalPrice(data)}</li> */}

                            <li>Due Date : {inputDateTime(data)}</li>
                            <li>Documentation : {photoDocumentations(data)}
                            </li>
                        </ul>


                    </div>
                </div>
            </div>
        );
    };
    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                {/* <AddModal /> */}
                            </Col>
                            <Col md={5} xs={10}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Order Code / Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={2}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV paramStatus={paramStatus} lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : datas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={loading ? '' : 'id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={i} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DataTables
