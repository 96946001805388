import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import { IDRFormatter } from "helpers/functions"
import userIcon from "../../../assets/images/services-icon/active-user.png"
import totalBalanceIcon from "../../../assets/images/services-icon/analysis.png"
import deployedBoxes from "../../../assets/images/services-icon/deployed-boxes.png";
import payoutIcon from "../../../assets/images/services-icon/the-sum-of.png"

const OverviewCards = ({ overviewData }) => {
    return (
        <>
            <p>Overview</p>
            <Row>
                {/* USER PARTNER COUNT */}
                <Col lg={"5col"} md={6} sm={6}>
                    <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                            <div className="mb-4">
                                <div className="float-start mini-stat-img me-3">
                                    <img src={userIcon} alt="" />
                                </div>
                                <br></br>
                                <h5
                                    className="font-size-14 mt-0 text-white-50"
                                    style={{ overflowWrap: "normal" }}
                                >
                                    Valid User Partner
                                </h5>
                                <h4 className="fw-medium font-size-24">
                                    {overviewData?.total_user_partners || 0}
                                </h4>
                            </div>
                            <div className="pt-2">
                                <div className="float-end">
                                    <Link to="#" className="text-white-50">
                                        <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                    </Link>
                                </div>
                                <p className="text-white-50 mb-0 mt-1"></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                {/* USER PARTNER TOTAL RP */}
                <Col lg={"5col"} md={6} sm={6}>
                    <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                            <div className="mb-4">
                                <div className="float-start mini-stat-img me-3">
                                    <img src={totalBalanceIcon} alt="" />
                                </div>
                                <br></br>
                                <h5
                                    className="font-size-14 mt-0 text-white-50"
                                    style={{ overflowWrap: "normal" }}
                                >
                                    User Partner Total Rp.
                                </h5>
                                <h4 className="fw-medium font-size-24">
                                    {IDRFormatter(
                                        overviewData?.total_user_partner_share ||
                                            0
                                    )}
                                </h4>
                            </div>
                            <div className="pt-2">
                                <div className="float-end">
                                    <Link to="#" className="text-white-50">
                                        <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                    </Link>
                                </div>
                                <p className="text-white-50 mb-0 mt-1"></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                {/* LOCATION PARTNER COUNT */}
                <Col lg={"5col"} md={6} sm={6}>
                    <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                            <div className="mb-4">
                                <div className="float-start mini-stat-img me-3">
                                    <img src={deployedBoxes} alt="" />
                                </div>
                                <br></br>
                                <h5
                                    className="font-size-14 mt-0 text-white-50"
                                    style={{ overflowWrap: "normal" }}
                                >
                                    Valid Location Partner
                                </h5>
                                <h4 className="fw-medium font-size-24">
                                    {overviewData?.total_location_partners || 0}
                                </h4>
                            </div>
                            <div className="pt-2">
                                <div className="float-end">
                                    <Link to="#" className="text-white-50">
                                        <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                    </Link>
                                </div>
                                <p className="text-white-50 mb-0 mt-1"></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                {/* LOCATION PARTNER TOTAL RP */}
                <Col lg={"5col"} md={6} sm={6}>
                    <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                            <div className="mb-4">
                                <div className="float-start mini-stat-img me-3">
                                    <img src={totalBalanceIcon} alt="" />
                                </div>
                                <br></br>
                                <h5
                                    className="font-size-14 mt-0 text-white-50"
                                    style={{ overflowWrap: "normal" }}
                                >
                                    Location Partner Total Rp.
                                </h5>
                                <h4 className="fw-medium font-size-24">
                                    {IDRFormatter(
                                        overviewData?.total_location_partner_share ||
                                            0
                                    )}
                                </h4>
                            </div>
                            <div className="pt-2">
                                <div className="float-end">
                                    <Link to="#" className="text-white-50">
                                        <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                    </Link>
                                </div>
                                <p className="text-white-50 mb-0 mt-1"></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                {/* TOTAL PAYOUT */}
                <Col lg={"5col"} md={6} sm={6}>
                    <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                            <div className="mb-4">
                                <div className="float-start mini-stat-img me-3">
                                    <img src={payoutIcon} alt="" />
                                </div>
                                <br></br>
                                <h5
                                    className="font-size-14 mt-0 text-white-50"
                                    style={{ overflowWrap: "normal" }}
                                >
                                    Total Payout
                                </h5>
                                <h4 className="fw-medium font-size-24">
                                    {IDRFormatter(
                                        overviewData?.total_share || 0
                                    )}
                                </h4>
                            </div>
                            <div className="pt-2">
                                <div className="float-end">
                                    <Link to="#" className="text-white-50">
                                        <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                    </Link>
                                </div>
                                <p className="text-white-50 mb-0 mt-1"></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default OverviewCards
