import { useEffect, useRef, useState } from "react"
import {
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Skeleton } from "primereact/skeleton"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { ROWS_PER_PAGE } from "constants/value";
import { Link } from "react-router-dom"
import "../../../../assets/css/style.css"

const LocationsBoxModal = ({ rowData, options }) => {
    const toast = useRef(null);

    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const tog_center = () => {
        setmodal_center(!modal_center);
        setlazyState(initialLazyState);
        removeBodyCss();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const incrementId = (data, index) => {
        return index.rowIndex + 1;
    }

    const boxNumberBody = (data) => {
        return <Link to={`/deployments/${data?.id}`}>{data?.number}</Link>
    }

    const boxNameBody = (data) => {
        return <Link to={`/deployments/${data?.id}`}>{data?.name}</Link>
    }

    const addressBody = (data) => {
        return <Link to={`/deployments/${data?.id}`}>{data?.address}</Link>
    }

    const fillLevelBody = (data) => {
        // if (!data.gpsLatitude) {
        //     return <Link to={`/deployments/${data?.id}`}>0%</Link>
        // }

        if (!(data?.leakage || false )) {
            return <Link to={`/deployments/${data?.id}`}>
                {`${Math.round((data.lUCOHold + Number.EPSILON) * 100) / 100}%`}
            </Link>
        } else {
            return (
                <Link to={`/deployments/${data?.id}`}>
                    <span class="danger-important-text">
                        {`${Math.round((data.lUCOHold + Number.EPSILON) * 100) / 100}%`}
                    </span>
                    <br></br>
                    <span className={`badge bg-danger text-t-danger`}>
                        LEAK
                    </span>
                </Link>
            )
        }
    };

    const statusBody = (data) => {
        let badgeText = data?.status;
        let badgeStatus = '';
        let badgeHidden = "d-none";

        if (data.hidden) {
            badgeHidden = "";
        }

        if (data.status === 'active' ) {
            if (data.isOpen)
                badgeStatus = `open`;
            else
                badgeStatus = `closed`;
        }

        return (
            <Link to={`/deployments/${data?.id}`}>
                <span className={`badge bg-${data.status} text-t-${data.status}`}>
                    {badgeText}
                </span>
                <br />
                <span className={`badge bg-${badgeStatus == 'open' ? data.status : "offline"} text-t-${badgeStatus == 'open' ? data.status : "offline"}`}>
                    {badgeStatus}
                </span>
                <span className={`badge bg-hidden text-t-hidden ${badgeHidden}`}>
                    hidden
                </span>
            </Link>
        )
    }

    const getBoxHistory = async () => {
        try {
            setLoading(true);

            let queryParams = `boxIds=${rowData?.detail?.box_ids}`;

            const response = await axiosApi.get(`/api/admin/partner-location-boxes?${queryParams}`);
            const result = response.data.result;

            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (modal_center) {
            getBoxHistory()
        };
    }, [modal_center, lazyState, options]);

    const columns = [
        // { field: 'number', header: '#', body: incrementId },
        { field: 'box_number', header: 'Box Number', body: boxNumberBody, className: 'text-center', style: { maxWidth: '50px' }, },
        { field: 'box_name', header: 'Box Name', body: boxNameBody, className: '', style: { maxWidth: '80px' }, },
        { field: 'address', header: 'Address', body: addressBody, className: '', style: { maxWidth: '200px' }, },
        { field: 'fill_level', header: 'Fill Level', body: fillLevelBody, className: '', style: { maxWidth: '50px' }, },
        { field: 'status', header: 'Status', body: statusBody, className: '', style: { maxWidth: '50px' }, },
    ]

    return (
        <>
            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={tog_center}>
                {rowData?.detail?.box_ids?.length || 0}
            </span>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={"xl"}>
                <ModalHeader
                    className="mt-0 with-close"
                    toggle={tog_center}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        Location Partner : {rowData?.partner_account} - Details Box
                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <DataTable
                        // lazy
                        removableSort
                        value={loading ? items : trans}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        // onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        scrollable
                        scrollHeight='500px'
                        dataKey={loading ? '' : 'box_number'}
                    >
                        {columns.map((col, i) => {
                            return (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    headerClassName={col.headerClassName}
                                    body={loading ? <Skeleton> </Skeleton> : col.body}
                                    style={col.style}
                                    bodyClassName={col.className}
                                />
                            )
                        })}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default LocationsBoxModal
