import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText } from "reactstrap"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from 'primereact/inputswitch';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import useAdminPartner from "hooks/useAdminPartner"

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import { update } from "lodash";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import InputMask from "react-input-mask";

const AddAdminPartnerModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setRefreshAdminPartners, brandList } = useAdminPartner();
    const { showToast } = useAuth();
    const [permissions, setPermissions] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [selectedPrefix, setSelectedPrefix] = useState("+62");
    const [selectedBrand, setSelectedBrand] = useState("mypertamina");
    const [prefixList, setPrefixList] = useState([]);
    const [superAdmin, setSuperAdmin] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [userDataSharing, setUserDataSharing] = useState(true);

    const getPermissions = async () => {
        try {
            const response = await axiosApi.get('/api/admin/permission');
            response.data.result.forEach(element => {
                if (element.title.toLowerCase() == 'superadmin')
                    setSuperAdmin(element.key)
            });
            setPermissions(response.data.result);
        } catch (err) {
            console.log(err);
        }
    }

    const getCountryCodes = async () => {
        try {
            const response = await axiosApi.get('/api/v1/app/countrylist');
            var prefixes = []

            if (response.data)
                response.data.forEach(element => {
                    var prefix = {
                        ...element,
                        value: element.phonePrefix,
                    }
                    prefixes.push(prefix)
                })
            setPrefixList(prefixes);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if(modal_center){
            getPermissions();
            getCountryCodes();
        }
    }, [modal_center]);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setPhoneNumber(null);
        setSelectedPrefix("+62");
        setSelectedBrand('mypertamina')
        setShowPassword(false);
        setUserDataSharing(true)
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const handlePhoneNumber = (e) => {
        let { name, value } = e.target;
        if (value.startsWith("0")) {
            value = value.slice(1); // Remove the first character
        }
        setPhoneNumber(value);
        validation.setFieldValue(value); // Update formik's value
    }

    const handleUsernameChange = (e) => {
        const { name, value } = e.target;
        const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, '');
        validation.setFieldValue(name, alphanumericValue);
    };

    const toggleUserDataSharing = (e) => {
        setUserDataSharing(e.value)
        validation.setFieldValue("brand", e.value);
    }

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            username: '',
            phone_number: '',
            email: '',
            brand: 'mypertamina',
            password: '',
            data_sharing: true,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            username: Yup.string().required(
                "This value is required"
            ),
            phone_number: !phoneNumber
                ? Yup.string().required("This value is required")
                : Yup.string().nullable(),
            email: Yup.string()
                .email("Must be a valid Email")
                .max(255)
                .required("Email is required"),
            brand: Yup.string().required(
                "This value is required"
            ),
            password: Yup.string().required(
                "This value is required"
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = {
                    ...values,
                    phone_number: phoneNumber ? selectedPrefix + " " + phoneNumber : "",
                }

                const response = await post('/api/admin/brand/register', opts);
                setRefreshAdminPartners(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    const changePrefix = (e) => {
        setSelectedPrefix(e.value);
    };

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add New Partner Dashboard User </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Partner Dashboard User</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        
                        {/* INPUT NAME */}
                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* END OF INPUT NAME */}

                        {/* INPUT USERNAME */}
                        <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                                name="username"
                                placeholder="Enter Username"
                                type="text"
                                onChange={validation.handleChange && handleUsernameChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                    validation.touched.username && validation.errors.username ? true : false
                                }
                            />
                            {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                        </div>
                        {/* END OF INPUT USERNAME */}

                        {/* INPUT PHONE NUMBER */}
                        <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <div className="d-flex">
                                <Dropdown value={selectedPrefix} onChange={changePrefix} options={prefixList} optionLabel="name" className="h-1 payment-status-dropdown me-3" />
                                <InputGroup className={validation.touched.phone_number && validation.errors.phone_number ? 'is-invalid' : ''}>
                                    <InputGroupText>
                                        {selectedPrefix}
                                    </InputGroupText>
                                    <InputMask
                                        placeholder="Enter Phone Number"
                                        value={phoneNumber}
                                        mask="999999999999999"
                                        onChange={handlePhoneNumber}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.phone_number && validation.errors.phone_number ? true : false
                                        }
                                        maskChar={null}
                                    >
                                        {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                                    </InputMask>
                                    {validation.touched.phone_number && validation.errors.phone_number ? (
                                        <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                                    ) : null}
                                </InputGroup>
                            </div>
                        </div>
                        {/* END OF INPUT PHONE NUMBER */}

                        <div className="mb-3">
                            <div className="row">
                                {/* INPUT BRAND */}
                                <div className="col-md-4">
                                    <Label className="form-label">Partner</Label>
                                    <div className="d-flex mb-2">
                                        <Dropdown
                                            value={validation.values.brand}
                                            onChange={(e) => {
                                                setSelectedBrand(e.value);
                                                validation.setFieldValue("brand", e.value);
                                            }}
                                            options={brandList}
                                            optionLabel="name"
                                            className='h-1 payment-status-dropdown'
                                        />
                                    </div>
                                </div>
                                {/* END OF INPUT BRAND */}

                                {/* INPUT EMAIL */}
                                <div className="col-md-8">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        placeholder="Enter Valid Email"
                                        type="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* END OF INPUT EMAIL */}
                            </div>
                        </div>

                        {/* INPUT PASSWORD */}
                        <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <InputGroup>
                                <Input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                    invalid={
                                        validation.touched.password && validation.errors.password ? true : false
                                    }
                                />
                                <InputGroupText role="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} />
                                </InputGroupText>
                                {validation.touched.password && validation.errors.password ? (
                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}
                            </InputGroup>
                        </div>
                        {/* END OF INPUT PASSWORD */}

                        <div className="d-flex flex-wrap gap-2 justify-content-between">
                                <div className="d-flex align-items-center gap-2">
                                    <Label className="form-label mb-0">User Data Sharing</Label>
                                    <InputSwitch
                                        checked={userDataSharing}
                                        onChange={(e) => {
                                            setUserDataSharing(e.value)
                                            validation.setFieldValue("data_sharing", e.value)
                                        }}
                                    />
                                </div>

                                <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                    Submit
                                </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddAdminPartnerModal
