import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";
import { Button as PButton, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';

const TransactionHistoryExportCSV = ({ lazyState, rowData, options, type }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedAnonymous, setSelectedAnonymous] = useState(true);
    const [fieldList, setFieldList] = useState([]);
    const [headerFilter, setHeaderFilter] = useState([]);
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
        setFieldList([]);
    };
 
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `noov-com-${rowData?.partner_account} Location Partnership Transactions${options.periodName ? `_${options.periodName}` : ''}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${date}.csv`

    const headers = [
        { label: "ID", key: "TA_ID" },
        { label: "Box", key: "box.name" },
        { label: "User", key: "user.name" },
        { label: "Username", key: "user.username" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Rejected Weight (kg)", key: "TA_Slops_Weight" },
        { label: "Rejected Volume (liter)", key: "TA_Slops_Volume" },
        { label: "Currency", key: 'currency' },
        { label: "Price", key: "price" },
        { label: "Amount", key: "amount" },
        { label: "Start", key: "TA_Start_Time" },
        { label: "End", key: "TA_End_Time" },
        { label: "Address", key: "address.address" },
        { label: "Postcode", key: "address.postcode" },
        { label: "City", key: "address.city" },
        { label: "Country", key: "address.country" },
        { label: "Latitude", key: "address.lat" },
        { label: "Longitude", key: "address.long" },
        { label: "Method", key: "method" },
        { label: "Alcohol Level", key: "extraData.alcohol_level" },
        { label: "Hydrocarbon Level", key: "extraData.hydrocarbon_level" },
        { label: "Raw Max Level", key: "extraData.raw_max_level" },
        { label: "Raw Interface Level", key: "extraData.raw_interface_level" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])


    const updateFieldList = (value) => {
        setFieldList(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
        handleSelectAllValue();
    };

    const handleSelectAllValue = () => {
        var status = true;
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                status = false;
            }
        });
        const selectAllCheckbox = document.getElementById('select-all');
        selectAllCheckbox.checked = status;
    };

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setFieldList([]);
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
            updateFieldList(checkbox.value);
        });

    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const filteredHeaders = headers.filter(header =>
                    fieldList.includes(header.key)
                );
                setHeaderFilter(filteredHeaders);
                await fetchDataReq();
                tog_center();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `partner_location_id=${rowData?.id}&rows=10000&searchText=${lazyState.searchText}&isAnonymize=${selectedAnonymous}`;

            if (type == 'monthly') {
                queryParams += `&startDate=${startDate}&endDate=${endDate}`;
            } else {
                queryParams += `&startDate=${options.startDate}&endDate=${options.endDate}`;
            }
            const response = await axiosApi.get(`/api/admin/partner-transactions/location-partner?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.TA_UCO_Weight = obj.UCO_Approved ? simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight) : 0;
                obj.TA_UCO_Volume = obj.UCO_Approved ? simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume) : 0;
                obj.TA_Slops_Weight = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight);
                obj.TA_Slops_Volume = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume);
                obj.address.lat = obj.address.lat || obj?.box?.location?.latitude || "";
                obj.address.long = obj.address.long || obj?.box?.location?.longitude || "";
                obj.address.address = obj.address?.address?.replace(/\n/g, ', ') || "";
                obj.currency = rowData?.user?.currency;
                if (obj.TA_UCO_Volume === 0) {
                    obj.price = 0;
                } else {
                    obj.price = obj.amount ? simplify(obj.amount / obj.TA_UCO_Volume) : 0;
                }
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => tog_center()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headerFilter}
            >
            </CSVLink>
            <Modal isOpen={modal_center} toggle={tog_center} size="md" centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    <Label>Export as CSV</Label>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Export Field</Label>
                            <FormGroup check>
                                <Input
                                    id="select-all"
                                    name="select-all"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                />
                                <Label htmlFor="select-all">Select All</Label>
                            </FormGroup>

                            <FormGroup className="d-flex flex-wrap">
                                {headers?.map((header) => (
                                    <FormGroup check key={header.key} style={{ flex: '0 0 50%' }}>
                                        <Input id={header.key} name="header[]" type="checkbox" value={`${header.key}`} onChange={(e) => {
                                            const clickedValue = e.target.value;
                                            updateFieldList(clickedValue);
                                        }} />
                                        <Label htmlFor={header.key}>{header.label}</Label>
                                        {validation.touched.header && validation.errors.header ? (
                                            <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        {
                            <div className="mb-3">
                                <Label className="form-label">Anonymous</Label><br />
                                <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                            </div>
                        }
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <PButton type="submit" color="primary" disabled={validation.isSubmitting || fieldList.length === 0}>
                                Submit
                            </PButton>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>

    )
}

export default TransactionHistoryExportCSV