import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row } from "reactstrap";

import "chartist/dist/scss/chartist.scss";
import { Link, useParams } from "react-router-dom";
import PaymentPeriodModal from "pages/PaymentPeriodModal";

//i18n
import { withTranslation } from "react-i18next";
import LocationPartnersTable from './LocationPartnersTable';
import { useLocation } from "react-router-dom";
import usePartner from "hooks/usePartner";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const LocationPartners = () => {
  const axiosApi = useAxiosPrivate();
  const { partnerRefresh, setDataLoading, setDataTable, setDataTotalRecords } = usePartner();
  const [selectedPeriodName, setSelectedPeriodName] = useState('All Time');
  const [sortField, setSortField] = useState(null);
  
  const { id } = useParams();
  const location = useLocation();
  const propsData = location.state;

  const [lazyState, setlazyState] = useState({
    searchText: propsData || id || '',
    periodName: selectedPeriodName,
    startDate: '',
    endDate: '',
    config: {
      first: 0,
      rows: 25,
      page: 0,
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });

  const getLocationPartners = async () => {
    setDataLoading(true)
    try {
      // let queryParams = `rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
      let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
      if (sortField) {
        queryParams += `&sorts=${sortField}`;
      }
      // let queryParams = `rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`;
      const response = await axiosApi.get(`/api/admin/partner-locations?${queryParams}`);
      // const response = {
      //   data:
      //   {
      //     "status": "success",
      //     "message": "success",
      //     "totalRecords": 3,
      //     "result": [
      //       {
      //         "id": "abc123",
      //         "partner_account": "Partner Name",
      //         "total_collected_volume": 0,
      //         "share_ratio": 0,
      //         "total_share": 0,
      //         "detail": {
      //           "box_ids": [
      //             "655c225b4374db5ce79dd3f6",
      //             "65a0bbe647055108c5755621"
      //           ],
      //           "compensation": "fixed_revenue_share",
      //           "fixed_monthly_price": "122112",
      //           "share_tier": {
      //             "share_tier_1": {
      //               "min": "0",
      //               "max": "1",
      //               "percentage": "0"
      //             },
      //             "share_tier_2": {
      //               "min": "1",
      //               "max": "22",
      //               "percentage": "2.5"
      //             },
      //             "share_tier_3": {
      //               "min": "22",
      //               "max": "33",
      //               "percentage": "5"
      //             },
      //             "share_tier_4": {
      //               "min": "33",
      //               "max": "44",
      //               "percentage": "10"
      //             }
      //           }
      //         }
      //       }
      //     ]
      //   }
      // }
      const result = response.data.result;
      setDataTable(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setDataTotalRecords(response.data.totalRecords)
      } else {
        setDataTotalRecords(0)
      }
    } catch (error) {
      console.log(error);
    }
    setDataLoading(false)

  }

  useEffect(() => {
    getLocationPartners();
  }, [lazyState, partnerRefresh, sortField]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Location Partners`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/*<Breadcrumbs title="Support" breadcrumbItem="LocationPartners" />*/}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8} sm={12}>
                <h6 className="page-title">Location Partners</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Partners</a></li><li className="active breadcrumb-item" aria-current="page">Location Partners</li></ol>
              </Col>

              <Col md={4} sm={12}>
                <div> {/* Auto width for PaymentPeriodModal */}
                  <PaymentPeriodModal
                    mainOptions={lazyState}
                    setMainOptions={setlazyState}
                    selectedPeriodName={selectedPeriodName}
                    setSelectedPeriodName={setSelectedPeriodName}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <LocationPartnersTable id={propsData} lazyState={lazyState} setlazyState={setlazyState} setSortField={setSortField} searchId={id} />
        </div>
      </div>
    </React.Fragment>
  );
};

LocationPartners.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(LocationPartners);
