import React, { useState, useEffect, useRef } from "react"
import { CSVLink } from "react-csv"
import { Button } from "primereact/button"
import usePartner from "hooks/usePartner"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import moment from "moment"
import makeid from "helpers/random"

const DataExportCSV = ({ lazyState, period }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const { eligibleClicked } = usePartner()
    const axiosApi = useAxiosPrivate()
    const csvRef = useRef()

    let formattedPeriod = moment(period).format("YYYY-MM")
    let filename = `Finance_Summary_Only_Eligible_${formattedPeriod}.csv`

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log("data is empty")
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true)

        try {
            const response = await axiosApi.get(
                `/api/admin/export-partner-payments?period=${formattedPeriod}&only_status=eligible`
            )

            const result = response.data
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            console.log(error.response.data)
            setLoading(false)
            console.log(error)
        }

        setLoading(false)
    }

    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${
                    loading === true ? "mdi-loading mdi-spin" : "mdi-file"
                }`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: "top" }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: "none" }}
                ref={csvRef}
                filename={filename}
            />
        </>
    )
}

export default DataExportCSV
