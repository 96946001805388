import React from "react"
import { Link } from "react-router-dom"

import { useState, useRef, useCallback, useEffect } from "react"

import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { InputText } from "primereact/inputtext"

import avatar from "../../../../assets/images/users/user-blank.png"
import alfamartLogo from "../../../../assets/images/partner/alfamartLogo.svg"
import mypertaminaLogo from "../../../../assets/images/partner/mypertamina.svg"


import PropTypes from "prop-types"
import useAuth from "hooks/useAuth"

import AddAdminPartnerModal from "./modals/AddAdminPartnerModal"
import EditAdminPartnerModal from "./modals/EditAdminPartnerModal"
import DeleteAdminPartnerModal from "./modals/DeleteAdminPartnerModal"

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Skeleton } from "primereact/skeleton"
import useAdminPartner from "hooks/useAdminPartner"
import moment from "moment"

import { inputDateTime, debounce } from "helpers/functions"

const AdminPartnerTable = ({ id, lazyState, setLazyState }) => {
    const lazyStateRef = useRef(lazyState)

    useEffect(() => {
        lazyStateRef.current = lazyState
    }, [lazyState])

    const {
        adminPartners = [],
        adminPartnersLoading,
        setAdminPartnersLoading,
    } = useAdminPartner()
    const { auth } = useAuth()

    const items = Array.from({ length: 5 }, (v, i) => i)

    const [search, setSearch] = useState(id || "")

    const delay = 1000

    const changeFilter = value => {
        const _filters = { ...lazyStateRef.current }
        _filters.searchText = value
        setLazyState(_filters)
    }

    const debouncedSearch = useCallback(debounce(changeFilter, delay), [])

    const onSearchTextChange = e => {
        const value = e.target.value
        setSearch(value)
        setAdminPartnersLoading(true)
        debouncedSearch(value)
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1
    }

    const badgeStatus = data => {
        return (
            <span
                className={`text-uppercase badge bg-${data.status} text-t-${data.status}`}
            >
                {data.status === "active" ? "Active" : "Not Active"}
            </span>
        )
    }

    const adminName = data => {
        return (
            <div className="d-flex flex-column">
                <img
                    src={data?.img || avatar}
                    alt={data.name}
                    className="avatar-xs rounded-circle me-2"
                />{" "}
                {data.name}
            </div>
        )
    }

    const brandBody = data => {
        return (
            <>
                <img
                    src={
                        data?.brand === "mypertamina"
                            ? mypertaminaLogo
                            : alfamartLogo
                    }
                    alt="Logo's Partner"
                    style={{ width: "90px" }}
                />
            </>
        )
    }

    const roleList = data => {
        const roles = data.level.split(",")

        // Check if 'superadmin' is present and replace with 'admin'
        if (roles.length > 0) {
            return (
                <div>
                    {roles.map((role, index) => {
                        if (role === "superadmin") {
                            roles[index] = "admin"
                        }
                        return <div key={index}>{role}</div>
                    })}
                </div>
            )
        }

        return roles
    }

    const adminAction = data => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditAdminPartnerModal admin={data} />
                </div>
                <div>
                    <DeleteAdminPartnerModal admin={data} />
                </div>
            </div>
        )
    }

    const columns = [
        { field: "", header: "#", body: incrementId },
        { field: "name", header: "Name", body: adminName },
        { field: "username", header: "Username", body: "" },
        { field: "email", header: "Email", body: "" },
        { field: "phone_number", header: "Phone Number", body: "" },
        { field: "brand", header: "Brand", body: brandBody },
        { field: "joined", header: "Joined", body: inputDateTime },
        // { field: "level", header: "Role", body: roleList },
        // { field: "status", header: "Status", body: badgeStatus },
        { field: "action", header: "Action", body: adminAction },
    ]

    const filteredAdmins = Array.isArray(adminPartners)
        ? adminPartners.filter(admin =>
              ["_id", "name", "username", "email", "phone_number"].some(key =>
                  admin[key]?.toLowerCase().includes(search.toLowerCase())
              )
          )
        : []

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col
                                md={12}
                                lg={7}
                                className="d-flex flex-column flex-md-row align-items-center gap-0 gap-md-3"
                            >
                                <AddAdminPartnerModal />
                                <Link
                                    to="https://partner.noovoleum.com/"
                                    className="btn btn-secondary my-2"
                                    target="_blank"
                                    style={{
                                        width: '251px',
                                        // backgroundColor: 'grey',
                                        // color: 'white'
                                    }}
                                >
                                    Go To Partner Dashboard
                                    <i className="fa fa-link ms-2" />
                                </Link>
                            </Col>
                            <Col md={12} lg={5}>
                                <div className="d-flex justify-content-center justify-content-md-end my-2">
                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText
                                            value={search}
                                            onChange={onSearchTextChange}
                                            placeholder="Search Username or Name"
                                            style={{ width: "251px" }}
                                            className="p-inputtext-sm user-filter"
                                        />
                                    </span>
                                </div>
                            </Col>

                            {/* <Col md={1}> */}
                                {/* <div className="float-start d-block d-flex flex-row gap-2 export-icon"> */}
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                {/* </div> */}
                            {/* </Col> */}
                        </Row>
                        <DataTable
                            value={
                                adminPartnersLoading ? items : filteredAdmins
                            }
                            stripedRows
                            size={"normal"}
                        >
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={
                                        adminPartnersLoading ? (
                                            <Skeleton> </Skeleton>
                                        ) : (
                                            col.body
                                        )
                                    }
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default AdminPartnerTable
