import { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const BoxCollectionPoint = ({ data ,platform = "grab"}) => {
    const axiosApi = useAxiosPrivate();
    const userId = data.id;
    var isEnabled = true;
    if (platform == "dash") {
        if (data && data.hasOwnProperty('dashCollectionPoint')) {
            isEnabled = data.dashCollectionPoint;
        }
    } else {
        if (data && data.hasOwnProperty('collectionPoint')) {
            isEnabled = data.collectionPoint;
        }
    }

    const [collectionPoint, setCollectionPoint] = useState(isEnabled);
    const { showToast } = useAuth();

    const handleSwitchChange = async (value) => {
        setCollectionPoint(value);

        const grabBody = { phonePrefix: data.phone.prefix, phoneNumber:data.phone.number,collectionPoint: value ,id: userId , updatedField:"collectionPoint" };
        const dashBody = { phonePrefix: data.phone.prefix, phoneNumber:data.phone.number, dashCollectionPoint: value, id: userId ,updatedField:"dashCollectionPoint"};
        const response = await axiosApi.post('/api/admin/engineer/box/update-collection-point', (platform == "grab" ? grabBody : dashBody))
        showToast(response.data);
    };

    return (
        <div>
            <InputSwitch checked={collectionPoint} onChange={(e) => handleSwitchChange(e.value)} />
            {/* Other JSX */}
        </div>
    );
};

export default BoxCollectionPoint;
