import { Row, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import React, { useEffect, useState } from "react";
import axiosApi from 'helpers/api_helper';
import { Column } from "primereact/column";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { DataTable } from 'primereact/datatable';
import DecryptBank from "./DecryptBank.js"
import notFoundImage from "../../../assets/images/users/user-blank.png";

const UserDeviceModal = ({ user }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [userPaymentMethod, setUserPaymentMethods] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
    };
    const getUserPaymentMethod = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/support/userDevices/${user._id}`);

            setUserPaymentMethods(response.data.result);
            setTotalRecords(response.data.totalRecords)
        } catch (error) {
        }
    }

    useEffect(() => {
        getUserPaymentMethod();
    }, []);


    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const handleLogout = async (device_id) => {
        try {
            const response = await axiosApi.delete(`/api/admin/support/logoutDevice/${user._id}`);
            tog_center();
        } catch (error) {

        }
    }

    const actionBody = (data) => {
        return (
            <>
               <button className="btn btn-sm btn-danger" onClick={() => {handleLogout(data._id) }}>Log out</button>
            </>
        )
    }
    const osBody = (data) => {
        return (
            <>
                {data.device_os} <br></br>
                <span className="text-muted small">{data.device_os_version}</span>
            </>
        )
    }
    const modelBody = (data) => {
        return (
            <>
                {data.device_full_model_parsed}<br></br>
                <span className="text-muted small">{data.device_brand} {data.device_model}</span>
            </>
        )
    }

    const biometricBody = (data) => {
        return (
            <>
                {data?.biometric_enabled ? "✅" : "❌"}
            </>
        )
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'device_full_model_parsed', header: 'Model', body: modelBody, style: { minWidth: '8rem' } },
        { field: 'device_os', header: 'OS', body: osBody, style: {} },
        { field: 'app_version', header: 'App Version', body: "", style: {} },
        { field: 'biometric', header: 'Biometric', body: biometricBody, style: {} },
        { field: 'updatedAt', header: 'Last Used', body: inputDateTime, style: {} },
        // { field: 'action', header: 'Action', body: actionBody, style: {} },
    ]

    return (
        <>
            {totalRecords === 0 ? (
                <span>{totalRecords}</span> // If totalRecords is 0, display the count without a hyperlink
            ) : (
                <a href="#" onClick={tog_center}>
                    {totalRecords}
                </a> // If totalRecords is not 0, display the count as a hyperlink
            )}
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>User Devices</ModalHeader>
                <ModalBody>

                    <DataTable
                        // filters={filters}
                        // globalFilterFields={['name', 'address']}
                        value={loading ? items : userPaymentMethod}
                        stripedRows
                        showGridlines
                        size={'normal'}
                        // metaKeySelection={true}
                        // selectionMode={'single'}
                        selection={loading ? '' : userPaymentMethod}
                        scrollable
                        scrollHeight='439px'
                    >
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
                        ))}
                    </DataTable>
                </ModalBody>
                {/* <ModalFooter>
                   
                    <button type="button" className="btn btn-danger" onClick={handleLogout("all")}>Logout All Device</button>
            
                </ModalFooter> */}
            </Modal>
        </>
    )

}

export default UserDeviceModal
