import React, { useEffect, useState } from "react"

//Import Breadcrumb

import useAxiosPrivate from "hooks/useAxiosPrivate"
import useAdmin from "hooks/useAdmin"
import useAuth from "hooks/useAuth"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useParams } from "react-router-dom"
import CountryFilter from "pages/CountryFilter";

import servicesIcon1 from "../../../assets/images/services-icon/average-payout.png"
import promotionCredited from "../../../assets/images/services-icon/02.png"
import bonusCredited from "../../../assets/images/services-icon/bonus.png"
import totalPayouts from "../../../assets/images/services-icon/the-sum-of.png"
import totalBalanceIcon from "../../../assets/images/services-icon/analysis.png"
import PaymentTable from "./PaymentTable"
import usePayment from "hooks/usePayment"

import {
  currencyFormatter,
  simplify,
  balanceFormatter,
  IDRFormatter,
  getFormattedUTCDate
} from "helpers/functions"
// import PaymentPeriodModal from "./modals/PaymentPeriodModal"
import PaymentPeriodModal from "pages/PaymentPeriodModal";

import AyoconnectButton from "./button/ayoconnect.js"
import { CURRENT_WITHDRAWAL_PLATFORM } from "constants/value"
import BalanceButton from "./button/balance"

const FinancePayment = () => {
  const { id } = useParams();
  const { setIsMobile } = useAuth()
  const {
    totalOpen,
    setTotalOpen,
    ACBalance,
    setACBalance,
    totalRecords,
    setTotalRecords,
    totalAmounts,
    setTotalAmounts,
    refresh,
    totalUserBalance,
    setTotalUserBalance,
    setAutoWithdrawal,
    totalOpenIdr,
    setTotalOpenIdr,
    totalAmountIdr,
    setTotalAmountIdr,
    totalUserBalanceIdr,
    setTotalUserBalanceIdr,
    totalBonus,
    setTotalBonus,
    totalBonusIdr,
    setTotalBonusIdr,
    XDBalance,
    setXDBalance,
    AWXBalance,
    setAWXBalance,
    withdrawalPlatform,
    setWithdrawalPlatform,
    totalPromo,
    setTotalPromo,
    totalPromoIdr,
    setTotalPromoIdr
  } = usePayment()
  const [selectedCurrency, setSelectedCurrency] = useState('IDR');

  const [loading, setLoading] = useState(false)
  const [convertIdr, setConvertIdr] = useState(0)
  const [idParamStatus, setIdParamStatus] = useState(id ? false : true)
  const [expandedRows, setExpandedRows] = useState(null)
  const today = new Date()
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  endOfMonth.setHours(23, 59, 59, 999)
  const startDate = getFormattedUTCDate(startOfMonth)
  const endDate = getFormattedUTCDate(endOfMonth)
  const [lazyState, setLazyState] = useState({
    searchText: id || "",
    status: "all",
    startDate: startDate,
    endDate: endDate,
    config: {
      first: 0,
      rows: 25,
      page: 0,

      sortField: null,
      sortOrder: null,
      filters: {
        status: { value: "", matchMode: "contains" },
      },
    },
  })
  const [datas, setDatas] = useState([])
  const axiosApi = useAxiosPrivate()
  const [selectedPeriodName, setSelectedPeriodName] = useState('This Month');

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768) // Adjust the width value as needed
  }
  const handleChangeCountry = (currency) => {
    if (currency == "IDR") {
      setWithdrawalPlatform("XENDIT");
    } else if (currency == "SGD") {
      setWithdrawalPlatform("AIRWALLEX");
    }
  }

  const fetchRequests = async () => {
    try {
      const responseSetting = await axiosApi.get(
        "/api/admin/setting/get-app-settings"
      )
      if (responseSetting.data.setting) {
        const {
          setting: { auto_withdrawal },
        } = responseSetting.data
        setAutoWithdrawal(auto_withdrawal)
      }
      const responseCurrency = await axiosApi.get(
        `/api/admin/finance/get-currencies`
      )
      const currencies = responseCurrency.data.rates

      const url = `/api/admin/finance/v3/payment-request-list?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&status=${lazyState.status}&searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&currency=${selectedCurrency}`

      const response = await axiosApi.get(url)

      if (response.data.ACBalance > 0) {
        setConvertIdr(response.data.ACBalance / currencies[selectedCurrency])
      } else {
        setConvertIdr(0)
      }
      setTotalRecords(response.data.totalRecords || 0)
      setTotalAmounts(response.data.totalPayments || 0)
      setTotalAmountIdr((response.data.totalPayments || 0) * currencies[selectedCurrency])
      setTotalUserBalance(response.data.totalBalance || 0)
      setTotalUserBalanceIdr((response.data.totalBalance || 0) * currencies[selectedCurrency])
      setTotalBonus(response.data.totalBonus || 0)
      setTotalBonusIdr((response.data.totalBonus || 0) * currencies[selectedCurrency])
      setTotalPromo(response.data.totalPromo || 0)
      setTotalPromoIdr((response.data.totalPromo || 0) * currencies[selectedCurrency])
      setDatas(response.data.requests)
      setACBalance(response.data.ACBalance || 0)
      setXDBalance(response.data.XDBalance || 0)
      setAWXBalance(response.data.AWXBalance || 0)
      // setWithdrawalPlatform(
      //   response.data.withdrawalPlatform || CURRENT_WITHDRAWAL_PLATFORM
      // )
      setTotalOpen(response.data.averagePayments || 0)
      setTotalOpenIdr((response.data.averagePayments || 0) * currencies[selectedCurrency])
      setLoading(false)
      setTotalPromo(response.data.totalPromo || 0)
      setTotalPromoIdr((response.data.totalPromo || 0) * currencies[selectedCurrency])

      if (!idParamStatus) {
        if (response.data.requests.length > 0) {
          setExpandedRows({ [response.data.requests[0].reqId]: true })
          setIdParamStatus(true)
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      fetchRequests()
    }, 300)
  }, [lazyState, refresh, selectedCurrency])

  useEffect(() => {
    handleResize() // Initial check on component mount
    // Add event listener for window resize
    window.addEventListener("resize", handleResize)
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  document.title = `${process.env.REACT_APP_NAME || ""} | Accounting`
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Finance" breadcrumbItem="Revenue & Payment" /> */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={4} sm={4}>
                <h6 className="page-title">Accounting</h6>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Finance</a>
                  </li>
                  <li className="active breadcrumb-item" aria-current="page">
                    Accounting
                  </li>
                </ol>
              </Col>

              <Col
                md={8}
                sm={8}
                className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap"
              >
                <CountryFilter selectedFilter={selectedCurrency} setSelectedFilter={setSelectedCurrency} handleChangeCountry={handleChangeCountry} field='currency' />
                &nbsp;
                {/* <div className="float-md-end d-sm-block"> */}
                <div className="d-flex flex-column justify-content-between mb-3">
                  <div className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                      <BalanceButton
                        withdrawalPlatform={withdrawalPlatform}
                        balance={IDRFormatter(XDBalance)}
                        sgBalance={"SGD" + currencyFormatter(AWXBalance,"SGD")}
                        oldBalance={IDRFormatter(ACBalance)}
                      />
                    </div>

                  </div>
                </div>
                &nbsp;
                <PaymentPeriodModal
                  mainOptions={lazyState}
                  setMainOptions={setLazyState}
                  selectedPeriodName={selectedPeriodName}
                  setSelectedPeriodName={setSelectedPeriodName}
                />
                {/* <PaymentPeriodModal lazyState={lazyState} setLazyState={setLazyState} /> */}
                {/* </div> */}
              </Col>
            </Row>
          </div>

          <p>Overview</p>
          <Row>
            <Col lg={"5col"} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ padding: "1rem" }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <br></br>
                    <h5
                      className="font-size-14 mt-0 text-white-50"
                      style={{ overflowWrap: "normal" }}
                    >
                      Average&nbsp;Payout
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {balanceFormatter(simplify(totalOpenIdr) || 0, selectedCurrency)}
                    </h4>
                    <h5>($ {currencyFormatter(simplify(totalOpen) || 0)})</h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={"5col"} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ padding: "1rem" }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={totalPayouts} alt="" />
                    </div>
                    <br></br>
                    <h5
                      className="font-size-14 mt-0 text-white-50"
                      style={{ overflowWrap: "normal" }}
                    >
                      User&nbsp;Payouts
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {balanceFormatter(simplify(totalAmountIdr) || 0, selectedCurrency)}
                    </h4>
                    <h5>
                      (${" "}
                      {currencyFormatter(
                        (totalAmounts === 0 ? 0 : simplify(totalAmounts)) || 0
                      )}
                      )
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg={'5col'} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ 'padding': '1rem' }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <span style={{ paddingTop: '4px', display: 'inline-block' }}>
                      <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                        Disbursement<br />Balance
                      </h5>
                    </span>
                    <h4 className="fw-medium font-size-24">
                      Rp {currencyFormatter(ACBalance ?? 0, 'IDR')}
                    </h4>
                    <h5>

                      ($ {currencyFormatter(convertIdr ?? 0)})
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col lg={"5col"} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ padding: "1rem" }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={totalBalanceIcon} alt="" />
                    </div>
                    <br></br>
                    <h5
                      className="font-size-14 mt-0 text-white-50"
                      style={{ overflowWrap: "normal" }}
                    >
                      User&nbsp;Payables
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {balanceFormatter(
                        simplify(totalUserBalanceIdr) || 0,
                        selectedCurrency
                      )}
                    </h4>
                    <h5>
                      (${" "}
                      {currencyFormatter(
                        (totalUserBalance === 0
                          ? 0
                          : simplify(totalUserBalance)) || 0
                      )}
                      )
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={"5col"} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ padding: "1rem" }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={bonusCredited} alt="" />
                    </div>
                    <br></br>
                    <h5
                      className="font-size-14 mt-0 text-white-50"
                      style={{ overflowWrap: "normal" }}
                    >
                      Bonus&nbsp;Credited
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {balanceFormatter(simplify(totalBonusIdr) || 0, selectedCurrency)}
                    </h4>
                    <h5>
                      (${" "}
                      {currencyFormatter(
                        (totalBonus === 0 ? 0 : simplify(totalBonus)) || 0
                      )}
                      )
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={"5col"} md={6} sm={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody style={{ padding: "1rem" }}>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-1">
                      <img src={promotionCredited} alt="" />
                    </div>
                    <h5
                      className="font-size-14 mt-0 text-white-50"
                      style={{ overflowWrap: "normal" }}
                    >
                      Promotions<br></br>Credited
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {balanceFormatter(simplify(totalPromoIdr) || 0, selectedCurrency)}
                    </h4>
                    <h5>($ 
                      {currencyFormatter(
                        (totalPromo === 0 ? 0 : simplify(totalPromo)) || 0)
                      })
                    </h5>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5 d-none"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1"></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PaymentTable datas={datas} loading={loading} id={id} expandedRows={expandedRows} setExpandedRows={setExpandedRows} lazyState={lazyState} setLazyState={setLazyState} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default FinancePayment
