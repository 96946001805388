import '../../../assets/css/style.css'
import ReactApexChart from 'react-apexcharts';
import Select from "react-select";

import useOverview from "hooks/useOverview";
import React, { useEffect, useState } from "react"
import { currencyFormatter, simplify, formatDateTime, debounce, balanceFormatter, formatThousandSeparator} from "helpers/functions";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import PeriodFilterModal from './PeriodFilterModal';
import DetailModal from './modals/DetailModal';
import SyncModal from './modals/SyncModal';
const Inventory = () => {
    const offtakeText = 'Offtake';
    const stockText = 'Stock';
    const collectedText = 'Collected';
    const axiosApi = useAxiosPrivate();
    const { stocks, setStocks, stockCategories, setStockCategories, refresh, setLoading } = useOverview();
    const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };
    const today = new Date();
    const [currentStockWeight, setCurrentStockWeight] = useState(false);
    const [currentWeightUnit, setCurrentWeightUnit] = useState(false);
    const [currentStockVolume, setCurrentStockVolume] = useState('');
    const [currentVolumeUnit, setCurrentVolumeUnit] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    
    const [intakeTotalWeight, setIntakeTotalWeight] = useState(0);
    const [intakeTotalAmount, setIntakeTotalAmount] = useState(0);
    const [intakeWeightUnit, setIntakeWeightUnit] = useState('');
    const [offtakeTotalWeight, setOfftakeTotalWeight] = useState(0);
    const [offtakeTotalAmount, setOfftakeTotalAmount] = useState(0);
    const [offtakeWeightUnit, setOfftakeWeightUnit] = useState('');
    const [selectedPeriodName, setSelectedPeriodName] = useState('Last 30 Days');
    const [selectedFilter, setSelectedFilter] = useState(null)
    const [tanks, setTanks] = useState(null);
    const [lastSync, setLastSync] = useState(null);

    const start_date = new Date();
    start_date.setDate(today.getDate() - 29); // Subtract 59 days to include today
    const startDate = formatDate(start_date);
    const endDate = formatDate(today)
    const [modal_center, setmodal_center] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [lazyState, setlazyState] = useState({
        id: '',
        searchText: '',
        filterPeriod: 'month',
        startDate: startDate,
        endDate: endDate,
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });
    const items = Array.from({ length: 10 }, (v, i) => i);
    const getTanks = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/inventory/tank-location-options`);
            var results = response.data.results;
            const locList = Object.entries(results).map(([id, name]) => ({
                id,
                name
            }));
            let allBoxes = []
            allBoxes.unshift({ name: "All", value: "", id: '', capacity: 0, label: "All" })
            if (locList)
                locList.forEach((element, index) => {
                    var location = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: `${element.name}`

                    }
                    allBoxes.push(location);
                    if (index == 0 && selectedFilter == null) {
                        setSelectedFilter(allBoxes[0])
                    }
                });

            setTanks(allBoxes);

        } catch (error) {
            console.log(error);
        }
    }
    const getLastSync = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/settings/inventory_last_sync`);
            if (response?.data?.results?.value) {
                setLastSync(response?.data?.results?.value)
            } else {
                setLastSync(null)
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            setLastSync(null);

        }
    }

    const getChartData = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/inventory/stock-chart?tank_location=${lazyState.id}&start_date=${lazyState.startDate}&end_date=${lazyState.endDate}`);
            // console.log(convertData(lazyState.filterPeriod, response.data.results.chart_data));

            setCurrentStockWeight(response.data.results.chart_recap.last_weight);
            setCurrentWeightUnit(response.data.results.chart_recap.weight_unit);
            setCurrentStockVolume(response.data.results.chart_recap.last_volume);
            setCurrentVolumeUnit(response.data.results.chart_recap.volume_unit);
            setTotalAmount(response.data.results.chart_recap.totalAmount);

            setIntakeTotalWeight(response.data.results.chart_recap.intake_total_weight);
            setIntakeTotalAmount(response.data.results.chart_recap.intake_total_amount);
            setIntakeWeightUnit(response.data.results.chart_recap.intake_weight_unit);

            setOfftakeTotalWeight(response.data.results.chart_recap.total_offtake);
            setOfftakeTotalAmount(response.data.results.chart_recap.total_offtake_amount);
            setOfftakeWeightUnit(response.data.results.chart_recap.offtake_weight_unit);
            setStocks(
                // [{
                //     name: 'TEAM B',
                //     type: 'area',
                //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
                // }, {
                //     name: 'TEAM A',
                //     type: 'column',
                //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
                // },]
                [
                    {
                        name: stockText,
                        type: 'area',
                        data: response.data.results.chart_data
                    },
                    {
                        name: lazyState.id ? collectedText : offtakeText,
                        type: 'column',

                        data: lazyState.id ? response.data.results.chart_collected : response.data.results.chart_offtake
                    },


                ]
            );
            setStockCategories(
                {
                    chart: {
                        stacked: false,
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        },
                        stroke: {
                            width: [0.1, 2],
                            curve: 'smooth'
                        },
                        events: {
                            // dataPointSelection: function (event, chartContext, opts) {
                            //     const name = opts.w.config.series[opts.seriesIndex].name;
                            //     const value = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                            //     let dateClicked = opts.w.globals.categoryLabels[opts.dataPointIndex];
                            //     let dateRaw = response.data.results.chart_period_raw[opts.dataPointIndex];
                            //     setmodal_center(true)
                            //     var data = {
                            //         title: dateClicked,
                            //         date: dateRaw,
                            //         name: name,
                            //         value: value,
                            //         type_id: name == stockText ? 1 : name == offtakeText ? 2 : 3,
                            //         tank_id: lazyState.id
                            //     }
                            //     setModalData(data);
                            // },
                            markerClick: function (event, chartContext, opts) {
                                const name = opts.w.config.series[opts.seriesIndex].name;
                                const value = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
                                let dateClicked = opts.w.globals.categoryLabels[opts.dataPointIndex];
                                let dateRaw = response.data.results.chart_period_raw[opts.dataPointIndex];
                                setmodal_center(true)
                                var data = {
                                    title: dateClicked,
                                    date: dateRaw,
                                    name: name,
                                    value: value,
                                    type_id: name == stockText ? 1 : name == offtakeText ? 2 : 3,
                                    tank_id: lazyState.id
                                }
                                setModalData(data);
                            },

                        },
                    },
                    colors: ['#70ad47', '#0d756e'],
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: lazyState.filterPeriod == 'month' ? '50%' : '20%'
                        }
                    },

                    fill: {
                        type: ['gradient', 'solid'], // Set gradient fill for area series
                        // opacity: [0.85, 0.25],
                        gradient: {
                            shadeIntensity: 1,
                            type: "vertical",
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.25,
                            stops: [20, 100, 100, 100]
                        },
                    },
                    labels: response.data.results.chart_period,
                    legend: {
                        show: true
                    },

                    // tooltip: {
                    //     intersect: true,
                    //     shared: false,
                    // },
                    markers: {
                        size: 5,
                    },
                    xaxis: {
                        // axisBorder: {
                        //     show: false
                        // },
                        labels: {
                            show: true,
                            rotate: -70,
                            rotateAlways: true,
                            hideOverlappingLabels: true,
                            floating: true,
                            style: {
                                colors: [],
                                fontSize: '10px',
                                // fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                cssClass: 'apexcharts-xaxis-label',
                            },

                        },
                        axisTicks: {
                            show: false,
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                return simplify(value)
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                        custom: function ({ series, dataPointIndex, w }) {
                            let dateLabel = response.data.results.chart_period[dataPointIndex];
                            let stock = series[0][dataPointIndex];
                            let valueStock = response.data.results.chart_amount[dataPointIndex];
                            let offtake = series[1][dataPointIndex];
                            let valueOfftake = response.data.results.chart_offtake_amount[dataPointIndex];

                            return `
                                <div class="custom-tooltip">
                                    <div class="tooltip-header">${dateLabel}</div>
                                    <div class="tooltip-content">
                                        <div class="tooltip-item">
                                            <span class="tooltip-dot" style="background-color: ${w.config.colors[0]}"></span>
                                            <span class="tooltip__text-large"><strong>Stock:</strong> ${formatThousandSeparator(simplify(stock).toString())} kg</span>
                                        </div>
                                        <div class="tooltip-item">
                                            <span class="tooltip__text-small"><strong>(Value:</strong> ${balanceFormatter(valueStock, 'IDR')})</span>
                                        </div>
                                        <div class="tooltip-item">
                                            <span class="tooltip-dot" style="background-color: ${w.config.colors[1]}"></span>
                                            <span class="tooltip__text-large"><strong>Offtake:</strong> ${formatThousandSeparator(simplify(offtake).toString())} kg</span>
                                        </div>
                                        <div class="tooltip-item">
                                            <span class="tooltip__text-small"><strong>(Value:</strong> ${balanceFormatter(valueOfftake, 'IDR')})</span>
                                        </div>
                                    </div>
                                </div>
                            `;
                        }
                    }
                }
            )
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getTanks();
        getLastSync();
        getChartData();
    }, [lazyState, refresh])

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.id = value.id;
        setlazyState(_filters)
        setSelectedFilter(value)
    }
    return (
        <Card>
            <CardBody>
                <Row className="align-items-start mb-2">
                    <Col md={6} className="d-flex flex-row align-items-center gap-3">
                        <h4 className="card-title mb-4">Current Stock Levels for {selectedPeriodName}
                            {lastSync &&
                                <p className="font-size-12 text-muted">
                                    Last Synced : &nbsp;
                                    <span className={`badge bg-active text-t-active`}>
                                        {formatDateTime(lastSync)}
                                    </span>
                                </p>
                            }
                        </h4>

                    </Col>
                    <Col>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>

                            <SyncModal />
                            <div className="col px-2" width="240px">
                                <Select
                                    value={selectedFilter}
                                    onChange={changeFilter}
                                    options={tanks}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                            <PeriodFilterModal
                                mainOptions={lazyState}
                                setMainOptions={setlazyState}
                                selectedPeriodName={selectedPeriodName}
                                setSelectedPeriodName={setSelectedPeriodName}
                            />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col sm="6" lg="8">
                        <div className="mt-4 mt-sm-0">
                            {
                                stockCategories && <ReactApexChart options={stockCategories} series={stocks} height="250" />
                            }
                        </div>
                    </Col>
                    <Col sm="6" lg="4" className="d-flex align-items-center justify-content-center">
                        <div className="text-center">
                            <div className="d-flex gap-5">
                                <div className='mb-4'>
                                    <p className="text-muted mb-1">Intake {selectedPeriodName}</p>
                                    <h3>{intakeTotalWeight} {intakeWeightUnit}</h3>
                                    <h5>({balanceFormatter(intakeTotalAmount, 'IDR')})</h5>
                                </div>

                                <div className='mb-4'>
                                    <p className="text-muted mb-1">Offtake {selectedPeriodName}</p>
                                    <h3>{offtakeTotalWeight} {offtakeWeightUnit}</h3>
                                    <h5>({balanceFormatter(offtakeTotalAmount, 'IDR')})</h5>
                                </div>
                            </div>

                            <div>
                                <p className="text-muted mb-1">{selectedFilter && selectedFilter.id ? `Current UCO Stock ${selectedFilter.name}` : "Current UCO Total Stock"}</p>
                                <h3>{currentStockWeight} {currentWeightUnit}</h3>
                                <h5>({balanceFormatter(totalAmount, 'IDR')})</h5>
                            </div>
                        </div>
                    </Col>

                </Row>
                {/* Render the modal */}
                {modal_center && (
                    <DetailModal
                        modal_center={modal_center}
                        setmodal_center={setmodal_center}
                        selectedFilter={selectedFilter}
                        data={modalData}
                    />
                )}
            </CardBody>
        </Card>
    )
}

export default Inventory