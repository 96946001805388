import { useState, useRef } from "react";

import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD , PICKUP_TOOLTIP_LIST } from "constants/value";
import PickupQrModal from "./modals/PickupQrModal";
import UserDetailModal from "./modals/UserDetailModal";

import dashImage from "../../../assets/images/pickup/dash.png";
import grabImage from "../../../assets/images/pickup/grab.png";
import makeid from "helpers/random";

const PickupsTable = ({ id, pickupDatas, totalRecords, lazyState, setlazyState, loading, setRefresh, refresh, expandedRows, setExpandedRows }) => {
    const searchPlatformList = [
        { name: 'All Platform', value: '' },
        { name: 'Dash', value: 'dash' },
        { name: 'Grab', value: 'grab' },
    ];
    const [platformFilter,setPlatformFilter] = useState("");

    const toast = useRef(null);
    const [search, setSearch] = useState(id||'')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 300;

    const changePlatformFilter = (e) => {
        const value = e.target.value;
        setPlatformFilter(value);
        let _filters = { ...lazyState };
        _filters.platform = value;
        setlazyState(_filters)
    }

    const addressData = (data) => {
        return data.address.name + "," + data.address.full_address + "," + data.address.city
    }

    const roundedVol = (data) => {
        return simplify(data.uco_volume)
    }
    const roundedWeight = (data) => {
        return simplify(data.uco_weight)
    }

    const moreThanADay = (createdAt) => {
        return true
        const createdDate = new Date(createdAt); // Parse `createdAt` to a Date object
        const now = new Date();
        const oneDayInMs = 24 * 60 * 60 * 1000; // One day in milliseconds
      
        return now - createdDate < oneDayInMs;
    }
    const adminAction = (data) => {
        if ((data.platform != "dash" && data.grab_status == 'COMPLETED' && data.status == 'pending' ) ||
            (data.platform == "dash" && (data.grab_status == "IN_DELIVERY" || (data.grab_status == "COMPLETED" && moreThanADay(data.createdAt))))
        ) {
            return (
                <div className="d-flex flex-row gap-2">
                    <div>
                        <PickupQrModal pickup={data} setRefresh={setRefresh} />
                    </div>
                </div>
            )
        } else if (data?.status == "success"){
            if (data?.trx?.UCO_Approved) {
                return "Accepted"
            } else {
                return "Rejected"
            }
        } else {
            return "-"
        }

    }
    const userDetail = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <UserDetailModal user={data} />
                </div>
            </div>
        )
    }

    const copyToClipboard = async data => {
        try {
            const id = data.pickup_id;
            const { protocol, hostname, port } = window.location;

            const pathname = window.location.pathname; // e.g., "/accounting/3231-0105" or "/accounting/"
            const segments = pathname.split('/').filter(segment => segment !== ''); // Filter out empty segments
            let basepath;
            if (segments.length > 0) {
                // Take the first segment and construct the base path
                basepath = `/${segments[0]}/`;
            } else {
                basepath = '/'; // In case of an empty path
            }
            const fullBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${basepath}${id}`;
            await navigator.clipboard.writeText(fullBaseUrl);
            toast.current.show({ severity: 'info', summary: 'Link copied to clipboard' });
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    const rowClassName = (rowData) => {
        if (rowData.status == 'cancelled') {
            return 'redBg';
        } else if (rowData.active == '3') {
            return 'warningBg';
        }

        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    const transactionAmount = (data) => {
        const currency = data.currency ?? "IDR";
        return  balanceFormatter(data.price , currency)
    }
    const driverData = (data) => {
        const driverImg = data.driver?.pictureURL ?? "";
        const driverIcon = (driverImg == "") ? "pi pi-user" : "";
        return <div>

            <div style={{ display: 'flex' }}>
                <Avatar className="driver-image" image={driverImg} icon={driverIcon} size="large" shape="circle" />
                <strong style={{ marginLeft:'5px', marginRight: '5px' }}>
                    <span>{platformImage(data.platform ?? "grab")} <span  className="text-capitalize">{data.platform ?? "grab"}</span></span>
                    <br />
                    <small>{data.driver != null ? data.driver.name : '-'}</small>
                </strong>
            </div>
            {/* <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold', color: 'gray' }}>
                    {data.driver.phone}
                </span>{" "}
            </div>
            <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                    {data.driver.licensePlate}
                </span>{" "}
            </div> */}
        </div>;
    }

    const headerDriver = () => {
        return <>
            <span>Courier<br />(Driver)</span>
        </>
    }

    const createdFormat = (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss')
    }
 
    const pickupIdBody = (data) => {
        return <small>{data.pickup_id}</small>
    }

    const grabStatusBody = (data) => {
        //weird workaround but works
        const randomString = makeid(10)
        return (
            <> 
                <Tooltip target={"#sbl" + randomString} />
                <small className="status-body-label" id={"sbl" + randomString} data-pr-tooltip={PICKUP_TOOLTIP_LIST[data.grab_status] ?? ''} data-pr-position="bottom">
                    {data.grab_status === "COMPLETED" ? "DELIVERED" : data.grab_status}
                </small>
                <br />
                <span>
                    {data.grab_status === "COMPLETED" &&
                        (data.status === "success" ? smallBadgeStatus("success") : smallBadgeStatus("rejected"))}
                </span>
            </>
        )
    }

    const columns = [
        { field: 'pickup_id', header: 'Pick Up ID', body: pickupIdBody, className: '', style: { minWidth: '120px' } },
        { field: 'address.name', header: 'Sender', body: userDetail, className: '' },
        // { field: 'address.full_address', header: 'Address', body: '', className: '', style: { maxWidth: '300px' } },
        { field: 'driver', header: headerDriver, body: driverData, className: '', style: { minWidth: '130px' } },
        { field: 'uco_volume', header: 'Volume (liter)', body: roundedVol, className: 'text-center' },
        // { field: 'uco_weight', header: 'Weight (kg)', body: roundedWeight, className: 'text-end' },
        { field: 'grab_status', header: 'Status', body: grabStatusBody, className: '' },
        // { field: 'status', header: 'Status', body: '', className: '' },
        { field: 'price', header: 'Fare', body: transactionAmount, className: 'text-start' },
        { field: 'created_at', header: 'Date', body: createdFormat, className: 'text-start' },
        { field: 'action', header: 'UCO Status', body: adminAction, style: { minWidth: '150px' } },
    ]

 
    const onPage = (event) => {
        setLittleRefresh(littleRefresh + 1)
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const badgedStatus = (status) => {
        let statusConverter = status;

        if (status == "pending") {
            statusConverter = "warning"
        } else if (status == 'cancelled') {
            statusConverter = "danger"
        } else if (status == "open") {
            statusConverter = "secondary"
        } else if (status == "rejected") {
            statusConverter = "danger"
        }

        return <Badge value={status} severity={statusConverter}></Badge>
    }

    const smallBadgeStatus = (status) => {
        let statusConverter = status === "success" ? "Accepted" : "Rejected";
        let badgeColor = status === "success" ? "success" : "offline";

        return (
            <span className={`badge bg-${badgeColor} text-t-${badgeColor}`}>
                {statusConverter}
            </span>
        );
    }

    const platformImage = (platform) =>{
        if (platform == "dash") {
            return <img src={dashImage} alt="dash" width="20" />
        } else if (platform == "grab") {
            return <img src={grabImage} alt="grab" width="20" />
        } else {
            return ""
        } 
    }

    const [littleRefresh,setLittleRefresh] = useState(0);

    const TooltipTargeter = () => {
   
        return <Tooltip key={littleRefresh} target=".status-body-label" />        
    }
    

    const rowExpansionTemplate = (data) => {
        return (
            
            <div className="trans-detail">
                <h5><i class="fas fa-box"></i> Pick Up ID : <b>{data.pickup_id} </b>
                    <Button Style="float:right" color="info"
                        onClick={() => {
                            copyToClipboard(data)
                        }} >
                        <i class="far fa-copy"></i> Copy Link
                    </Button></h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <ul>

                            <li>Merchant Order ID : <b>{data.mo_id}</b></li>
                            <li>Status: {data.status === 'success' ? (data.trx != null ? (<>{badgedStatus('success')} ({data.trx.TA_UCO_Volume} liter / {balanceFormatter(data.trx.totalAmount, 'IDR')} )</>) : badgedStatus("rejected")) : badgedStatus(data.status)}</li>
                            <li>Delivery Status: <b>{data.grab_status ?? "Unknown"}</b></li>
                            <li>Box : {
                                data.box?._id
                                    ? <Link to={`/deployments/${data.box?._id}`}><b>{data.box.name ?? "-"}</b></Link>
                                    : "-"
                                }
                            </li>
                        </ul>
                        <label><i class="fas fa-receipt"></i>  Transaction :</label>
                        <ul>
                            <li>Platform ID : {data.gd_id ? data.gd_id : '-'}</li>
                            <li>Fare : <b>{balanceFormatter(data.price,(data.currency ?? "IDR") )}</b></li>
                            <li>Volume : <b>{simplify(data.uco_volume)} liter</b></li>
                            <li>Weight : <b>{simplify(data.uco_weight)} kg</b></li>
                            <li>Distance : <b>{simplify(data.distance / 1000)} km</b></li>
                            {data.platform == "dash" && data.images.length > 0 ? (
                                data.images.map((image, index) => (
                                    <a key={index} href={image.url} target="_blank">
                                        <button className="btn btn-primary btn-sm me-1">
                                            {image.type == 'PICKUP_PROOF' ? 'Pickup Proof' : 'Delivery Proof' }
                                        </button>
                                    </a>
                                ))
                            ) : data.grab_link && (
                                <a href={data.grab_link} target="_blank">
                                    <button className="btn btn-primary btn-sm">
                                        {data.platform == "dash" ? "Pickup Proof" : "Tracking Link"}
                                    </button>
                                </a>
                            )}
                            {/* <li>Grab live tracking : {data.grab_link !== "" && data.grab_status != 'COMPLETEDx' ? (
                                <a target="_blank" href={data.grab_link}>click here</a>
                            ) : (
                                '-'
                            )}</li> */}
                        </ul>
                        {data.schedule != null &&
                        <>
                        <label><i class="fas  fa-calendar"></i> Schedule:</label>
                        <ul>
                            <li>Date : <b>{data.schedule?.date ?? "-"}</b></li>
                            <li>Time : <b>{data.schedule?.start ?? "-"}</b> - <b>{data.schedule?.end ?? "-"}</b></li>
                        </ul>
                        </>
                        }
                    </div>
                    <Divider layout="vertical" />
                    <div className="col-7">
                        <label><i class="fas fa-user"></i> Sender</label>
                        <ul>
                            <li>Name :  <UserDetailModal user={data} udmStyle="inline"/></li>
                            <li>Pick Up Location : <b>{data.address.full_address}, {data.address.city},{data.address.postal_code} </b></li>
                            <li>Pick Up Coordinates <a target="_blank" href={`https://www.google.com/maps/search/${data.address.latitude},${data.address.longitude}`}>
                                {data.address.latitude}, {data.address.longitude}</a>
                            </li>
                        </ul>
                        {data.driver != null ? (
                            <div>
                                <label><i class="fas fa-biking"></i> Driver</label>
                                <ul>
                                    <li className="text-capitalize">Platform: {platformImage(data.platform ?? "grab")} <b>{data.platform ?? '-'}</b></li>
                                    <li>Name: <b>{data.driver.name ?? '-'}</b></li>
                                    <li>Phone: {data.driver.phone ?? '-'}</li>
                                    <li>Plate No: {data.driver.licensePlate ?? '-'}</li>
                                    <li>Model: {data.driver.model ?? '-'}</li>
                                </ul>
                            </div>
                        ) : null}
                        <Toast ref={toast} />
                  
                        <label><i class="fas fa-tint"></i> UCO Transactions:</label>
                        <ul>
                         {/* foreach transactions */}
                         {data.transactions?.length > 0 ? (
                                <>
                                    {data.transactions.map((item, index) => (
                                        <li key={index}>
                                            <Link to={'/transactions'} state={item?.TA_ID}>
                                                {item?.TA_ID}
                                            </Link>
                                            <> ({item?.TA_UCO_Volume} liter / {balanceFormatter(item?.totalAmount, 'IDR')})</>
                                        </li>
                                    ))}
                                </>
                            ) : (
                                <li>{data.trx !== null ? <><Link to={'/transactions'} state={data.trx.TA_ID}> {data.trx.TA_ID} </Link> ({data.trx?.UCO_Approved ? <>`{data.trx.TA_UCO_Volume} liter / {balanceFormatter(data.trx.totalAmount, 'IDR')} `</>  : "rejected"}) </>  : '-'}</li>
                     
                        )}
                        </ul>
                   
                        {data.errorReason != null &&
                        <>
                        <label>Failed Reason:</label>
                        <ul>
                            <li><span class="text-danger">{data.errorReason ?? ""}</span></li>
                        </ul>
                        </>
                        }
                    </div>

                </div>
            </div>
        );
    };

    return (
        <>    
           
      
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                    <Row className="align-items-md-center align-items-end">
                            <Col md={3} xs={12} className="d-flex flex-row align-items-start gap-3 mb-2">
                                <h4 className="card-title mb-0">Pickup Requests</h4>
                            </Col>
                            <Col md={9} xs={9}>
                                <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-start align-items-start w-100">
                                    <div className="d-flex flex-md-col flex-row justify-content-md-end justify-content-start align-items-start">
                                        <Dropdown
                                            value={platformFilter}
                                            onChange={changePlatformFilter}
                                            options={searchPlatformList}
                                            optionLabel="name"
                                            className="h-1 payment-status-dropdown me-md-2 mb-2"
                                            style={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                            }}
                                        />

                                      
                                    </div>

                                      <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Pick Up ID / Sender" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                                </div>

                            </Col>
                        </Row>   
                    
                    
                        <DataTable
                            lazy
                            value={loading ? items : pickupDatas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={loading ? '' : 'pickup_id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column
                                    style={col.style}
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default PickupsTable