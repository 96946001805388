import React, { useEffect, useRef } from "react"
import Tagify from "@yaireo/tagify"
import { Tags } from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css"

const TagifyInput = ({ onChange, settings, search }) => {
    const inputRef = useRef(null);
    const tagifyRef = useRef(null);


    const initializeTagify = (inputRef, tagifyRef, settings, onChange) => {
        if (!tagifyRef.current) {
            tagifyRef.current = new Tagify(inputRef.current, {
                ...settings,
                transformTag: (tagData) => {
                    tagData.value = tagData.display || tagData.value;
                },
                dropdown: { enabled: 0 },
                whitelist: []
            });

            const inputElTagify = tagifyRef.current.DOM.input;

            inputElTagify.setAttribute("data-placeholder", settings.placeholder || "Partner Name or Type");
            inputElTagify.setAttribute("aria-placeholder", settings.placeholder || "Partner Name or Type");


            tagifyRef.current.on("change", (e) => {
                const tags = e.detail.tagify.value.map((tag) => ({
                    value: tag.rawValue || tag.value,
                    type: tag.type
                }));
                onChange(tags);

                if (tags.length > 0) {
                    inputElTagify.removeAttribute("data-placeholder");
                    inputElTagify.removeAttribute("aria-placeholder");
                } else {
                    inputElTagify.setAttribute("data-placeholder", "Partner Name or Type");
                    inputElTagify.setAttribute("aria-placeholder", "Partner Name or Type");
                }
            });

            inputElTagify.addEventListener("focus", () => {
                if (tagifyRef.current.value.length > 0) {
                    inputElTagify.removeAttribute("data-placeholder");
                    inputElTagify.removeAttribute("aria-placeholder");
                }
            });
        }
    }

    const updateTags = (tagifyRef, settings) => {
        if (tagifyRef.current) {
            const currentTags = tagifyRef.current.value.map(tag => tag.value);
            const newTags = settings.value || [];

            const tagsToAdd = newTags.filter(tag => !currentTags.includes(tag));

            if (tagsToAdd.length > 0) {
                tagifyRef.current.addTags(tagsToAdd);
            }

            // if (JSON.stringify(currentTags) !== JSON.stringify(newTags)) {
            //     tagifyRef.current.removeAllTags();
            //     tagifyRef.current.addTags(newTags);
            // }
        }
    }

    const handleClearTags = () => {
        if (tagifyRef.current) {
            tagifyRef.current.removeAllTags();
        }
    };


    useEffect(() => {
        initializeTagify(inputRef, tagifyRef, settings, onChange);
    }, [settings, onChange]);

    useEffect(() => {
        updateTags(tagifyRef, settings);
    }, [settings.value]);

    return (
        <div
            className="d-flex tagify-wrapper"
            style={{
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                width: "100%"
            }}
        >
            <div className="p-input-icon-left">
                <i className="pi pi-search me-2" />
            </div>

            <div
                style={{ flexGrow: 1 }}
            >
                <input
                    ref={inputRef}
                    style={{
                        flexGrow: 1,
                        border: "1px solid #ccc",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                />
            </div>

            <div
                className="p-input-icon-right"
                onClick={handleClearTags}
                style={{ cursor: 'pointer', marginLeft: '40px' }}
            >
                <i className="pi pi-times" />
            </div>
        </div>
    )
}

export default TagifyInput
