import { CSVLink } from "react-csv"
import { Button as PButton } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { Row, Modal, ModalBody, ModalHeader, Label , Button } from "reactstrap"
import { simplify } from "helpers/functions";
import useTransaction from "hooks/useTransaction";
import CheckboxField from "../components/CheckboxField"
import { InputSwitch } from 'primereact/inputswitch';


const TransactionExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedAnonymous, setSelectedAnonymous] = useState(true);
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
    const { rejectedClicked, bonusClicked } = useTransaction();
    const [acceptedOnly,setAcceptedOnly] = useState(true);

    //show Error Text
    const [error,setError] = useState("");
 
    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);
    const [modalFieldToExtract, setModalFieldToExtract] = useState(false);

    const tog_center = () => {
        setAcceptedOnly(true)

        if (rejectedClicked) {
            setAcceptedOnly(false)
        }

        setSelectedMethods(["box"]);
        setError("")
        setmodal_center(!modal_center);
    };

    const togButtonNext = () => {
        setmodal_center(false);
        setSelectedFields(["ID", "Box", "User", "Username", "Email", "Phone Number", "Location Partner (Yes/No)", "Volume Partner (Yes/No)", "User Partner (Yes/No)", "Referral Code", "Accepted Weight (kg)", "Accepted Volume (liter)", "Rejected Weight (kg)", "Rejected Volume (liter)", "Currency", "Amount", "Start", "End", "Address", "Postcode", "City", "Country", "Latitude", "Longitude"]);
        setError("")
        setModalFieldToExtract(true);
    }

    const handleCloseModalField = () => {
        setModalFieldToExtract(false);
    }

    const togModalFieldToExtract = () => {
        setmodal_center(false);
        setError("");
        handleCloseModalField();
    }

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Transactions_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${rejectedClicked ? '_Rejected' : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const allHeaders = [
        { label: "ID", key: "TA_ID" },
        { label: "Box", key: "box[0].name" },
        { label: "User", key: "user[0].name" },
        { label: "Username", key: "user[0].username" },
        { label: "Email", key: "user.email" },
        { label: "Phone Number", key: "user.phone_number" },
        { label: "Location Partner (Yes/No)", key: "is_partner_location", },
        { label: "Volume Partner (Yes/No)", key: "is_partner_volume" },
        { label: "User Partner (Yes/No)", key: "is_partner_user" },
        { label: "Referral Code", key: "referral_code" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Rejected Weight (kg)", key: "TA_Slops_Weight" },
        { label: "Rejected Volume (liter)", key: "TA_Slops_Volume" },
        { label: "Currency", key: "user[0].currency" },
        { label: "Amount", key: "totalAmount" },
        { label: "Start", key: "TA_Start_Time" },
        { label: "End", key: "TA_End_Time" },
        { label: "Address", key: "address.address" },
        { label: "Postcode", key: "address.postcode" },
        { label: "City", key: "address.city" },
        { label: "Country", key: "address.country" },
        { label: "Latitude", key: "address.lat" },
        { label: "Longitude", key: "address.long" },
        { label: "Method", key: "method" },
        { label: "Alcohol Level", key: "extraData.alcohol_level" },
        { label: "Hydrocarbon Level", key: "extraData.hydrocarbon_level" },
        { label: "Raw Max Level", key: "extraData.raw_max_level" },
        { label: "Raw Interface Level", key: "extraData.raw_interface_level" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            setLoading(true)
            setError("");
            let queryParams = {
                searchText: lazyState.searchText,
                startDate: lazyState.startDate,
                endDate: lazyState.endDate,
                approved: acceptedOnly ? true : undefined,
                method: getSelectedMethodsString(),
                isAnonymize: selectedAnonymous
            };

            if (rejectedClicked) {
                if (acceptedOnly) {
                    setError("Cannot have accepted only while looking at rejected transaction") 
                    setLoading(false)
                    return;
                }
                queryParams.approved = false;
            }

            if (!queryParams.method && selectedFields.length === 0) {
                setError("No method and field is selected");
                setLoading(false);
                return;
            }

            if (!queryParams.method) {
                setError("No method is selected") 
                setLoading(false)
                return;
            }

            if (selectedFields.length === 0) {
                setError("No field is selected");
                setLoading(false);
                return;
            }

            const response = await axiosApi.post(`/api/admin/engineer/export-transaction`, queryParams);

            const result = response.data.result;
            
            if (result.length == 0) {
                setError("No data to export") 
                setLoading(false)
                return;
            }
            result.forEach(obj => {
                obj.user.phone_number = obj.user[0]?.phone?.number
                    ? `${obj.user[0].phone.prefix}-${obj.user[0].phone.number}`
                    : '-',
                obj.user.email = obj.user[0]?.email ? obj.user[0]?.email : '-',
                obj.TA_UCO_Weight = obj.UCO_Approved ? simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight) : 0;
                obj.TA_UCO_Volume = obj.UCO_Approved ? simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume) : 0;
                obj.TA_Slops_Weight = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight);
                obj.TA_Slops_Volume = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume);
                obj.address.lat = obj.address.lat || obj?.box[0]?.location?.latitude || "";
                obj.address.long = obj.address.long || obj?.box[0]?.location?.longitude || "";
                obj.address.address = obj.address?.address?.replace(/\n/g, ', ') || "";

                obj.is_partner_location = obj.is_partner_location ? 'Yes' : 'No';
                obj.is_partner_volume = obj.is_partner_volume ? 'Yes' : 'No';
                obj.is_partner_user = obj.is_partner_user ? 'Yes' : 'No';
                obj.referral_code = obj.user?.[0]?.referral_code || '-';
                obj.extraData = obj.extraData || {};
                obj.extraData.alcohol_level = obj.extraData.alcohol_level || '-';
                obj.extraData.hydrocarbon_level = obj.extraData.hydrocarbon_level || '-';
                obj.extraData.raw_max_level = obj.extraData.raw_max_level || '-';
                obj.extraData.raw_interface_level = obj.extraData.raw_interface_level || '-';
            })
            setDataReq(result)
            setRefresh(response.data.random)
            setLoading(false)
            togModalFieldToExtract()
        } catch (error) {
            setError(error.message) 
            setLoading(false)
            console.log(error);
        }
    }

    const [selectedMethods, setSelectedMethods] = useState(["box"]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setSelectedMethods((prev) => {
            if (checked) {
                // Add the value if not already present
                return Array.from(new Set([...prev, value]));
            } else {
                // Remove the value if unchecked
                return prev.filter((method) => method !== value);
            }
        });
    };

    const [selectedFields, setSelectedFields] = useState([
        "ID",
        "Box",
        "User",
        "Username",
        "Email",
        "Phone Number",
        "Location Partner (Yes/No)",
        "Volume Partner (Yes/No)",
        "User Partner (Yes/No)",
        "Referral Code",
        "Accepted Weight (kg)",
        "Accepted Volume (liter)",
        "Rejected Weight (kg)",
        "Rejected Volume (liter)",
        "Currency",
        "Amount",
        "Start",
        "End",
        "Address",
        "Postcode",
        "City",
        "Country",
        "Latitude",
        "Longitude"
    ]);

    const handleFieldCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setSelectedFields((prev) => {
            if (checked) {
                // Add the value if not already present
                return Array.from(new Set([...prev, value]));
            } else {
                // Remove the value if unchecked
                return prev.filter((field) => field !== value);
            }
        });
    };

    const getSelectedMethodsString = () => selectedMethods.join(',');
    const changeAcceptedOnly = () =>  setAcceptedOnly(!acceptedOnly);

    const filteredHeaders = allHeaders.filter(header => selectedFields.includes(header.label));


    return (
        <>
            <PButton
                type="button"
                icon={`mdi mdi-24px mdi-file`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={tog_center}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={filteredHeaders}
            >
            </CSVLink>

            <Modal isOpen={modal_center} toggle={tog_center} centered size="sm">
                <ModalHeader className="mt-0" toggle={tog_center}>Export Transactions</ModalHeader>
                <ModalBody>
                    <Row>
                    <div className="col-12">
                        <label><b>Transactions Method:</b></label>
                        {/* BOX */}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="box"
                                id="checkBoxMethodBox"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("box")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodBox">
                                Box
                            </label>
                        </div>
                        {/* END OF BOX */}

                        {/* MANUAL */}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="manual"
                                id="checkBoxMethodManual"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("manual")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodManual">
                                Manual
                            </label>
                        </div>
                        {/* END OF MANUAL */}

                        {/* PICKUP */}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="pickup"
                                id="checkBoxMethodPickup"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("pickup")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodPickup">
                                Pickup
                            </label>
                        </div>
                        {/* END OF PICKUP */}

                        {/* PROMOTION */}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="promotion"
                                id="checkBoxMethodPromotion"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("promotion")} // Controlled input
                              
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodPromotion">
                                Promotion
                            </label>
                        </div>
                        {/* END OF PROMOTION */}

                        {/* MONTHLY BONUS */}
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="bonus"
                                id="checkBoxMethodBonus"
                                onChange={handleCheckboxChange}
                                checked={selectedMethods.includes("bonus")} // Controlled input
                            />
                            <label className="form-check-label" htmlFor="checkBoxMethodBonus">
                                Monthly Bonus
                            </label>
                        </div>
                        {/* END OF MONTHLY BONUS */}
                    </div>
                    <div className="col-12">
                        <label><b>Transactions Status:</b></label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value="box"
                                id="checkBoxAcceptedOnly"
                                onChange={changeAcceptedOnly}
                                checked={acceptedOnly && !rejectedClicked} // Controlled input
                                disabled={rejectedClicked}
                            />
                            <label className="form-check-label" htmlFor="checkBoxAcceptedOnly">
                                Accepted Transactions Only
                            </label>
                        </div>
                    </div>


                    {error != "" && 
                    <span style={{ color: 'red' }}><b>{error}</b></span>
                    }
                        <hr></hr>
                        <div className="col-12">
                            <PButton
                                type="button"
                                icon="mdi mdi-arrow-right"
                                class="btn btn-primary"
                                onClick={togButtonNext}
                            > 
                                Next
                            </PButton>
                        </div>
                    </Row>
                </ModalBody>

            </Modal>

            {/* MODAL FIELD */}
            <Modal isOpen={modalFieldToExtract} toggle={handleCloseModalField} centered size="md">
                <ModalHeader className="mt-0" toggle={handleCloseModalField}>Fields to extract :</ModalHeader>
                <ModalBody>
                    <Row>
                        {/* LEFT SIDE */}
                        <div className="col-6">
                            {/* ID */}
                            <CheckboxField
                                id="ID"
                                value="ID"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("ID")}
                                label="ID"
                            />

                            {/* BOX */}
                            <CheckboxField
                                id="Box"
                                value="Box"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Box")}
                                label="Box"
                            />

                            {/* USER */}
                            <CheckboxField
                                id="User"
                                value="User"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("User")}
                                label="User"
                            />

                            {/* USERNAME */}
                            <CheckboxField
                                id="Username"
                                value="Username"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Username")}
                                label="Username"
                            />

                            {/* EMAIL */}
                            <CheckboxField
                                id="Email"
                                value="Email"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Email")}
                                label="Email"
                            />

                            {/* PHONE NUMBER */}
                            <CheckboxField
                                id="Phone Number"
                                value="Phone Number"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Phone Number")}
                                label="Phone Number"
                            />

                            {/* LOCATION PARTNER */}
                            <CheckboxField
                                id="Location Partner (Yes/No)"
                                value="Location Partner (Yes/No)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Location Partner (Yes/No)")}
                                label="Location Partner (Yes/No)"
                            />

                            {/* VOLUME PARTNER */}
                            <CheckboxField
                                id="Volume Partner (Yes/No)"
                                value="Volume Partner (Yes/No)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Volume Partner (Yes/No)")}
                                label="Volume Partner (Yes/No)"
                            />

                            {/* USER PARTNER */}
                            <CheckboxField
                                id="User Partner (Yes/No)"
                                value="User Partner (Yes/No)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("User Partner (Yes/No)")}
                                label="User Partner (Yes/No)"
                            />

                            {/* REFERRAL CODE */}
                            <CheckboxField
                                id="Referral Code"
                                value="Referral Code"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Referral Code")}
                                label="Referral Code"
                            />

                            {/* ACCEPTED WEIGHT */}
                            <CheckboxField
                                id="Accepted Weight (kg)"
                                value="Accepted Weight (kg)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Accepted Weight (kg)")}
                                label="Accepted Weight (kg)"
                            />

                            {/* ACCEPTED VOLUME */}
                            <CheckboxField
                                id="Accepted Volume (liter)"
                                value="Accepted Volume (liter)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Accepted Volume (liter)")}
                                label="Accepted Volume (liter)"
                            />
                            
                            {/* REJECTED WEIGHT */}
                            <CheckboxField
                                id="Rejected Weight (kg)"
                                value="Rejected Weight (kg)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Rejected Weight (kg)")}
                                label="Rejected Weight (kg)"
                            />
                            
                            {/* REJECTED LITER */}
                            <CheckboxField
                                id="Rejected Volume (liter)"
                                value="Rejected Volume (liter)"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Rejected Volume (liter)")}
                                label="Rejected Volume (liter)"
                            />

                            {/* CURRENCY */}
                            <CheckboxField
                                id="Currency"
                                value="Currency"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Currency")}
                                label="Currency"
                            />
                        </div>

                        {/* RIGHT SIDE */}
                        <div className="col-6">
                            {/* AMOUNT */}
                            <CheckboxField
                                id="Amount"
                                value="Amount"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Amount")}
                                label="Amount"
                            />

                            {/* START */}
                            <CheckboxField
                                id="Start"
                                value="Start"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Start")}
                                label="Start"
                            />

                            {/* END */}
                            <CheckboxField
                                id="End"
                                value="End"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("End")}
                                label="End"
                            />

                            {/* ADDRESS */}
                            <CheckboxField
                                id="Address"
                                value="Address"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Address")}
                                label="Address"
                            />

                            {/* POST CODE */}
                            <CheckboxField
                                id="Postcode"
                                value="Postcode"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Postcode")}
                                label="Postcode"
                            />

                            {/* CITY */}
                            <CheckboxField
                                id="City"
                                value="City"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("City")}
                                label="City"
                            />

                            {/* COUNTRY */}
                            <CheckboxField
                                id="Country"
                                value="Country"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Country")}
                                label="Country"
                            />

                            {/* LATITUDE */}
                            <CheckboxField
                                id="Latitude"
                                value="Latitude"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Latitude")}
                                label="Latitude"
                            />

                            {/* LONGITUDE */}
                            <CheckboxField
                                id="Longitude"
                                value="Longitude"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Longitude")}
                                label="Longitude"
                            />

                            {/* METHOD */}
                            <CheckboxField
                                id="Method"
                                value="Method"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Method")}
                                label="Method"
                            />

                            {/* ALCOHOL LEVEL */}
                            <CheckboxField
                                id="Alcohol Level"
                                value="Alcohol Level"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Alcohol Level")}
                                label="Alcohol Level"
                            />

                            {/* HYDROCARBON LEVEL */}
                            <CheckboxField
                                id="Hydrocarbon Level"
                                value="Hydrocarbon Level"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Hydrocarbon Level")}
                                label="Hydrocarbon Level"
                            />

                            {/* RAW MAX LEVEL */}
                            <CheckboxField
                                id="Raw Max Level"
                                value="Raw Max Level"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Raw Max Level")}
                                label="Raw Max Level"
                            />

                            {/* RAW INTERFACE LEVEL */}
                            <CheckboxField
                                id="Raw Interface Level"
                                value="Raw Interface Level"
                                onChange={handleFieldCheckboxChange}
                                checked={selectedFields.includes("Raw Interface Level")}
                                label="Raw Interface Level"
                            />
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">Anonymous</Label><br />
                            <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                        </div>

                        {error != "" && 
                            <span style={{ color: 'red' }}><b>{error}</b></span>
                        }
                        <hr></hr>

                        <div className="col-12 d-flex justify-content-center mt-3">
                            <Button
                                type="button"
                                color="primary"
                                disabled={loading}
                                onClick={() => fetchDataReq()}
                            >
                                <i className={`mdi ${loading ? 'mdi-loading mdi-spin' : 'mdi-file'} me-2`}></i>
                                Export to CSV
                            </Button>
                        </div>
                    </Row>
                </ModalBody>
            </Modal>
        </>

    )
}

export default TransactionExportCSV