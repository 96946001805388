import { createContext, useState } from "react"

const AdminPartnerContext = createContext({})

export const AdminPartnerProvider = ({ children }) => {
    const [refreshAdminPartners, setRefreshAdminPartners] = useState("")
    const [adminPartners, setAdminPartners] = useState()
    const [adminPartnersLoading, setAdminPartnersLoading] = useState(false)

    const levelOptions = [
        { label: "Admin", value: "superadmin" },
        { label: "Finance", value: "finance" },
        { label: "Engineer", value: "engineer" },
        { label: "Support", value: "support" }
    ];

    const methodList = [
        {
            name: "Whatsapp",
            key: "whatsapp",
        },
        {
            name: "E-mail",
            key: "email",
        }
    ];

    const brandList = [
        { name: "Pertamina", value: "mypertamina", },
        { name: "Alfamart", value: "alfamart", }
    ]

    return (
        <AdminPartnerContext.Provider
            value={{
                refreshAdminPartners,
                setRefreshAdminPartners,
                adminPartners,
                setAdminPartners,
                adminPartnersLoading,
                setAdminPartnersLoading,
                methodList,
                brandList,
                levelOptions,
            }}
        >
            {children}
        </AdminPartnerContext.Provider>
    )
}

export default AdminPartnerContext
