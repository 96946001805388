import { Row, Modal, ModalBody, ModalHeader } from "reactstrap"
import React, { useEffect, useState } from "react";
import axiosApi from 'helpers/api_helper';
import { Column } from "primereact/column";
import { currencyFormatter, simplify, inputDateTime, debounce, startTime, balanceFormatter } from "helpers/functions";
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";


const UserPickupsModal = ({ user }) => {
    const [modal_center, setmodal_center] = useState(false);
    const [userPaymentMethod, setUserPaymentMethods] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
    };
    const getUserPaymentMethod = async () => {
        try {
            const response = await axiosApi.get(`/api/admin/pickup/userPickups/${user._id}`);

            setUserPaymentMethods(response.data.result);
            setTotalRecords(response.data.totalRecords)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUserPaymentMethod();
    }, []);


    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const bodyPickupId = (data) => {

        if (!data.pickup_id) {
            return <span>-</span>
        }

        return (
            <Link to={`/pickups/${data.pickup_id}`}>{data?.pickup_id}</Link>
        )
    }

    const transactionAmount = (data) => {
        return balanceFormatter(data.price)
    }

    const bodyTrxId = (data) => {
        const approvedTransactions = data.transactions.filter(transaction => transaction.UCO_Approved);

        if (approvedTransactions.length > 0) {
            return (
                <div>
                    {approvedTransactions.map((transaction, index) => (
                        <div key={index} className="mb-2">
                            <Link to={`/transactions/${transaction.TA_ID}`}>{transaction.TA_ID}</Link>
                        </div>
                    ))}
                </div>
            );
        }

        return <span>-</span>;
    }

    const roundedVol = (data) => {
        return simplify(data.uco_volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.uco_weight)
    }
    const driverData = (data) => {
        return <div>
            <div style={{ display: 'flex' }}>
                <strong style={{ marginRight: '10px' }}>{data?.driver?.name || "-"}</strong>
            </div>
            {/* <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold', color: 'gray' }}>
                    {data.driver.phone}
                </span>{" "}
            </div>
            <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                    {data.driver.licensePlate}
                </span>{" "}
            </div> */}
        </div>;
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'pickup_id', header: 'Pick Up ID', body: bodyPickupId, className: '', style: { maxWidth: '120px' } },
        { field: 'address.name', header: 'Sender', body: '', className: '' },
        { field: 'address.full_address', header: 'Address', body: '', className: '', style: { maxWidth: '300px' } },
        { field: 'driver', header: 'Driver', body: driverData, className: '' },
        { field: 'uco_weight', header: 'Weight (kg)', body: roundedWeight, className: 'text-center', style: { maxWidth: '90px' } },
        // { field: 'uco_weight', header: 'Weight (kg)', body: roundedWeight, className: 'text-end' },
        { field: 'grab_status', header: 'Grab Status', body: '', className: '' },
        // { field: 'status', header: 'Status', body: '', className: '' },
        { field: 'price', header: 'Grab Fare', body: transactionAmount, className: 'text-end' },
        { field: 'trx_id', header: 'Tx ID', body: bodyTrxId, className: '', },
        { field: 'created_at', header: 'Date', body: startTime, className: 'text-end', style: { maxWidth: '120px' } },
    ]

    return (
        <>
            {totalRecords === 0 ? (
                <span>{totalRecords}</span> // If totalRecords is 0, display the count without a hyperlink
            ) : (
                <a href="#" onClick={tog_center}>
                    {totalRecords}
                </a> // If totalRecords is not 0, display the count as a hyperlink
            )}
            <Modal isOpen={modal_center} toggle={tog_center} centered size="xl">
                <ModalHeader className="mt-0" toggle={tog_center}>User Pickups</ModalHeader>
                <ModalBody>
                    <DataTable
                        // filters={filters}
                        // globalFilterFields={['name', 'address']}
                        value={loading ? items : userPaymentMethod}
                        stripedRows
                        showGridlines
                        size={'normal'}
                        // metaKeySelection={true}
                        // selectionMode={'single'}
                        selection={loading ? '' : userPaymentMethod}
                        scrollable
                        scrollHeight='500px'
                    >
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )

}

export default UserPickupsModal
