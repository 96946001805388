export const TON_TO_LITER = 1087
export const ROWS_PER_PAGE = [5, 10, 25, 50, 100]
export const ROWS_PER_PAGE_MEDIUM_DASHBOARD = [25, 50, 100]
export const ROWS_PER_PAGE_MEDIUM = [20, 50, 100]
export const ROWS_PER_PAGE_LARGE = [50, 100, 200]
export const phoneRegExp = /^[\+0-9]?[1-9]{1,4}[ \-]*(\([0-9]{2,3}\)[ \-]*|[0-9]{2,4}[ \-]*)*[0-9]{3,4}[ \-]*[0-9]{3,4}?$/
export const positiveNumericRegExp = /^[0-9]+$/
export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
export const INITIAL_OPENING_HOURS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
].map(day => ({
  day,
  isOpen: false,
  open: "00:00",
  close: "00:00",
  timezone: "WIB",
}))
export const ENV_IS_PROD = process.env.REACT_APP_ENV === "prod"
export const CURRENT_WITHDRAWAL_PLATFORM = "XENDIT"

export const countryList = [
  { label: 'Indonesia', currency: 'IDR', country: "ID", value: "ID", flagUrl: 'https://flagcdn.com/w20/id.png' },
  { label: 'Singapore', currency: 'SGD', country: "SG", value: "SG", flagUrl: 'https://flagcdn.com/w20/sg.png' },
]

export const PICKUP_TOOLTIP_LIST = {
"WAITING_SCHEDULE": "The pickup request is awaiting the scheduled time.",  
"CANCELLED": "The pickup request has been canceled.",  
"CANCELED": "The pickup request has been canceled.",  
"USER_CANCELED": "The pickup request was canceled by the user.",  
"SYSTEM_CANCELED": "The pickup request was canceled by the system.",  
"USER_CANCELLED": "The pickup request was canceled by the user.",  
"SYSTEM_CANCELLED": "The pickup request was canceled by the system.",  
"IN_DELIVERY": "The driver is currently delivering the UCO to the Noovoleum Collection Point or designated box.",  
"COMPLETED": "The pickup request has been completed, and the UCO has been received by Noovoleum.",  
"ALLOCATING": "The platform is assigning an available driver for the pickup.",  
"CREATED": "The platform is assigning an available driver for the pickup.",  
"FAILED": "The pickup request has failed due to an inability to complete the process.",  
"PENDING_PICKUP": "Awaiting driver pickup of the UCO.",  
"PICKING_UP": "The driver is en route to the user location to collect the UCO.",  
"RETURNED": "The UCO delivery failed, and the package has been returned to the user location.",  
"PENDING_DROP_OFF": "Awaiting the user drop-off of the UCO to the driver."  
}