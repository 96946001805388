import React, { useEffect, useState } from "react";

// Formik validation
import * as Yup from "yup";
import { useFormik, FieldArray, Form as FormFormik, FormikProvider } from "formik";
import {
  Button,
  FormFeedback,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import useAuth from "hooks/useAuth";
import TooltipInfo from "components/Partner/TooltipInfo";
// import {  TON_TO_LITER } from 'constants/value'; REAL VALUE. might restore this later if needed

const TON_TO_LITER = 1000; //fake value. they already input 1000 on the data


import Select from "react-select";

import { formatThousandSeparator, removeCommas } from "helpers/functions";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { availableLanguageList } from "common/languages";
import usePartner from "hooks/usePartner";
import moment from "moment";
import { Calendar } from 'primereact/calendar';

const PartnerForm = ({ activeTab, setactiveTab, existData = null, id = null , setSharedTabData , sharedTabData }) => {
  const axiosApi = useAxiosPrivate();
  const { setPartnerForm } = usePartner();
  const { showToast } = useAuth();

  const [selectedLanguage, setSelectedLanguage] = useState("id")
  const [directPayment, setDirectPayment] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showVolumeForm, setShowVolumeForm] = useState(false);
  const [showLocationForm, setShowLocationForm] = useState(false);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [userPaymentMethod,setUserPaymentMethod] = useState(null);

  const today = new Date();
  const [date, setDate] = useState(new Date(today))
  const collectedDate = moment(date).format('YYYY-MM-DD');

  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const toggleModal = () => {
    console.log("toggle modal triggered")
    setIsModalOpen(!isModalOpen);
    removeBodyCss();
    validation.handleSubmit();
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleConfirmModal = () => {
    closeModal()
    // validation.handleSubmit();
    toggleTab(activeTab + 1)
  }

  const handleDateChange = (e) => {
    setDate(e.value);
  }

  const updateTypes = (value) => {
    setPartnerTypes(prevLevels => {
      if (!prevLevels.includes(value)) {
        return [...prevLevels, value];
      } else {
        return prevLevels.filter(level => level !== value);
      }
    });
  };

  const handlePrice = (e) => {
    validation.handleChange(e); let { name, value } = e.target;

    if (currency === "IDR") {
      value = value.replace(/[^0-9]/g, ""); // Remove everything except digits
    } else {
      value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot
    }

    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    if (value === "" || parseFloat(value) <= 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }

    const formattedValue = formatThousandSeparator(value);

    validation.setFieldValue(name, formattedValue);
  };

  const handlePercentageInput = (e) => {
    validation.handleChange(e);
    let { name, value } = e.target;

    // Allow only digits and decimal points
    value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

    // Ensure only one decimal point can be entered
    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    // Ensure value is more than 0
    if (value === "" || parseFloat(value) <= 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }


    // Limit to two decimal places
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
      value = value.substring(0, decimalIndex + 3); // Keep only two digits after decimal
    }

    if (value > 100) {
      value = "100"
    }

    const formattedValue = formatThousandSeparator(value);

    validation.setFieldValue(name, formattedValue);
  };

  //VOLUME FORM
  const [currency, setCurrency] = useState("IDR");
  const [enablePrice, setEnablePrice] = useState(false);
  const [showUsernameResults, setShowUsernameResults] = useState(false);
  const [usernameResults, setUsernameResults] = useState([]);
  const [userId, setUserId] = useState("");
  const [userLoading, setUserLoading] = useState(false);

  const handleUsername = async (e) => {
    validation.handleChange(e);
    let { name, value } = e.target;
    validation.setFieldValue(name, value)
    if (value) {
      await searchUser(value);
      setShowUsernameResults(true);
    } else {
      setShowUsernameResults(false);
      setUserId("");
    }
  };
  const searchUser = async (username) => {
    try {
      setUserLoading(true);
      const response = await axiosApi.get(
        `/api/admin/support/search-user-with-default-payment?username=${username}`
      );
      // console.log(response.data);
      setUsernameResults(response.data.result);
      setUserLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUsernameBlur = (e) => {
    validation.handleBlur(e);
    setTimeout(() => {
      setShowUsernameResults(false);
    }, 150);
  };

  const handleUsernameResultClick = (user, e) => {
    e.stopPropagation();
    validation.values.username = user.username
    setUserPaymentMethod(user.payment_method ?? null);
    setUserId(user._id);
    setCurrency(user.currency);
    setShowUsernameResults(false);
  };
  const handleExpectedVolume = (e) => {
    validation.handleChange(e);
    let { name, value } = e.target;

    value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    if (value === "" || parseFloat(value) <= 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }

    const formattedValue = formatThousandSeparator(value);

    validation.setFieldValue(name, formattedValue);
  };

  //USER FORM
  const [selectedReferrals, setSeletedReferrals] = useState([])
  const [referrals, setReferrals] = useState([])
  const typeList = [
    { name: "Influencer", value: "Influencer" },
    { name: "Field Agent", value: "Field Agent" },
    { name: "Corporate", value: "Corporate" },
    // { name: 'Custom', value: 'custom' },
  ]
  const [type, setType] = useState(existData?.partner?.partner_user?.type || "");
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const searchType = async (type) => {
    try {
      setLoading(true);
      if (type) {
        const results = typeList.filter(partnerTypes => partnerTypes.name.toLowerCase().includes(type.toLowerCase()))
          .map(type => type.value);
        setResults(results);
      } else {
        setResults([]);
      }
      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  };
  const handleType = async (e) => {
    validation.handleChange(e);
    const value = e.target.value;
    if (value) {
      await searchType(value);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    validation.setFieldValue(name, e.target.value);
    setType(e.target.value);
  };

  const handleTypeBlur = (e) => {
    validation.handleBlur(e);
    setTimeout(() => {
      setShowResults(false);
    }, 150);
  };

  const handleResultClick = (user, e) => {
    e.stopPropagation();
    setType(user);
    validation.values.type = user

    setShowResults(false);
  };
  // const getReferrals = async () => {
  //   try {
  //     const response = await axiosApi.get(`/api/admin/marketing/referral`);
  //     const dataList = response.data.result

  //     const locList = dataList.map(referral => ({ ...referral, label: referral.name, value: referral.name }));
  //     setReferrals(locList);
  //     const selectedReferralIds = existData?.partner?.partner_user?.referral_ids || [];
  //     if (selectedReferralIds.length > 0) {
  //       const selectedReferrals = locList.filter(referral =>
  //         selectedReferralIds.includes(referral._id)
  //       );
  //       setSeletedReferrals(selectedReferrals);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // const changeReferral = (value) => {
  //   setSeletedReferrals(value)
  // }
  // useEffect(() => {
  //   if (showUserForm) {
  //     getReferrals();
  //   }
  // }, [showUserForm]);



  //LOCATION FORM
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [boxes, setBoxes] = useState([])
  const handleMonthPrice = (e) => {
    validation.handleChange(e); let { name, value } = e.target;

    if (currency === "IDR") {
      value = value.replace(/[^0-9]/g, ""); // Remove everything except digits
    } else {
      value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot
    }

    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    if (value === "" || parseFloat(value) <= 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }

    const formattedValue = formatThousandSeparator(value);

    validation.setFieldValue(name, formattedValue);
  };
  const getBoxes = async () => {
    try {
      const selectedBoxIds = existData?.partner?.partner_location?.box_ids || [];
      const partnerConfigId = existData?.id || '';
      const response = await axiosApi.get(`/api/admin/partner-configurations/box-options?partner_configuration_id=${partnerConfigId}`);
      const dataList = response.data.result
      let results = []
      let selectedBoxesArr = [];

      if (dataList)
        dataList.forEach((box, index) => {
          let isLinked = box.is_linked;

          // if (existData?.partner?.partner_location?.box_ids?.includes(box.id)) {
          //   isLinked = false;
          // }

          results.push({
            ...box,
            label: box.name,
            value: box.name,
            // disabled: isLinked // marked the that has been used
            is_linked: box.is_linked
          });
        });
      setBoxes(results);
      if (selectedBoxesArr.length > 0) {
        setSelectedBoxes(selectedBoxesArr);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const changeBox = (value) => {
    setSelectedBoxes(value)
  }
  useEffect(() => {
    if (showLocationForm) {
      getBoxes();
    }
  }, [showLocationForm]);
  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }

  const addBox = (arrayHelpers, forms) => {
    // let defaultAvailableBox = null;
    // let usedBox = [];

    // if (forms.length > 0) {
    //   for (let i = 0; i < forms.length; i++) {
    //     if (forms[i].box_ids) {
    //       usedBox.push(forms[i].box_ids.id);
    //     }
    //   }
    // }

    // if (usedBox.length > 0) {
    //   defaultAvailableBox = boxes.filter((box) => !usedBox.includes(box.id) && !box.disabled);
    // } else {
    //   defaultAvailableBox = boxes.filter(box => !box.disabled);
    // }

    let object = {
      box_ids: '',
      collected_date: '',
      // availableBox: defaultAvailableBox,
    }

    arrayHelpers.push(object);
  }


  const [compensation, setCompensation] = useState(null);
  const updateCompensation = (value) => {

    setCompensation(value); // Set the selected compensation type
    // setCompensation(prevLevels => {
    //   if (!prevLevels.includes(value)) {
    //     return [...prevLevels, value];
    //   } else {
    //     return prevLevels.filter(level => level !== value);
    //   }
    // });
  };
  const handleMaxChange = (e, tier) => {
    let { name, value } = e.target;
    validation.handleChange(e);
    value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

    // Ensure only one decimal point can be entered
    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    // Ensure value is more than 0
    if (value === "" || parseFloat(value) < 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }


    // Limit to two decimal places
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
      value = value.substring(0, decimalIndex + 3); // Keep only two digits after decimal
    }

    validation.setFieldValue(name, value);

    // Set the next min value based on the current max value
    if (tier === 1) {
      validation.setFieldValue('min_share_tier_2', value);
    } else if (tier === 2) {
      validation.setFieldValue('min_share_tier_3', value);
    } else if (tier === 3) {
      validation.setFieldValue('min_share_tier_4', value);
    }
  };

  const handleShareChange = (e, tier) => {
    let { name, value } = e.target;
    validation.handleChange(e);
    value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

    // Ensure only one decimal point can be entered
    const dotCount = (value.match(/\./g) || []).length;
    if (dotCount > 1) {
      value = value.substring(0, value.length - 1); // Remove the extra dot
    }

    // Ensure value is more than 0
    if (value === "" || parseFloat(value) < 0) {
      value = ""; // Optional: Reset value to empty if it's not valid
    }


    // Limit to two decimal places
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
      value = value.substring(0, decimalIndex + 3); // Keep only two digits after decimal
    }

    if (value > 100) {
      value = "100"
    }

    validation.setFieldValue(name, value);
  };
  useEffect(() => {
    setDirectPayment(existData?.partner?.partner_volume?.direct_payment_enabled ? true : false);
    setEnablePrice(existData?.partner?.partner_volume?.is_fixed_price || false);
    setUserId(existData?.partner?.partner_volume?.user_id || existData?.partner?.partner_user?.user_id || "");
    setCurrency(existData?.partner?.partner_volume?.currency || existData?.partner?.partner_user?.currency || 'IDR')
    setSelectedLanguage(existData?.partner?.language || "id")
    const partnerType = existData?.partner?.partner_type || [];
    setPartnerTypes(partnerType)
    setShowUserForm(partnerType.includes("user_partner"));
    setShowVolumeForm(partnerType.includes("volume_partner"));
    setShowLocationForm(partnerType.includes("location_partner"));
    setType(existData?.partner?.partner_user?.type || "");
    setCompensation(existData?.partner?.partner_location?.compensation || null);
    getBoxes();
    // getReferrals();
  }, [existData]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: existData?.partner?.partner_volume?.username || existData?.partner?.partner_user?.username || "",
      // user_partnership_share: existData?.partner?.partner_user?.user_partnership_share || "",
      expected_volume: existData?.partner?.partner_volume?.expected_volume ? formatThousandSeparator(String(existData?.partner?.partner_volume?.expected_volume)) : "",
      price: enablePrice ? (existData?.partner?.partner_volume?.fixed_price ? formatThousandSeparator(String(existData?.partner?.partner_volume?.fixed_price)) : "") : "",
      price_month: existData?.partner?.partner_location?.fixed_monthly_price ? formatThousandSeparator(String(existData?.partner?.partner_location?.fixed_monthly_price)) : "",
      type: existData?.partner?.partner_user?.type || "",
      min_share_tier_1: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_1?.min || 0) / TON_TO_LITER).toFixed(2)),
      max_share_tier_1: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_1?.max || 0) / TON_TO_LITER).toFixed(2)),
      min_share_tier_2: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_2?.min || 0) / TON_TO_LITER).toFixed(2)),
      max_share_tier_2: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_2?.max || 0) / TON_TO_LITER).toFixed(2)),
      min_share_tier_3: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_3?.min || 0) / TON_TO_LITER).toFixed(2)),
      max_share_tier_3: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_3?.max || 0) / TON_TO_LITER).toFixed(2)),
      min_share_tier_4: String((parseFloat(existData?.partner?.partner_location?.share_tier?.share_tier_4?.min || 0) / TON_TO_LITER).toFixed(2)),
      percentage_share_tier_1: String(existData?.partner?.partner_location?.share_tier?.share_tier_1?.percentage || 0),
      percentage_share_tier_2: String(existData?.partner?.partner_location?.share_tier?.share_tier_2?.percentage || 2.5),
      percentage_share_tier_3: String(existData?.partner?.partner_location?.share_tier?.share_tier_3?.percentage || 5),
      percentage_share_tier_4: String(existData?.partner?.partner_location?.share_tier?.share_tier_4?.percentage || 10),

      boxes: existData?.partner?.partner_location?.box_ids || [],
    },

    validationSchema: Yup.object().shape({
      partner_type: partnerTypes.length == 0 ? Yup.array().min(1, "This value is required").required("This value is required") : Yup.array()
        .nullable(),
      expected_volume: showVolumeForm ? Yup.string().required("This value is required") : Yup.string().nullable(),
      price: showVolumeForm && enablePrice
        ? Yup.string().required("This value is required")
        : Yup.string().nullable(),
      // user_partnership_share: showUserForm ? Yup.string().required("This value is required") : Yup.string().nullable(),
      // referral: showUserForm && selectedReferrals.length == 0 ? Yup.array().min(1, "This value is required").required("This value is required") : Yup.array()
      //   .nullable(),
      // box: showLocationForm && selectedBoxes.length == 0 ? Yup.array().min(1, "This value is required").required("This value is required") : Yup.array()
      //   .nullable(),

      boxes: showLocationForm ? Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("This value is required"),
          collected_date: Yup.string().required("This value is required"),
        })
      ).required('This value is required').min(1, 'This value is required') : Yup.array().nullable(),

      compensations: showLocationForm && compensation == null ? Yup.string().required("This value is required") : Yup.string()
        .nullable(),
      price_month: showLocationForm && compensation != 'revenue_share'
        ? Yup.string().required("This value is required")
        : Yup.string().nullable(),
      username: showVolumeForm ? Yup.string().required("This value is required") : Yup.string().nullable(),
      max_share_tier_1: (showLocationForm && compensation != 'fixed')
        ? Yup.string()
          .required("This value is required")
          .test(
            "less-than-tier-2",
            "Tier 1 must be less than Tier 2",
            function (value) {
              const { max_share_tier_2 } = this.parent;
              return !max_share_tier_2 || parseFloat(value) < parseFloat(max_share_tier_2);
            }
          )
        : Yup.string().nullable(),

      max_share_tier_2: (showLocationForm && compensation != 'fixed')
        ? Yup.string()
          .required("This value is required")
          .test(
            "less-than-tier-3",
            "Tier 2 must be less than Tier 3",
            function (value) {
              const { max_share_tier_3 } = this.parent;
              return !max_share_tier_3 || parseFloat(value) < parseFloat(max_share_tier_3);
            }
          )
        : Yup.string().nullable(),
      max_share_tier_3: (showLocationForm && compensation != 'fixed') ? Yup.string().required("This value is required") : Yup.string().nullable(),
      percentage_share_tier_1: (showLocationForm && compensation != 'fixed')
        ? Yup.string()
          .required("This value is required")
        : Yup.string().nullable(),
      percentage_share_tier_2: (showLocationForm && compensation != 'fixed')
        ? Yup.string()
          .required("This value is required")
        : Yup.string().nullable(),
      percentage_share_tier_3: (showLocationForm && compensation != 'fixed')
        ? Yup.string()
          .required("This value is required")
        : Yup.string().nullable(),
      percentage_share_tier_4: (showLocationForm && compensation != 'fixed') ? Yup.string().required("This value is required") : Yup.string().nullable(),

    }),
    onSubmit: async (values, { resetForm }) => {
      try {
      console.log('Form Values:', values); // Debug values

        const partnerForm = { language: selectedLanguage, partner_type: partnerTypes };
        const volumeForm = {
          user_id: userId,
          is_fixed_price: enablePrice,
          fixed_price: enablePrice ? values.price ? removeCommas(values.price) : null : null,
          direct_payment_enabled: directPayment,
          expected_volume: removeCommas(values.expected_volume),
        };
        const userForm = {
          user_id: userId,
          // type: type,
          // user_partnership_share: values.user_partnership_share,
          // referral_ids: selectedReferrals.length == 0 ? [] : selectedReferrals.map(referral => referral._id)
        };

        // format for box
        const boxData = values.boxes.map(box => ({
          id: box.id,
          collected_date: moment(box.collected_date).format('YYYY-MM-DD')
        }));

        console.log('Formatted Box Data:', boxData); // Debug formatted data


        const locationForm = {
          // box_ids: selectedBoxes.length == 0 ? [] : selectedBoxes.map(box => box.id),
          box_ids: boxData,
          compensation: compensation,
          fixed_monthly_price: compensation != 'revenue_share' ? (values.price_month ? removeCommas(values.price_month) : "") : "",
          ...(compensation != 'fixed' && {
            share_tier:
            {
              share_tier_1: {
                min: ( parseFloat(values.min_share_tier_1) * TON_TO_LITER).toString(),
                max: ( parseFloat(values.max_share_tier_1) * TON_TO_LITER).toString(),
                percentage: values.percentage_share_tier_1
              },
              share_tier_2: {
                min: ( parseFloat(values.min_share_tier_2) * TON_TO_LITER).toString(),
                max: ( parseFloat(values.max_share_tier_2) * TON_TO_LITER).toString(),
                percentage: values.percentage_share_tier_2
              },
              share_tier_3: {
                min: ( parseFloat(values.min_share_tier_3) * TON_TO_LITER).toString(),
                max: ( parseFloat(values.max_share_tier_3) * TON_TO_LITER).toString(),
                percentage: values.percentage_share_tier_3
              },
              share_tier_4: {
                min: ( parseFloat(values.min_share_tier_4) * TON_TO_LITER).toString(),
                percentage: values.percentage_share_tier_4
              }
            }
          })
        };

        let opts = {
          ...partnerForm,
          ...(id ? { partner_configuration_id: id } : {}),
          ...(showVolumeForm && volumeForm ? { partner_volume: volumeForm } : {}),
          ...(showUserForm && userForm ? { partner_user: userForm } : {}),
          ...(showLocationForm && locationForm ? { partner_location: locationForm } : {}),
        };
        console.log('Final Payload:', opts); // Debug final payload


        // console.log('opts',opts)

        const response = await axiosApi.post('/api/admin/partner-wizard/validate/partner', opts);
        console.log('API Response:', response.data); // Debug API response

        //save default payment method to sharedData if 
        var sharedData = {};
        if (userPaymentMethod) {
          if (!existData?.payment?.bank_account_number ) {
            sharedData.userPaymentMethod = userPaymentMethod;
          }
          
        }

        setSharedTabData(sharedData);

        setPartnerForm(opts);

        // CONDITION FOR IS MODAL
        if (response.data.isModal && showLocationForm) {
          console.log("modal triggered")
          setModalMessage(response.data.responseMessage);
          setIsModalOpen(true);
        } else {
          toggleTab(activeTab + 1);
        }

      } catch (error) {
        console.log(error.message)
        console.error('Submit Error:', error); // Debug error
        if (error.response && error.response.data && error.response.data.state) {
          showToast(error.response.data);
          validation.setErrors(error.response.data);
        } else {
          let response = {};
          response.state = "error";
          response.toast = true;
          response.message = "Internal Server Error"
          showToast(response);
        }
      }
    },
  });

  useEffect(() => {
    if (validation.errors && Object.keys(validation.errors).length > 0) {
      console.log('Validation Errors:', validation.errors);
    }
  }, [validation.errors]);


  return (
    <React.Fragment>
      <FormikProvider value={validation}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          console.log('Form Submitted'); // Debug form submission
          validation.handleSubmit();
          return false;
        }}
      >

        <Modal isOpen={isModalOpen} toggle={closeModal} size={'md'} centered>
          <ModalHeader className="mt-0" toggle={closeModal}>Confirmation Modal</ModalHeader>

          <ModalBody>
            {modalMessage}
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={() => closeModal()}>
              Cancel
            </Button>

            <Button color="primary" onClick={handleConfirmModal}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>

        <Row className="mb-1">
          <Label className="col-lg-3 col-md-3 form-label">Types <TooltipInfo string="types" /></Label>
          <div className="col-lg-9 col-md-9">
            <FormGroup check key={`volume_partner`}>
              <Input id='volume_partner' name="partner_type" type="checkbox" value='volume_partner' checked={showVolumeForm} onChange={(e) => { setShowVolumeForm(e.target.checked); updateTypes(e.target.value); }} />
              <Label htmlFor='volume_partner'>{`Volume`}</Label>
            </FormGroup>
            <FormGroup check key={`user_partner`}>
              <Input id='user_partner' name="partner_type" type="checkbox" value='user_partner' checked={showUserForm} onChange={(e) => {
                setShowUserForm(e.target.checked); updateTypes(e.target.value);
              }} />
              <Label htmlFor='user_partner'>{`User`}</Label>
            </FormGroup>
            <FormGroup check key={`location_partner`}>
              <Input id='location_partner' name="partner_type" type="checkbox" value='location_partner' checked={showLocationForm} onChange={(e) => { setShowLocationForm(e.target.checked); updateTypes(e.target.value); }} />
              <Label htmlFor='location_partner'>{`Location`}</Label>
            </FormGroup>
            {partnerTypes.length == 0 && validation.errors.partner_type ? (
              <div className="error-text mb-3">{validation.errors.partner_type}</div>
            ) : null}
          </div>
        </Row>

        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Language <TooltipInfo string="language" /></Label>
          <div className="col-lg-9 col-md-9">
            <Dropdown
              value={selectedLanguage || ""}
              onChange={event => {
                setSelectedLanguage(event.target.value)
                setCurrency(event.target.value)
              }}
              options={availableLanguageList}
              optionLabel="label"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>

        {(showVolumeForm) && <Row className="mb-3 position-relative">
          <Label className="col-lg-3 col-md-3 col-form-label">Username <TooltipInfo string="username" /></Label>
          <div className="col-lg-3 col-md-3">

            <InputGroup className="col-lg-9 col-md-9">
              <Input
                name="username"
                placeholder="Enter Username"
                type="text"
                autoComplete="off"
                onChange={handleUsername}
                onBlur={handleUsernameBlur}
                value={validation.values.username || ""}
                invalid={
                  validation.touched.username && validation.errors.username
                    ? true
                    : false
                }
              />
              {validation.touched.username && validation.errors.username ? (
                <FormFeedback type="invalid">
                  {validation.errors.username}
                </FormFeedback>
              ) : null}
            </InputGroup>
            {userLoading ? (
              <ListGroup
                className="position-absolute list-group-container col-lg-3 col-md-3"
                style={{ top: "100%", zIndex: 100 }}
              >
                <ListGroupItem key={"load"} className="d-flex">
                  <ProgressSpinner
                    style={{ width: "30px", height: "30px" }}
                    strokeWidth="2"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                    className="align-self-center"
                  />
                </ListGroupItem>
              </ListGroup>
            ) : showUsernameResults && usernameResults.length > 0 ? (
              <ListGroup
                className="position-absolute list-group-container col-lg-3 col-md-3"
                role="button"
                style={{ top: "100%", zIndex: 10 }}
              >
                {usernameResults.map((user) => (
                  <ListGroupItem
                    key={user._id}
                    onClick={(e) => handleUsernameResultClick(user, e)}
                    className="hover-list-item"
                  >
                    {user.username}
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : null}
          </div>
        </Row>
        }

        {showVolumeForm &&
          <div>
            <Row className="mb-3" >
              <Label className="col-lg-3 col-md-3 col-form-label">
                Fixed Price <TooltipInfo string="fixedPrice" />
              </Label>
              <div className="col-lg-9 col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                <InputSwitch checked={enablePrice} onChange={(e) => setEnablePrice(e.value)} style={{ marginRight: '10px' }} />

              </div>
            </Row>
            {enablePrice && (<Row className="mb-3" >
              <Label className="col-lg-3 col-md-3 col-form-label">
                Fixed Price / liter (optional) <TooltipInfo string="fixedPricePerLiter" />
              </Label>
              <div className="col-lg-9 col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                <InputGroup
                  className={
                    validation.touched.price && validation.errors.price
                      ? "is-invalid"
                      : ""
                  }
                  style={{ width: "350px" }}
                >
                  <Input
                    name="price"
                    placeholder="Enter Fixed Price"
                    type="text"
                    autoComplete="off"
                    inputMode="numeric"
                    onChange={handlePrice}
                    onBlur={validation.handleBlur}
                    value={validation.values.price || ""}
                    invalid={
                      validation.touched.price && validation.errors.price
                        ? true
                        : false
                    }
                  />
                  <InputGroupText>
                    {currency}
                  </InputGroupText>
                  {validation.touched.price && validation.errors.price ? (
                    <FormFeedback type="invalid">
                      {validation.errors.price}
                    </FormFeedback>
                  ) : null}
                </InputGroup>

              </div>
            </Row>
            )}
            <Row className="mb-3">
              <Label className="col-lg-3 col-md-3 col-form-label">Expected Monthly Volume <TooltipInfo string="expectedMonthlyVolume" /></Label>
              <div className="col-lg-3 col-md-3">
                <InputGroup
                  className={
                    validation.touched.expected_volume && validation.errors.expected_volume
                      ? "is-invalid"
                      : ""
                  }
                  style={{ width: "350px" }}
                >
                  <Input
                    name="expected_volume"
                    placeholder="Enter Expected Monthly Volume"
                    type="text"
                    autoComplete="off"
                    inputMode="numeric"
                    onChange={handleExpectedVolume}
                    onBlur={validation.handleBlur}
                    value={validation.values.expected_volume || ""}
                    invalid={
                      validation.touched.expected_volume && validation.errors.expected_volume
                        ? true
                        : false
                    }
                  />
                  <InputGroupText>
                    liter
                  </InputGroupText>
                  {validation.touched.expected_volume && validation.errors.expected_volume ? (
                    <FormFeedback type="invalid">
                      {validation.errors.expected_volume}
                    </FormFeedback>
                  ) : null}
                </InputGroup>
              </div>

            </Row>
            <Row className="mb-3">
              <Label className="col-lg-3 col-md-3 form-label">Direct Payment <TooltipInfo string="directPayment" /></Label>
              <div className="col-lg-9 col-md-9" >
                <InputSwitch checked={directPayment} onChange={(e) => setDirectPayment(e.value)} />
              </div>

            </Row>
          </div>
        }

        {showLocationForm && <div> 
          {/* <Row className="mb-3">
            <Label className="col-lg-3 col-md-3 col-form-label">Box <TooltipInfo string="box" /></Label>

            <Col md={4} lg={4}>
              <Select
                value={selectedBoxes}
                isOptionDisabled={(option) => option.disabled}
                onChange={changeBox}
                options={boxes}
                optionLabel="label"
                classNamePrefix="select2-selection"
                styles={{ control: (base) => ({ ...base, height: "47px" }) }}
              />
              {selectedBoxes.length == 0 && validation.errors.box ? (
                <div className="error-text mb-3">{validation.errors.box}</div>
              ) : null}
            </Col>
            <Col md={3} lg={3}>
              <Calendar
                locale="en"
                minDate={today}
                value={date}
                onChange={handleDateChange}
                showIcon
                dateFormat="yy/mm/dd"
              />
            </Col>
          </Row> */}

          <FieldArray
            name="boxes"
            render={arrayHelpers => {
              const forms = validation.values.boxes;

              return (
                <div>
                  <Row>
                    <Label className="col-lg-3 col-md-3 col-form-label">Box<TooltipInfo string="box" /></Label>
                    <Col md={9}>
                      <div className="mb-3">
                        <div className="d-flex">
                          {forms.length === 0 && (
                            <div className="d-flex flex-wrap">
                              <Button
                                onClick={() => addBox(arrayHelpers, boxes)} 
                                color="primary"
                              >
                                <i className="fas fa-plus"></i>
                              </Button>
                            </div>
                          )}
                        </div>
                        {validation.touched.boxes && validation.errors.boxes === 'string' ? (
                          <FormFeedback type="invalid">
                            {validation.errors.boxes}
                          </FormFeedback>
                        ) : null}

                        {/* {forms.length === 0 && validation.touched.boxes && validation.errors.boxes && (
                          <div className="error-text text-danger mt-1">
                            Please add at least one box
                          </div>
                        )} */}

                        {forms.map((form, index) => (
                          <div key={index}>
                            <Row>
                              {/* Box Select */}
                              <Col lg={5} md={6}>
                                <div className="row mb-3 px-2">
                                  <Label className="form-label text-start">Box {index + 1}</Label>
                                  <Select
                                    id={`boxes.${index}.id`}
                                    name={`boxes.${index}.id`}
                                    value={boxes.find((box) => box.id === form?.id) || null}
                                    onChange={selectedOption => {
                                      validation.setFieldValue(`boxes.${index}.id`, selectedOption.id);
                                    }}
                                    className={
                                      validation.touched.boxes?.[index]?.id &&
                                      validation.errors.boxes?.[index]?.id
                                        ? "is-invalid"
                                        : ""
                                    }
                                    onBlur={validation.handleBlur}
                                    options={boxes}
                                    isOptionDisabled={(option) => {
                                      // if box selected, disabled in other form input
                                      const isSelectedInOtherForm = validation.values.boxes.some((boxForm, i) => 
                                          i !== index &&
                                          boxForm.id &&
                                          boxForm.id === option.id
                                        );
                                        return isSelectedInOtherForm;
                                      }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    // options={form.availableBox || boxes.filter(box => !box.disabled)}
                                    // isOptionDisabled={(option) => option.disabled}
                                    // getOptionLabel={(option) => option.name}
                                    // getOptionValue={(option) => option.id}
                                    classNamePrefix="select2-selection"
                                    styles={{ control: (base) => ({ ...base, height: "47px" }) }}
                                  />
                                  {validation.errors.boxes?.[index]?.id &&
                                    validation.errors.boxes?.[index]?.id ? (
                                      <div className="text-danger">
                                        {validation.errors.boxes[index].id}
                                      </div>
                                  ) : null}
                                </div>
                              </Col>

                              {/* Calendar */}
                              <Col lg={4} md={5}>
                                <div className="row mb-3 px-2">
                                  <Label className="form-label text-start">Collected Date</Label>
                                  <Calendar
                                    id={`boxes.${index}.collected_date`}
                                    name={`boxes.${index}.collected_date`}
                                    value={form.collected_date ? new Date(form.collected_date) : null}
                                    onChange={(e) => {
                                      validation.setFieldValue(
                                        `boxes.${index}.collected_date`, 
                                        moment(e.value).format('YYYY-MM-DD')
                                      );
                                      setDate(e.value);
                                    }}
                                    className={
                                      validation.touched.boxes?.[index]?.collected_date &&
                                      validation.errors.boxes?.[index]?.collected_date
                                        ? "is-invalid"
                                        : ""
                                    }
                                    onBlur={validation.handleBlur}
                                    showIcon
                                    dateFormat="yy/mm/dd"
                                    locale="en"
                                  />
                                  {validation.touched.boxes?.[index]?.collected_date && 
                                    validation.errors.boxes?.[index]?.collected_date ? (
                                    <div className="text-danger">
                                      {validation.errors.boxes[index].collected_date}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>

                              {/* Btn Delete */}
                              <Col lg={1} md={1}>
                                <div className="d-flex px-1">
                                  <Button 
                                    className="btn btn-danger" 
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}


                        {forms.length > 0 && (
                          <Row>
                            <Col lg={5} md={6}></Col>
                            <Col lg={4} md={5}></Col>
                            <Col lg={1} md={1}>
                              <div className="d-flex px-1 mt-3">
                                {forms.every(form => form.id && form.collected_date) ? (
                                  <Button 
                                    onClick={() => addBox(arrayHelpers, boxes)} 
                                    color="primary"
                                  >
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                ) : (
                                  <Button disabled color="primary">
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                        {/* {forms.length > 0 && (
                          <>
                            <Col lg={5} md={4}></Col>
                            <Col lg={3} md={3}></Col>

                            <Col lg={2} md={3} className="d-flex justify-content-end">
                              <div>
                                {forms.every(form => form.id && form.collected_date) ? (
                                  <Button 
                                    onClick={() => addBox(arrayHelpers, boxes)} 
                                    color="primary"
                                  >
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                ) : (
                                  <Button disabled color="primary">
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </>
                        )} */}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            }}
          />

          <Row className="mb-1">
            <Label className="col-lg-3 col-md-3 form-label">Compensations <TooltipInfo string="compensation" /></Label>
            <div className="col-lg-9 col-md-9">
              <FormGroup check key={`fixed`}>
                <Input id='fixed' name="compensations" type="radio" value='fixed' checked={compensation == 'fixed'} onChange={(e) => { updateCompensation(e.target.value); }} />
                <Label htmlFor='fixed'>{`Fixed`}</Label>
              </FormGroup>
              <FormGroup check key={`revenue_share`}>
                <Input id='revenue_share' name="compensations" type="radio" value='revenue_share' checked={compensation == 'revenue_share'} onChange={(e) => { updateCompensation(e.target.value); }} />
                <Label htmlFor='revenue_share'>{`Revenue Share`}</Label>
              </FormGroup>
              <FormGroup check key={`fixed_revenue_share`}>
                <Input id='fixed_revenue_share' name="compensations" type="radio" value='fixed_revenue_share' checked={compensation == 'fixed_revenue_share'} onChange={(e) => { updateCompensation(e.target.value); }} />
                <Label htmlFor='fixed_revenue_share'>{`Fixed + Revenue Share`}</Label>
              </FormGroup>
              {compensation == null && validation.errors.compensations ? (
                <div className="error-text mb-3">{validation.errors.compensations}</div>
              ) : null}
            </div>
          </Row>
          {
            (compensation != 'revenue_share' && compensation != null) &&
            <Row className="mb-3" >
              <Label className="col-lg-3 col-md-3 col-form-label">
                Fixed Price / month <TooltipInfo string="fixedPricePerMonth" />
              </Label>
              <div className="col-lg-9 col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                <InputGroup
                  className={
                    validation.touched.price_month && validation.errors.price_month
                      ? "is-invalid"
                      : ""
                  }
                  style={{ width: "350px" }}
                >
                  <Input
                    name="price_month"
                    placeholder="Enter Fixed Price"
                    type="text"
                    autoComplete="off"
                    inputMode="numeric"
                    onChange={handleMonthPrice}
                    onBlur={validation.handleBlur}
                    value={validation.values.price_month || ""}
                    invalid={
                      validation.touched.price_month && validation.errors.price_month
                        ? true
                        : false
                    }
                  />
                  <InputGroupText>
                    IDR
                  </InputGroupText>
                  {validation.touched.price_month && validation.errors.price_month ? (
                    <FormFeedback type="invalid">
                      {validation.errors.price_month}
                    </FormFeedback>
                  ) : null}
                </InputGroup>

              </div>
            </Row>
          }

          {/* Tier 1 */}
          {
            (compensation != 'fixed' && compensation != null) &&
            <div>
              <Row className="mb-3">
                <Label className="col-lg-3 col-md-3 col-form-label">Share Tier 1 <TooltipInfo string="shareTier" /></Label>
                <Row className="col-lg-6 col-md-6">
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Minimum</Label>
                    <InputGroup className="mb-3">
                      <Input
                        name="min_share_tier_1"
                        type="text"
                        disabled
                        value={0}
                        inputMode="numeric"
                      />
                      <InputGroupText>Tons</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Maximum</Label>
                    <InputGroup className={
                      validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="max_share_tier_1"
                        type="text"
                        value={validation.values.max_share_tier_1}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1
                            ? true
                            : false
                        }
                        onChange={(e) => handleMaxChange(e, 1)}
                      />
                      <InputGroupText>Tons</InputGroupText>
                      {validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.max_share_tier_1}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Share</Label>
                    <InputGroup className={
                      validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="percentage_share_tier_1"
                        type="text"
                        value={validation.values.percentage_share_tier_1}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1
                            ? true
                            : false
                        }
                        onChange={(e) => handleShareChange(e, 1)}
                      />
                      <InputGroupText>%</InputGroupText>
                      {validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.percentage_share_tier_1}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </Row>

              {/* Tier 2 */}
              <Row className="mb-3">
                <Label className="col-lg-3 col-md-3 col-form-label">Share Tier 2 <TooltipInfo string="shareTier" /></Label>
                <Row className="col-lg-6 col-md-6">
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Minimum</Label>
                    <InputGroup className="mb-3">
                      <Input
                        name="min_share_tier_2"
                        type="text"
                        disabled
                        value={validation.values.min_share_tier_2}
                        inputMode="numeric"
                      />
                      <InputGroupText>Tons</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Maximum</Label>
                    <InputGroup className={
                      validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="max_share_tier_2"
                        type="text"
                        value={validation.values.max_share_tier_2}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2
                            ? true
                            : false
                        }
                        onChange={(e) => handleMaxChange(e, 2)}
                      />
                      <InputGroupText>Tons</InputGroupText>
                      {validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.max_share_tier_2}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Share</Label>
                    <InputGroup className={
                      validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="percentage_share_tier_2"
                        type="text"
                        value={validation.values.percentage_share_tier_2}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2
                            ? true
                            : false
                        }
                        onChange={(e) => handleShareChange(e, 2)}
                      />
                      <InputGroupText>%</InputGroupText>
                      {validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.percentage_share_tier_2}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </Row>

              {/* Tier 3 */}
              <Row className="mb-3">
                <Label className="col-lg-3 col-md-3 col-form-label">Share Tier 3 <TooltipInfo string="shareTier" /></Label>
                <Row className="col-lg-6 col-md-6">
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Minimum</Label>
                    <InputGroup className={
                      validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="min_share_tier_3"
                        type="text"
                        disabled
                        value={validation.values.min_share_tier_3}
                        inputMode="numeric"
                      />
                      <InputGroupText>Tons</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Maximum</Label>
                    <InputGroup className="mb-3">
                      <Input
                        name="max_share_tier_3"
                        type="text"
                        value={validation.values.max_share_tier_3}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3
                            ? true
                            : false
                        }
                        onChange={(e) => handleMaxChange(e, 3)}
                      />
                      <InputGroupText>Tons</InputGroupText>
                      {validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.max_share_tier_3}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Share</Label>
                    <InputGroup className={
                      validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="percentage_share_tier_3"
                        type="text"
                        value={validation.values.percentage_share_tier_3}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3
                            ? true
                            : false
                        }
                        onChange={(e) => handleShareChange(e, 3)}
                      />
                      <InputGroupText>%</InputGroupText>
                      {validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.percentage_share_tier_3}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </Row>

              {/* Tier 4 */}
              <Row className="mb-3">
                <Label className="col-lg-3 col-md-3 col-form-label">Share Tier 4 <TooltipInfo string="shareTier" /></Label>
                <Row className="col-lg-6 col-md-6">
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Minimum</Label>
                    <InputGroup className={
                      validation.touched.min_share_tier_4 && validation.errors.min_share_tier_4
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="min_share_tier_4"
                        type="text"
                        disabled
                        value={validation.values.min_share_tier_4}
                        inputMode="numeric"
                      />
                      <InputGroupText>Tons</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Maximum</Label>
                    <InputGroup className="mb-3 align-items-center">
                      <Input
                        type="text"
                        disabled
                        value="&#x221E;"
                        inputMode="numeric"
                      />
                      <InputGroupText>Tons</InputGroupText>
                    </InputGroup>
                  </Col>
                  <Col lg={4} md={4}>
                    <Label className="col-form-label">Share</Label>
                    <InputGroup className={
                      validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4
                        ? "is-invalid"
                        : ""
                    }>
                      <Input
                        name="percentage_share_tier_4"
                        type="text"
                        value={validation.values.percentage_share_tier_4}
                        onBlur={validation.handleBlur}
                        inputMode="numeric"
                        invalid={
                          validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4
                            ? true
                            : false
                        }
                        onChange={(e) => handleShareChange(e, 4)}
                      />
                      <InputGroupText>%</InputGroupText>
                      {validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4 ? (
                        <FormFeedback type="invalid">
                          {validation.errors.percentage_share_tier_4}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </Row>

            </div>

          }


        </div>
        }
        <div className="d-flex flex-wrap gap-2 justify-content-end">
          <Button
            color="primary"
            onClick={() => toggleTab(activeTab - 1)}
          >
            Previous
          </Button>{" "}
          <Button
            type="button"
            color="primary"
            disabled={validation.isSubmitting}
            onClick={async () => {
              // if (showLocationForm && isModalOpen) {
              //   toggleModal();
              //   console.log("===== IF =====")
              //   console.log(" if isModalOpen", isModalOpen)
              //   console.log(" if showLocationForm", showLocationForm)
              // } else {
              //   console.log("===== ELSE =====")
              //   console.log(" else isModalOpen", isModalOpen)
              //   console.log(" else showLocationForm", showLocationForm)
              //   // Validate the form before proceeding
              //   validation.validateForm().then(errors => {
              //     if (showVolumeForm && (errors.username || errors.expected_volume)) {
              //       // If there are errors in the required fields, do not proceed
              //       validation.setTouched({
              //         username: true,
              //         expected_volume: true,
              //       });
              //       return;
              //     }
              //     validation.handleSubmit();
              //     // toggleTab(activeTab + 1);
              //   });
              // }
              // if partner_type.location_partner === true {
              //   if(boxes_ids.length <= 0) {
              //     showModal('Box is required')
              //   }
              // }

              if (showLocationForm) {
                if(Object.entries(validation.errors).length > 0) {
                  // const errors = Object.entries(validation.errors);
                    showToast({message: `Box field value is required`, state: "error", toast: true});
                  
                  // for(const [el, msg] of errors) {
                    // showToast({message: `Box field value is required!`, state: "error", toast: true});
                  // }
                }
              }

              validation.handleSubmit();
            }}
          >
            Next
          </Button>{" "}
        </div>
      </Form>
      </FormikProvider>
    </React.Fragment>
  );
};

export default PartnerForm;
