import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import usePartner from "hooks/usePartner";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
const DataExportCSV = ({ lazyState, period }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const { eligibleClicked } = usePartner()
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partner_Payments_Report_${date}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { key: 'partner_account', label: 'Partner Name'},
        { key: 'username', label: 'Username'},
        { key: 'amount_due', label: 'Amount Due' },
        { key: 'amount_paid', label: 'Amount Paid' },
        { key: 'amount_paid', label: 'Amount Paid' },
        { key: 'outstanding_balance', label: 'Outstanding Balance' },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh]);

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `period=${period}`;

            if (eligibleClicked) {
                queryParams += '&filterEligible=true';
            }

            const response = await axiosApi.get(`/api/admin/export-partner-payments?${queryParams}`);
            const result = response.data;
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            console.log(error.response.data)
            setLoading(false)
            console.log(error);
        }
    }

    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                // headers={headers}
            >
            </CSVLink>
        </>
    )
}

export default DataExportCSV