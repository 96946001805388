import React from "react"

const PaidOutNotice = ({directPaymentTime}) => {
    const [hours, minutes] = directPaymentTime.split(":").map(Number);

    // Create a Date object in UTC
    let date = new Date();
    date.setUTCHours(hours, minutes, 0, 0);

    // Get formatted local time (HH:MM)
    const newHours = String(date.getHours()).padStart(2, '0');
    const newMinutes = String(date.getMinutes()).padStart(2, '0');

    // Get the client's timezone abbreviation
    const timeZone = Intl.DateTimeFormat('en', { timeZoneName: 'short' })
        .formatToParts(date)
        .find(part => part.type === 'timeZoneName').value;

            // Manually map GMT offsets to real timezone names
    const timezoneMap = {
        "GMT+7": "WIB", // Western Indonesia Time
        "GMT+8": "WITA", // Singapore Time
        "GMT+9": "WIT", // Japan Standard Time
    };

    // Replace the default GMT format with the correct abbreviation if available
    const localizedTimeZone = timezoneMap[timeZone] || timeZone; // Fallback to default if not found

    const calculatedTime = `${newHours}:${newMinutes} ${localizedTimeZone}`

    return <span class="text-muted">(Paid out daily at {calculatedTime})</span>;
}

export default PaidOutNotice
