import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";
import Trading from "./Trading";
import { Card, CardBody, Col, Row } from "reactstrap";
import Price from "./Price";
import usePrice from "hooks/usePrice";
import moment from "moment";
import { convertTimeFromUTC } from "helpers/functions";

const FinancePrice = () => {
  const { loading, setLoading,setSelectedRange, selectedRange, tradingOptions, priceRange, tradingSeries, setTradingOptions, setTradingSeries, setCurrentPrice, setPreviousPrice, setShare, refresh, buySeries, setBuySeries, selectedSearchPeriod } = usePrice();
  const axiosApi = useAxiosPrivate();

  const [ratesData, setRatesData] = useState([]);

  const changeRange = (e) => {
    setSelectedRange(e.value);
  }
  const getAnnotations = (pass, day) => {
    if (!pass) {
      return [];
    } else {
      return (
        [{
          x: day.point,
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            style: {
              color: "#fff",
              background: "#775DD0",
              cssClass: "trade-range"
            },
            orientation: 'horizontal',
            text: day.text,
          }
        }]
      )
    }
  }

  const getUCOPrice = async () => {
    setLoading(true);
    try {
      const response = await axiosApi.get(`/api/admin/finance/getUCOPrice/${selectedRange}`);
      const description = await convertTimeFromUTC(response.data.arrTime, selectedRange)
      setCurrentPrice(response.data.currPrice);
      setPreviousPrice(response.data.prevPrice);
      setShare(response.data.share);
      setTradingOptions({
        ...tradingOptions,
        xaxis: {
          ...tradingOptions.xaxis,
          categories: description.categories,
          title: {
            text: `Time (${response.data.title})`,
          }
        },
        yaxis: {
          ...tradingOptions.yaxis,
          labels: {
            formatter: (value) => {
              return `$${value}`
            }
          },
        },
        tooltip: {
          x: {
            formatter: (value) => {
              return description.formatter[value - 1]
            }
          }
        },
        annotations: {
          xaxis: getAnnotations(response.data.pass, response.data.day),
        }
      });

      setTradingSeries([
        {
          name: 'Trading',
          data: response.data.series,
        }
      ]);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const getUCOBuyingHistory = async () => {
    const response = await axiosApi.get(`/api/admin/finance/getUCOBuyingHistoryV2?period=${selectedSearchPeriod}`);

    setRatesData(response.data.result);

    if (selectedSearchPeriod === 'day') {
      setBuySeries([
        {
            name: 'Buying Price',
            // data: response.data.series
            data: response.data.result.map((item) => ({
                x: moment(item.date).format("MMM DD"),
                y: item.series
            }))
        },
        {
            name: 'Payout Price',
            // data: response.data.margin
            data: response.data.result.map((item) => ({
              x: item.date,
              y: item.margin
          }))
        },
      ])
    } else {
      setBuySeries([
        {
            name: 'Buying Price',
            // data: response.data.series
            data: response.data.result.map((item) => ({
                x: item.period,
                y: item.series
            }))
        },
        {
            name: 'Payout Price',
            // data: response.data.margin
            data: response.data.result.map((item) => ({
              x: item.period,
              y: item.margin
          }))
        },
      ])
    }
  }

  useEffect(() => {
    getUCOPrice();
  },[selectedRange, refresh]);

  useEffect(() => {
    getUCOBuyingHistory();
  },[refresh, selectedSearchPeriod]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Price Management`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8} sm={12}>
                <h6 className="page-title">UCO Price</h6>
                <ol className="breadcrumb mb-0"><li className="breadcrumb-item"><a href="/">Finance</a></li><li className="active breadcrumb-item" aria-current="page">UCO Price</li></ol>
              </Col>

              <Col md={4} sm={12}>
                <div className="float-md-end d-sm-block">
                  <Dropdown value={selectedRange} onChange={changeRange} options={priceRange} optionLabel="name" className="h-1 payment-status-dropdown" />
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Trading />
                </CardBody>
              </Card>
            </Col>
            <hr style={{ borderTop: '1px solid black', opacity: '1' }} />
            <Col>
              <Card>
                <CardBody>
                  <Price ratesData={ratesData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancePrice;
