import { useState, useRef, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Link } from "react-router-dom";

import { formatThousandSeparator, debounce, formatTimeToUTC, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";

import '../../../assets/css/style.css'
import usePartner from "hooks/usePartner";
import TransactionHistoryModal from "../Overview/modals/TransactionHistoryModal";
import { Calendar } from 'primereact/calendar';
import DataExportCSV from "./exports/DataExportCSV";
import makeid from "helpers/random";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";

const ReportTable = ({ id, lazyState, setlazyState, maxDate, filterPeriod, setFilterPeriod, period }) => {
    /* DEEPLINK FROM FINANCE -> ACCOUNTING */
    // const location = useLocation();
    // const stateNameFromPartnerPayments = location.state?.nameFromPartnerPayments || '';
    const axiosApi = useAxiosPrivate();
    const { showToast } = useAuth();
    const [search, setSearch] = useState(id || '')
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState
    const [loading, setLoading] = useState(false);
    
    const parseStartEndDate = (inp) => {
        if (!inp) return {
            startDate: '',
            endDate: '',
        };
        if (!inp) return '';

        const date = new Date(inp.toString());
        date.setHours(0, 0, 0, 0);

        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setHours(23, 59, 59, 999);

        const formattedFirstDay = formatTimeToUTC(firstDay);
        const formattedLastDay = formatTimeToUTC(lastDay);
        return {
            startDate: formattedFirstDay,
            endDate: formattedLastDay,
        };
    };
    const changePeriod = (e) => {

        let value = e.value ? e.value : maxDate;
        setFilterPeriod(value);

        const { startDate, endDate } = parseStartEndDate(e.value);
        let _filters = { ...lazyState };
        _filters.startDate = startDate;
        _filters.endDate = endDate;
        setlazyState(_filters)
    }
    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    const { dataTotalRecords, setDataLoading, dataLoading, dataTable, setPartnerRefresh, eligibleClicked, setEligibleClicked } = usePartner();
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const delay = 1000;

    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value; // Update the search text
        setlazyState(_filters);      // Set the updated lazyState
    }
    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setDataLoading(true);
        debouncedSearch(value);
    }

    /* DEEPLINK FROM FINANCE -> ACCOUNTING */
    // const debouncedSearchStateName = useCallback(debounce(changeFilter, 100), []);
    // useEffect(() => {
    //     if (stateNameFromPartnerPayments) {
    //         setSearch(stateNameFromPartnerPayments);
    //         debouncedSearchStateName(stateNameFromPartnerPayments);
    //     }
    // }, [stateNameFromPartnerPayments]);

    const actualVolume = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="volume" />
                {" / "}
                {formatThousandSeparator(String(data.partner_volume?.expected_volume ?? 0))}
            </>
        );
    }
    const actualUser = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="user" />
                {" / "}
                {formatThousandSeparator(String(data.user_partner?.user_count ?? 0))}
            </>
        );
    }
    const actualLocation = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="location" />
                {" / "}
                {formatThousandSeparator(String(data.location_partner?.location_count ?? 0))}
            </>
        );
    }

    const bodyPartnerShare = (data) => {
        return balanceFormatter(data?.partner_share_earning ?? 0, 'IDR');
    }
    const bodyPartnerName = (data) => {
        const partnerName = data.partner_account;
        const partnerTypeList = data.partner_type_list || [];

        const formattedPartnerTypes = (
            <div>
                {partnerTypeList.map((type, index) => {
                    if (type === 'location_partner') {
                        return (
                            <Link to={`/location-partners/${data.partner_account}`} key={index}>
                                <span
                                    className="badge bg-secondary me-2"
                                    style={{ color: '#6610f2' }}
                                    onMouseEnter={(e) => (e.target.style.opacity = '0.5')}
                                    onMouseLeave={(e) => (e.target.style.opacity = '1')}
                                >
                                    Loc
                                </span>
                            </Link>
                        );
                    } else if (type === 'user_partner') {
                        return (
                            <Link to={`/user-partners/${data.partner_account}`} key={index}>
                                <span
                                    className="badge bg-active text-t-active me-2"
                                    onMouseEnter={(e) => (e.target.style.opacity = '0.5')}
                                    onMouseLeave={(e) => (e.target.style.opacity = '1')}
                                >
                                    User
                                </span>
                            </Link>
                        );
                    } else if (type === 'volume_partner') {
                        return (
                            <Link to={`/volume-partners/${data.partner_account}`} key={index}>
                                <span
                                    className="badge bg-offline text-t-offline me-2"
                                    onMouseEnter={(e) => (e.target.style.opacity = '0.5')}
                                    onMouseLeave={(e) => (e.target.style.opacity = '1')}
                                >
                                    Vol
                                </span>
                            </Link>
                        );
                    }
                    return null;
                })}
            </div>
        );

        return (
            <Link to={`/partner-overview/${data?.id}`}>
                <span>{partnerName}</span>
                {formattedPartnerTypes}
            </Link>
        );
    };

    const bodyAmountDue = (data) => {
        return balanceFormatter(data?.amount_due ?? 0, 'IDR');
    }
    const bodyAmountPaid = (data) => {
        return balanceFormatter(data?.amount_paid ?? 0, 'IDR');
    }
    const bodyAmountOutstanding = (data) => {
        return balanceFormatter(data?.outstanding_balance ?? 0, 'IDR');
    }

    const bodyCurrentBalance = (data) => {
        return balanceFormatter(data?.current_balance ?? 0, 'IDR');
    }

    const handleRetryBtnPayoutStatus = async (paymentRequestId, paymentRequestStatus) => {
        setLoading(true);
        try {
            const opts = {
                partner_configuration_id: paymentRequestId,
                period: period,
                status: paymentRequestStatus,
            }
            const response = await axiosApi.post('/api/admin/retry-payout', opts);
            setLoading(false)
            showToast(response.data);
            setPartnerRefresh(makeid(5));
        } catch (error) {
            setLoading(false)
            console.log(error);
            showToast(error);
        }
    };

    const bodyPayoutStatus = (data) => {
        let badgeText = data.payout_status
        let badgeColor = ''

        if (badgeText === 'success') {
            badgeColor = 'success'
        } else if (badgeText === 'ready') {
            badgeColor = 'success'
        } else if (badgeText === 'failed') {
            badgeColor = 'offline'
        } else if (badgeText === 'pending') {
            badgeColor = 'pending'
        } else if (badgeText === 'cancelled') {
            badgeColor = 'offline'
        } else if (badgeText === "-") {
            return <p className="ms-5">-</p>;
        }

        return (
            <div className="d-flex align-items-center gap-2">
                {data.payment_request_id ? (
                    <Link to={`/accounting/${data?.payment_request_id}`}>
                        <span
                            className={`badge bg-${badgeColor} text-t-${badgeColor}`}
                            onMouseEnter={(e) => (e.target.style.opacity = '0.5')}
                            onMouseLeave={(e) => (e.target.style.opacity = '1')}
                        >
                            {badgeText}
                        </span>
                    </Link>
                ) : (
                    <>
                        <span className={`badge bg-${badgeColor} text-t-${badgeColor}`}>
                            {badgeText}
                        </span>
                    </>
                )}

                {badgeText === 'failed' && (
                    <Button
                        className="ms-5"
                        color="primary"
                        onClick={() => handleRetryBtnPayoutStatus(data.id, data.payout_status)}
                        size="sm"
                        disabled={loading}
                    >
                        {loading ? (
                            <i className={`mdi ${loading && 'mdi-loading mdi-spin'}`} />
                            ) : (
                                'Retry'
                            )
                        }
                    </Button>
                )}
            </div>
        )
    }

    const warningIncompleteData = (data) => {
        if (data.flag_incomplete_partner) {
            return 'warningBg';
        }
    }

    const handleEligibleClicked = () => {
        setEligibleClicked(!eligibleClicked);
        setPartnerRefresh(makeid(5));
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Partner Name', body: bodyPartnerName, className: '', sortable: true },
        { field: 'outstanding_balance', header: 'Outstanding Balance', body: bodyAmountOutstanding, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } ,sortable: true },
        { field: 'current_balance', header: 'Current Balance', body: bodyCurrentBalance, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } ,sortable: true },
        { field: 'amount_due', header: 'Amount Due', body: bodyAmountDue, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'amount_paid', header: 'Amount Paid', body: bodyAmountPaid, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true},
        { field: 'payout_status', header: 'Payout Status', body: bodyPayoutStatus, className: 'text-uppercase'},
    ]

    return (
        <Card >
            <CardBody>
                <Row className="align-items-end mb-2">
                    <Col xs={12} md={3} lg={3} className="d-flex align-items-center">
                        {/* <h5>Report Period</h5> */}
                        <Calendar locale="en" maxDate={maxDate} value={filterPeriod} onChange={changePeriod} view="month" dateFormat="mm/yy" showIcon />
                    </Col>

                    <Col xs={12} md={4} lg={4} className="d-flex justify-content-start justify-content-md-center mt-2 mt-lg-0">
                        <Button
                            color={eligibleClicked ? "primary" : "secondary"}
                            onClick={handleEligibleClicked}
                            className=""
                        >
                            Eligible Only
                        </Button>
                    </Col>

                    <Col xs={10} md={4} lg={4} className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap mt-2 mt-lg-0">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                        </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="d-flex justify-content-md-end justify-content-lg-start mt-2 mt-lg-0">
                        <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                            <DataExportCSV lazyState={lazyState} period={period} />
                        </div>
                    </Col>
                </Row>
                <DataTable
                    // lazy
                    removableSort
                    value={dataLoading ? items : dataTable}
                    stripedRows
                    paginator
                    rows={lazyState.config.rows}
                    rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                    size={'normal'}
                    // onPage={onPage}
                    totalRecords={dataTotalRecords}
                    first={lazyState.config.first}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    dataKey={dataLoading ? '' : 'id'}
                    defaultSortOrder={-1}
                    rowClassName={warningIncompleteData}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={col.field}
                            sortable={col.sortable}
                            field={col.field}
                            header={col.header}
                            headerClassName={col.headerClassName}
                            style={col.style}
                            body={dataLoading ? <Skeleton></Skeleton> : col.body}
                            bodyClassName={col.className}
                        />
                    ))}
                </DataTable>
            </CardBody>
        </Card>
    )
}

export default ReportTable