import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useParams } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import useAuth from 'hooks/useAuth';
import axiosApi from 'helpers/api_helper';
import PickupsTable from './PickupsTable';

const Pickups = () => {
  const { auth } = useAuth();
  const { id } = useParams();
  const [expandedRows, setExpandedRows] = useState(null);

  const [refresh, setRefresh] = useState('');
  const [menu, setMenu] = useState(false);
  const [pickupDatas, setPickupDatas] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [idParamStatus, setIdParamStatus] = useState(id ? false : true)


  const [lazyState, setlazyState] = useState({
    searchText: id||'',
    platform: "",
    config: {
      first: 0,
      rows: 25,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        name: { value: '', matchMode: 'contains' },
      },

    }
  });

  const toggle = () => {
    setMenu(!menu);
  };

  const getPickups = async () => {
    try {
      setLoading(true)
      const response = await axiosApi.get(`/api/admin/pickup/getAllPickups?rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&platform=${lazyState.platform}`);
      const result = response.data.result;
      if (!idParamStatus) {
        if (result.length > 0) {
          setExpandedRows({ [result[0].pickup_id]: true })
          setIdParamStatus(true)
        }
      }
      setPickupDatas(result);
      if (response.data.totalRecords !== null && response.data.totalRecords !== undefined) {
        setTotalRecords(response.data.totalRecords)
      } else {
        setTotalRecords(0)
      }

      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getPickups();
  }, [lazyState, refresh]);

  document.title = `${process.env.REACT_APP_NAME || ''} | Pickups`;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Support" breadcrumbItem="Pickups" />


          <PickupsTable refresh={refresh} id={id} pickupDatas={pickupDatas} totalRecords={totalRecords} lazyState={lazyState} setlazyState={setlazyState} loading={loading} setRefresh={setRefresh} expandedRows={expandedRows} setExpandedRows={setExpandedRows} />
        </div>
      </div>
    </React.Fragment>
  );
};



export default Pickups;
