import '../../../assets/css/style.css'
import { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Link } from "react-router-dom";
import moment from "moment";
import { simplify, debounce, statusUco, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM } from "constants/value";
import useOverview from 'hooks/useOverview';
const Units = ({ lazyState, setlazyState, totalRecords }) => {
    const { loading, ucoUnits } = useOverview();
    const [expandedRows, setExpandedRows] = useState(null);
    const [search, setSearch] = useState('')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 300;

    const checkBox = (data) => {
        return data?.box[0]?.name || 'deleted box'
    }
    const checkMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual"
        } else if (method == "pickup") {
            return "Pickup (" + (data?.box[0]?.name ?? '') + ")";
        } else if (method == "bonus") {
            return "Monthly Bonus"
        } else if (method == "promotion") {
            return "Promotion Reward";
        } else {
            return "Box (" + (data?.box[0]?.name ?? '') + ")";
        }
    }
    const transactionMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual";
        } else if (method == "pickup") {
            return "Pickup"
        } else if (method == "promotion") {
            return "Promotion Reward";
        } else if (method == "manual") {
            return "Box";
        }
    }

    const boxFullAddress = (data) => {
        return data?.box[0]?.address || '-'
    }

    const boxAddressCoordinate = (data) => {
        if (data?.box[0]?.location.latitude) {
            return data?.box[0]?.location.latitude + ' ' + data?.box[0]?.location.longitude
        } else {
            return '-'
        }
    }


    const checkUser = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }

    const checkUsername = (data) => {
        return data?.user[0]?.username || 'deleted user'
    }
    const usernameAddress = (data) => {
        if (data?.user[0]?.address) {
            return data?.user[0]?.address + ', ' + data?.user[0]?.city + ' ' + data?.user[0]?.postcode + ', ' + data?.user[0]?.country
        } else {
            return '-'
        }
    }

    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }

    const roundedQuantity = (data) => {
        return simplify(data.weight)
    }

    const amountBody = (data) => {
        return balanceFormatter(data?.amount, data?.uco_unit?.currency) || "-"
    }

    const feesBody = (data) => {
        return balanceFormatter(data?.fees, data?.uco_unit?.currency) || "-"
    }

    const trxId = (data) => {
        return <Link to={`/transactions/${data?.uco_unit?.external_transaction_code}`} >
            {data?.uco_unit?.external_transaction_code}
        </Link>
    }
    const ucoUnitId = (data) => {
        return <Link to={`/uco-units`} state={data?.uco_unit_code} >
            {data?.uco_unit_code}
        </Link>
    }
    const fullName = (data) => {
        return <Link to={`/userlist`} state={data?.uco_unit?.fullname} >
            {data?.uco_unit?.fullname}
        </Link>
    }
    const boxLink = (data) => {
        var valueState = data?.tank?.type_id == 1 ? { boxes: data?.tank?.name } : data?.tank?.type_id == 2 ? { tank: data?.tank?.name } : { transport: data?.tank?.name };
        return <Link to={`/assets?tab=${data?.tank?.type_id == '3' ? 4 : data?.tank?.type_id}`} state={valueState} >
            {data?.tank?.name}
        </Link>
    }

    const startTime = data => {
        return moment(data?.uco_unit?.external_created_date).format("MM/DD/YYYY HH:mm:ss")
    }

    const columns = [
        { field: 'uco_unit_code', header: 'UCO Unit ID', body: ucoUnitId, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Name', body: fullName, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Transaction ID', body: trxId, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: 'weight', header: 'Quantity (kg)', body: roundedQuantity, className: 'text-center', headerClassName: '', style: { } },
        // { field: 'amount', header: 'Amount', body: amountBody, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        // { field: 'fees', header: 'Fees', body: feesBody, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Box ID', body: boxLink, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: 'TA_Start_Time', header: 'Collected Time', body: startTime, className: '', headerClassName: '', style: { } },
        { field: '', header: 'Status', body: statusUco, className: '', headerClassName: '', style: { } },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }


    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <h4 className="card-title mb-4">UCO Units</h4>
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Search" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            {/* <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <TransactionExportCSV lazyState={lazyState} />
                                </div>
                            </Col> */}
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : ucoUnits}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            // expandedRows={loading ? null : expandedRows}
                            // rowExpansionTemplate={rowExpansionTemplate}
                            // onRowToggle={onRowToggle}
                            // rowClassName={rowClassName}
                            dataKey={loading ? '' : 'uco_unit_code'}
                        >
                            {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                            {columns.map((col, i) => (
                                <Column

                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Units