import React, { useEffect, useState } from "react"
import AdminPartnerTable from "./AdminPartnerTable"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useAdminPartner from "hooks/useAdminPartner"
import useAuth from "hooks/useAuth"
import { useParams, useLocation } from "react-router-dom"

const AdminPartnerList = () => {
    const axiosApi = useAxiosPrivate()
    const { refreshAdminPartners, setAdminPartners, setAdminPartnersLoading, } = useAdminPartner()
    const { auth } = useAuth()
    const location = useLocation()
    const propsData = location.state
    const { id } = useParams()

    const [lazyState, setLazyState] = useState({
        searchText: propsData || id || "",
        config: {
            filters: {
                name: { value: "", matchMode: "contains" },
            },
        },
    })

    useEffect(() => {
        setAdminPartnersLoading(true);
        let isMounted = true;
        const controller = new AbortController;

        const getAdminsPartners = async () => {
            setAdminPartnersLoading(true);
            try {
                const response = await axiosApi.get('/api/admin/brand/getAdmins', {
                    signal: controller.signal,
                });
                isMounted && setAdminPartners(response.data);
            } catch (err) {
                console.log(err);
            }
            setAdminPartnersLoading(false);
        }

        getAdminsPartners();

        return () => {
            isMounted = false;
            controller.abort;
        }
    }, [refreshAdminPartners, lazyState]);

    return (
        <>
            <AdminPartnerTable idParam={id} id={propsData} lazyState={lazyState} setLazyState={setLazyState} />
        </>
    )
}

export default AdminPartnerList
