import '../../../assets/css/style.css'
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Button, Modal, ModalBody, ModalHeader, } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Link } from "react-router-dom";
import Select from "react-select";
import { simplify, debounce, statusUco, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_LARGE } from "constants/value";
import TransactionHistoryModal from './modals/TransactionHistoryModal';
import useUcoUnit from 'hooks/useUcoUnit';
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment"
import { useLocation } from 'react-router-dom';
import ExportFilterModal from '../ExportFilterModal';
import UnitExportCSV from './exports/UnitExportCSV';

const Units = ({ lazyState, setlazyState, totalRecords }) => {

    const location = useLocation();

    const { setRefresh, loading, ucoUnits } = useUcoUnit();
    const [expandedRows, setExpandedRows] = useState(null);
    const [tanks, setTanks] = useState(null);
    const axiosApi = useAxiosPrivate();
    const [selectedTank, setSelectedTank] = useState('')

    const [search, setSearch] = useState(lazyState.searchText)
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 300;
    const [headerFilter, setHeaderFilter] = useState(null);
    const [isExport, setIsExport] = useState(false);

    const handleConfirm = async ({ data, exportHeader }) => {
        const headers = data.fields;

        const filteredHeaders = exportHeader.filter(header =>
            headers.includes(header.key)
        );
        const result = {
            headers: filteredHeaders,
            isAnonymous: data.isAnonymous
        }

        setIsExport(true)
        setHeaderFilter(result)
        // setIsExport(false)
    }

    const getTanks = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/tanks?show_all=true`);
            var locList = response.data.results;
            let allBoxes = []
            if (locList)
                locList.forEach(element => {
                    var location = {
                        ...element,
                        name: element.name,
                        value: element.id,
                        label: `${element.name} (${element.capacity ?? 0}/${element.max_capacity ?? 0}${element.tankcapacitytype_unit_name ? ` ${element.tankcapacitytype_unit_name}` : ''})`

                    }
                    allBoxes.push(location);

                });
            allBoxes.unshift({ name: "All", value: "", id: '', capacity: 0, label: "All" })

            setSelectedTank(allBoxes[0])
            setTanks(allBoxes);

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getTanks();
    }, []);
    const roundedVol = (data) => {
        return simplify(data.volume)
    }

    const roundedQuantity = (data) => {
        return simplify(data.weight)
    }

    const amountBody = (data) => {
        return balanceFormatter(data?.amount, data?.uco_unit?.currency) || "-"
    }

    const feesBody = (data) => {
        return balanceFormatter(data?.fees, data?.uco_unit?.currency) || "-"
    }

    const ucoUnitId = (data) => {
        return (
            <TransactionHistoryModal rowData={data} unitIdParam={data.uco_unit_code} />
        )
    }

    const trxId = (data) => {
        return <Link to={`/transactions/${data?.uco_unit?.external_transaction_code}`} >
            {data?.uco_unit?.external_transaction_code}
        </Link>
    }
    const fullName = (data) => {
        return <Link to={`/userlist`} state={data?.uco_unit?.fullname} >
            {data?.uco_unit?.fullname}
        </Link>
    }
    const boxLink = (data) => {
        var valueState = data?.tank?.type_id == 1 ? { boxes: data?.tank?.name } : data?.tank?.type_id == 2 ? { tank: data?.tank?.name } : { transport: data?.tank?.name };
        return <Link to={`/assets?tab=${data?.tank?.type_id == '3' ? 4 : data?.tank?.type_id}`} state={valueState} >
            {data?.tank?.name}
        </Link>
    }
   
    const startTime = data => {
        return moment(data?.uco_unit?.external_created_date).format("MM/DD/YYYY HH:mm:ss")
    }
    const columns = [
        { field: '', header: 'UCO Unit ID', body: ucoUnitId, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Name', body: fullName, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Transaction ID', body: trxId, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: 'weight', header: 'Quantity (kg)', body: roundedQuantity, className: 'text-center' , headerClassName: '', style: { }},
        // { field: 'amount', header: 'Amount', body: amountBody, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        // { field: 'fees', header: 'Fees', body: feesBody, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Box ID', body: boxLink, className: '', headerClassName: '', style: { whiteSpace: 'nowrap' } },
        { field: 'TA_Start_Time', header: 'Collected Time', body: startTime, className: '', headerClassName: '', style: { } },
        { field: '', header: 'Status', body: statusUco, className: '', headerClassName: '', style: { } },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    const changeTank = (value) => {
        let _filters = { ...lazyState };
        _filters.id = value.id;
        _filters.boxId = value.name;
        
        setlazyState(_filters)
        setSelectedTank(value)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                {/* <h5>Transaction ID : {data.TA_ID}</h5> */}
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <ul>
                            <li>Username : {data?.uco_unit?.username}</li>
                            <li>Name : {data?.uco_unit?.fullname}</li>
                            <li>Address : {data?.uco_unit?.address}</li>
                            <li>Postcode : {data?.uco_unit?.postcode}</li>
                        </ul>

                    </div>

                    <Divider layout="vertical" />

                    <div>
                        <ul>
                            <li>UCO Unit ID : {data?.uco_unit?.external_transaction_code}</li>
                            <li>Box ID : {data?.tank?.name}</li>
                            <li>Quantity : {roundedQuantity(data)}</li>
                            <li>Time : {startTime(data)}</li>
                            <li>Status : {data?.uco_unit?.status_name}</li>
                            <li>Amount : {balanceFormatter(data?.amount, data?.uco_unit?.currency)}</li>
                            <li>Fees : {balanceFormatter(data?.fees, data?.uco_unit?.currency)}</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={5} xs={12} className="d-flex flex-row align-items-center gap-3">
                            </Col>

                            <Col md={4} xs={12} className='mb-2'>
                                <div className="col" width="240px">
                                    <Select
                                        value={selectedTank}
                                        onChange={changeTank}
                                        options={tanks}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            </Col>

                            <Col md={3} xs={4} className="d-flex align-items-center mb-2">
                                <span className="p-input-icon-left float-start float-md-end me-2">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Search" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>

                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <ExportFilterModal
                                        onConfirm={handleConfirm}
                                        totalRecords={totalRecords}
                                        anonymous={true}
                                        isExport={isExport}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            lazy
                            value={loading ? items : ucoUnits}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_LARGE}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            dataKey={loading ? '' : 'id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                />
                            ))}
                        </DataTable>
                        {headerFilter && isExport && <UnitExportCSV lazyState={lazyState} datas={headerFilter} isExport={isExport} setIsExport={setIsExport} />}

                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Units