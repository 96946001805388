import React, { Component, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { simplify } from "helpers/functions"

const FillingLevel = ({ approved, notApproved }) => {
    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
        },
        plotOptions: {
            radialBar: {
                pie: {
                    donut: {
                        size: '45%',
                    }
                }
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#70ad47', '#ec4561'],
        labels: ['Accepted', 'Rejected'],
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "%";
                }
            }
        }
    })

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={[simplify(approved), simplify(notApproved)]}
                type="donut"
                height="140px"
            />
        </React.Fragment>
    );
}

export default FillingLevel;