import { useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Message } from 'primereact/message';
import { post, del, get, put } from "../../../helpers/api_helper";
import useAuth from "hooks/useAuth";

const RejectTransactionModal = ({ transaction ,setRefresh}) => {
    const { showToast,auth } = useAuth();
  const [modal_center, setmodal_center] = useState(false);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const [modal_center_delete, setmodal_center_delete] = useState(false);
    const tog_center_delete = () => {
        setmodal_center_delete(!modal_center_delete);
        removeBodyCss();
        validation_delete.resetForm();
    };
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: transaction._id,
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await post('/api/transaction/reject', values);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
                // location.reload();
            } catch (error) {
                console.log(error);
            }
        }
    });

    const validation_delete = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: transaction._id,
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await post('/api/transaction/invalidate', values);
                setRefresh(response.random);
                resetForm();
                tog_center_delete();
                showToast(response);
                // location.reload();
            } catch (error) {
                console.log(error);
            }
        }
    });

    const isSuperAdmin = () => {
        if (auth && auth?.auth && auth?.auth?.level == 'superadmin') {
            return true;
        } else {
            return false;
        }
    }


    return (
        <>
        <div className="my-2">
        {transaction?.UCO_Approved &&
            <>
                <Button className="mb-1" color="danger" onClick={tog_center}>
                    Reject Transaction
                </Button>
                <br></br>
            </>
          
        }
        { isSuperAdmin() &&
            <Button color="dark" onClick={tog_center_delete}>
                Delete Transaction
            </Button>
        }
        </div>
        <Modal isOpen={modal_center} toggle={tog_center} centered>
            <ModalHeader className="mt-0" toggle={tog_center}>Reject Transaction</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <h5>Are you sure you want to reject this transaction? The amount will be deducted from the user balance</h5>
                    <br>
                    </br>
                    <h5><span className="text-danger">Note: This action cannot be undone</span></h5>
                    <Message severity="info" text="ℹ️ Reject Transaction should be used if the UCO turned out to be a slops (like Motor Oil , Water , etc). The transaction will be rejected and all UCO amount will be converted to Slops" />
                    <br></br>
                 
                    <br></br>
                  
                    <div className="mb-3">
                            <input type="hidden" name="id" value={ validation.values.id || transaction._id } />
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button type="submit" color="danger" >
                            Reject
                        </Button>{" "}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
        <Modal isOpen={modal_center_delete} toggle={tog_center_delete} centered>
            <ModalHeader className="mt-0" toggle={tog_center_delete}>Delete Transaction</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation_delete.handleSubmit();
                        return false;
                    }}>
                       <h5>Are you sure you want to delete this transaction? The amount will be deducted from the user balance and the transaction will be deleted permanently     <span style={{ display: 'none' }}>it is a lie like a cake. actually we keep a record of deleted transaction using this button</span></h5>
                    <br>
                    </br>
                    <h5><span className="text-danger">Note: This action cannot be undone</span></h5>
                    <Message severity="info" text="ℹ️ Delete Transaction should be used if there is a mistake in Manual Transaction input (like a typo for example)" />
                    <br></br>
                 
                    <br></br>

                    <div className="mb-3">
                            <input type="hidden" name="id" value={ validation.values.id || transaction._id } />
                    </div>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button type="submit" color="danger" >
                            Delete
                        </Button>{" "}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
        </>
    )
    
}

export default RejectTransactionModal
