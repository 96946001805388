import { INITIAL_OPENING_HOURS } from "constants/value";
import { createContext, useState } from "react";

const BoxContext = createContext({});

export const BoxProvider = ({ children }) => {
    const [totalRecords, setTotalRecords] = useState(0);
    const [box, setBox] = useState(null);
    const [boxIdFilter, setBoxIdFilter] = useState(null);
    const [boxImages, setBoxImages] = useState([]);
    const [limitImages, setLimitImages] = useState(false);
    const [isRight, setIsRight] = useState(false);
    const [collectionPoint, setCollectionPoint] = useState(false);
    const [dashCollectionPoint, setDashCollectionPoint] = useState(false);
    const [heartbeatEnabled, setHeartbeatEnabled] = useState(false);
    const [datas, setDatas] = useState([]);
    const [transDatas, setTransDatas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [pin, setPin] = useState(false);
    const [refresh, setRefresh] = useState('');
    const [refreshTransaction, setRefreshTransaction] = useState('');
    const [refreshGraph, setRefreshGraph] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [groupFilter, setGroupFilter] = useState('all');
    const [selectedLatitude, setSelectedLatitude] = useState('all');
    const [selectedLongitude, setSelectedLongitude] = useState('all');
    const [groupStatus, setGroupStatus] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState('id');
    const [hideBox, setHideBox] = useState(false);
    const [statusFilter, setStatusFilter] = useState('any');

    const [transaction, setTransaction] = useState([]);
    const [capacity, setCapacity] = useState(0);
    const [slops, setSlops] = useState(0);
    const [eBoxTemp, setEBoxTemp] = useState(0);
    const [chamberTemp, setChamberTemp] = useState(0);
    const [lazyState, setlazyState] = useState({
        searchText: '',
        boxArr: '',
        startDate: '',
        endDate: '',
        config: {
            first: 0,
            rows: 25,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            }
        }
    });

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
        setLimitImages(false);
    };

    const filterTransaction = (id, result, setVolume, setWeight, setSeries) => {
        let resultVolume = [];
        let resultWeight = [];
        result.map((data, index) => {
            let totalVolume = 0;
            let totalWeight = 0;
            data.map((dt, i) => {
                if (id === null || id === dt?.boxId) {
                    totalVolume += dt?.TA_UCO_Volume || 0;
                    totalWeight += dt?.TA_UCO_Weight || 0;
                }
            })
            resultVolume[index] = totalVolume || 0;
            resultWeight[index] = totalWeight || 0;
        })

        setVolume(resultVolume);
        setSeries([
            {
                name: 'Weight',
                data: resultWeight,
            }
        ]);
        setWeight(resultWeight);
    }

    const daysInWeek = [
        { name: 'Monday', value: 'monday' },
        { name: 'Tuesday', value: 'tuesday' },
        { name: 'Wednesday', value: 'wednesday' },
        { name: 'Thursday', value: 'thursday' },
        { name: 'Friday', value: 'friday' },
        { name: 'Saturday', value: 'saturday' },
        { name: 'Sunday', value: 'sunday' }
    ]

    const boxModes = [
        { name: 'Ready', value: 'active' },
        { name: 'Offline', value: 'offline' },
        { name: 'Maintenance', value: 'maintenance' },
        { name: 'Planned', value: 'planned' },
    ];

    const boxLanguages = [
        { name: 'Bahasa Indonesia', value: 'id' },
        { name: 'English', value: 'en' },
        { name: 'Chinese', value: 'zh' },
        { name: 'Malay', value: 'ms' },
        { name: 'Thai', value: 'th' },
        { name: 'Vietnamese', value: 'vi' },
        { name: 'Filipino', value: 'ph' },
        { name: 'Basa Sunda', value: 'su' },
        { name: 'Bahasa Jawa', value: 'jw' },
    ]

    const timezones = [
        { name: 'WIB (+7)', value: 'WIB', modifiier: 7 },
        { name: 'WITA (+8)', value: 'WITA', modifier: 8 },
        { name: 'SGT (+8)', value: 'SGT', modifier: 8 },
        { name: 'MYT (+8)', value: 'MYT', modifier: 8 },
        { name: 'WIT (+9)', value: 'WIT', modifier: 9 }
        
    ]

    const [selectedMode, setSelectedMode] = useState('')
    const [openingHoursArray, setOpeningHoursArray] = useState(INITIAL_OPENING_HOURS)
    const [selectedGroup, setSelectedGroup] = useState('')

    const limitImagesCount = 10

    const changeTimezone = (e) => {
        setOpeningHoursArray(prevArray => prevArray.map(item => ({
            ...item,
            timezone: e.value,
        })))
    }

    const boxStatuses = [
        { name: 'Any Status', value: 'any' },
        { name: 'Closed', value: 'closed' },
        { name: 'Open', value: 'open' },
        { name: 'Maintenance', value: 'maintenance' },
        { name: 'Planned', value: 'planned' },
        { name: 'Offline', value: 'offline' },
    ];

    return (
        <BoxContext.Provider value={{ groupOptions, setGroupOptions, box, setBox, isRight, setIsRight, toggleRightCanvas, datas, setDatas, loading, setLoading, pin, setPin, refresh, setRefresh, transaction, setTransaction, filterTransaction, capacity, setCapacity, slops, setSlops, transDatas, setTransDatas, loading2, setLoading2, lazyState, setlazyState, totalRecords, setTotalRecords, boxModes, selectedMode, setSelectedMode, boxImages, setBoxImages, limitImages, setLimitImages, limitImagesCount, timezones, daysInWeek, openingHoursArray, setOpeningHoursArray, changeTimezone, setHeartbeatEnabled, heartbeatEnabled,  dashCollectionPoint, setDashCollectionPoint, collectionPoint, setCollectionPoint, eBoxTemp, setEBoxTemp, chamberTemp, setChamberTemp, boxIdFilter, setBoxIdFilter, refreshTransaction, setRefreshTransaction, selectedGroup, setSelectedGroup, groupFilter, setGroupFilter, refreshGraph, setRefreshGraph, selectedLatitude, setSelectedLatitude, selectedLongitude, setSelectedLongitude, groupStatus, setGroupStatus, boxLanguages, selectedLanguage, setSelectedLanguage, hideBox, setHideBox, boxStatuses, statusFilter, setStatusFilter }}>
            {children}
        </BoxContext.Provider>
    )
}

export default BoxContext;