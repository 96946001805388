import React, { useState } from "react"
import {
    Button,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    FormGroup,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { post, del, get, put } from "../../../../../helpers/api_helper"
import useAdminPartner from "hooks/useAdminPartner"
import useAuth from "hooks/useAuth"

import alfamartLogo from "../../../../../assets/images/partner/alfamartLogo.svg"
import mypertaminaLogo from "../../../../../assets/images/partner/mypertamina.svg"

const DeleteAdminPartnerModal = ({ admin }) => {
    const { showToast } = useAuth()
    const { setRefreshAdminPartners, brandList, levelOptions } =
        useAdminPartner()

    const [modal_center, setmodal_center] = useState(false)

    const tog_center = () => {
        setmodal_center(!modal_center)
        removeBodyCss()
        validation.resetForm()
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding")
    }

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: admin._id,
        },

        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await post("/api/admin/brand/delete", values)
                setRefreshAdminPartners(response.random)
                resetForm()
                tog_center()
                showToast(response)
            } catch (error) {
                console.log(error)
            }
        },
    })

    return (
        <>
            <div className="my-2">
                <Button color="danger" onClick={tog_center}>
                    <i className="fas fa-trash"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    Delete Partner Dashboard User &nbsp;&nbsp;
                    <img
                        src={
                            admin?.brand === "mypertamina"
                                ? mypertaminaLogo
                                : alfamartLogo
                        }
                        alt="logo's partner"
                        style={{ width: "90px" }}
                    />
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >
                        <h5>You sure want to delete this admin ?</h5>
                        <div className="mb-3">
                            <input
                                type="hidden"
                                name="id"
                                value={validation.values.id || admin._id}
                            />
                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="danger">
                                Delete
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DeleteAdminPartnerModal
