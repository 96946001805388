import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, FormFeedback, Input, Label, Form, Row } from "reactstrap";
import useAuth from "hooks/useAuth";

import Select from "react-select"
import usePartner from "hooks/usePartner";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import TooltipInfo from "components/Partner/TooltipInfo";

const PartnerForm = ({ activeTab, setactiveTab, existData = null, id = null }) => {
  const { showToast } = useAuth();
  const { sectorList, entityList, setContactForm } = usePartner();
  const axiosApi = useAxiosPrivate();
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedSector, setSelectedSector] = useState('individual');
  const [selectedEntity, setSelectedEntity] = useState('corporation');
  const [selectedState, setSelectedState] = useState(true);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }
  const handleNumericInput = (event) => {
    const { value } = event.target;
    if (!/^\+?\d*$/.test(value)) {
      event.preventDefault();
    } else {
      validation.handleChange(event);
    }
  };


  const changeSector = (e) => {
    setSelectedSector(e.target.value)
  }
  const changeEntity = (e) => {
    setSelectedEntity(e.target.value)
  }
  const changeCountry = (e) => {
    setSelectedCountry(e.target.value)
    getRegions({ id: e.target.value.id });
  }

  const changeRegion = (value) => {
    setSelectedRegion(value)
    if (value && value.id) {
      getCities({ id: value.id });
    } else {
        setCityList([]);
        setSelectedCity(null);
    }
  }

  const changeCity = (value) => {
    setSelectedCity(value)
  }

  const getCities = async ({ id }) => {
    try {
      const response = await axiosApi.get(`/api/erp/master/location-cities?location_region_id=${id}`);
      let allData = []
      var dataList = response.data.results;
      if (dataList.length > 0) {
        dataList.forEach((element, index) => {
          var boxDetail = {
            ...element,
            name: element.name,
            value: element.id,
            label: element.name,
          }
          if (element.name == existData?.contact?.contact_city) {
            setSelectedCity(boxDetail)
          } else if (index == 0) {
            setSelectedCity(boxDetail)
          }
          allData.push(boxDetail);
        });
      }
      else {
        setSelectedCity(null)
      }
      setCityList(allData);

    } catch (error) {
      console.log(error);
    }
  }
  const getRegions = async ({ id }) => {
    try {
      const response = await axiosApi.get(`/api/erp/master/location-regions?location_country_id=${id}`);
      let allData = []
      var dataList = response.data.results;
      if (dataList.length > 0) {
        dataList.forEach((element, index) => {
          var boxDetail = {
            ...element,
            name: element.name,
            value: element.id,
            label: element.name,
          }

          if (element.name === existData?.contact?.contact_region) {
            setSelectedRegion(boxDetail)
            getCities({ id: boxDetail.id });
          } else if (index === 0) {
            setSelectedRegion(boxDetail)
            getCities({ id: boxDetail.id });
          }
          allData.push(boxDetail);
        });
      } else {
        setSelectedRegion(null)
        // getCities({ id: null });
        setCityList([]);
        setSelectedCity(null);
      }

      setRegionList(allData);
    } catch (error) {
      console.log(error);
    }
  }
  const getCountries = async () => {
    try {
      const response = await axiosApi.get(`/api/erp/master/location-countries`);
      var dataList = response.data.results;
      let allData = []
      if (dataList.length > 0) {
        dataList.forEach((element, index) => {
          if (element.name == existData?.contact?.contact_country) {
            setSelectedCountry(element)
            getRegions({ id: element.id });
          } else if (index == 0) {
            setSelectedCountry(element)
            getRegions({ id: element.id });
          }
          allData.push(element);
        });
      }
      else {
        setSelectedCountry(null)
        getRegions({ id: null });
      }
      setCountryList(allData);
    } catch (error) {
      console.log(error);
    }
  }

  const changeState = (e) => {
    setSelectedState(e.value)
  }

  useEffect(() => {
    // getCities();

    setSelectedSector(existData?.contact?.sector_type || 'individual');
    setSelectedEntity(existData?.contact?.entity_type || 'corporation')
    setSelectedState(existData?.contact?.active !== undefined ? existData.contact.active : true);
    getCountries();

  }, [existData]);

  useEffect(() => {
    if (selectedRegion && selectedRegion.id) {
        getCities({ id: selectedRegion.id });
    }
  }, [selectedRegion]);

  // Form validation 
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner_account: existData?.partner?.partner_account || "",
      contact_ktp: existData?.contact?.contact_ktp || '',
      contact_npwp: existData?.contact?.contact_npwp || '',
      legal_name: existData?.contact?.legal_name || '',
      contact_name: existData?.contact?.contact_name || '',
      contact_address: existData?.contact?.contact_address || '',
      contact_email: existData?.contact?.contact_email || '',
      monthly_report_email: existData?.contact?.monthly_report_email || '',
      contact_email: existData?.contact?.contact_email || '',
      contact_phone: existData?.contact?.contact_phone || '',
      sustainability_report_email: existData?.contact?.sustainability_report_email || '',
      contact_postalcode: existData?.contact?.contact_postalcode || '',
      active: existData?.contact?.active || true,
    },
    validationSchema: Yup.object().shape({
      partner_account: Yup.string().required("This value is required"),
      contact_ktp: Yup.string().required("This value is required"),
      contact_npwp: Yup.string().nullable(),
      legal_name: Yup.string().required("This value is required"),
      contact_name: Yup.string().required("This value is required"),
      contact_address: Yup.string().required("This value is required"),
      contact_postalcode: Yup.string().required("This value is required"),
      contact_email: Yup.string().nullable(),
      contact_phone: Yup.string().matches(/^\+[0-9]+$/, 'Phone number must start with + and contain only numbers.').required("This value is required"),
      monthly_report_email: Yup.string().nullable(),
      sustainability_report_email: Yup.string().nullable(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const opts = { ...values, ...(id ? { partner_configuration_id: id } : {}), entity_type: selectedEntity, sector_type: selectedSector, contact_country: selectedCountry?.name || '', contact_region: selectedRegion?.name || '', contact_city: selectedCity?.name || "", active: selectedState };
        const response = await axiosApi.post('/api/admin/partner-wizard/validate/contact', opts);
        setContactForm(opts);
        toggleTab(activeTab + 1);
        // toggleTab(activeTab + 1);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.state) {
          showToast(error.response.data);
          validation.setErrors(error.response.data);
        } else {
          let response = {};
          response.state = "error";
          response.toast = true;
          response.message = "Internal Server Error"
          showToast(response);
        }
      }
    }
  });
  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
        <Row className="mb-3">
          <label htmlFor="partner_account"
            className="col-lg-3 col-md-3 col-form-label">Partner Account Name <TooltipInfo string="partnerAccountName" /></label>
          <div className="col-lg-9 col-md-9">
            <Input
              name="partner_account"
              placeholder="Enter Partner Account Name"
              type="text"
              autoComplete="off"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.partner_account || ""}
              invalid={
                validation.touched.partner_account && validation.errors.partner_account
                  ? true
                  : false
              }
              style={{ width: '300px' }}
            />
            {validation.touched.partner_account && validation.errors.partner_account ? (
              <FormFeedback type="invalid">
                {validation.errors.partner_account}
              </FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">Entity Type <TooltipInfo string="entityType" /></Label>
          <div className="col-lg-5 col-md-5">
            <Dropdown
              value={selectedEntity || ''}
              onChange={changeEntity}
              options={entityList}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">Sector Type <TooltipInfo string="sectorType" /></Label>
          <div className="col-lg-5 col-md-5">
            <Dropdown
              value={selectedSector || ''}
              onChange={changeSector}
              options={sectorList}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">KTP <TooltipInfo string="ktp" /></Label>
          <div className="col-lg-5 col-md-5">
            <Input
              name="contact_ktp"
              placeholder="Enter KTP"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contact_ktp || ""}
              invalid={
                validation.touched.contact_ktp && validation.errors.contact_ktp ? true : false
              }
            />

            {validation.touched.contact_ktp && validation.errors.contact_ktp ? (
              <FormFeedback type="invalid">{validation.errors.contact_ktp}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">NPWP <TooltipInfo string="npwp" /></Label>
          <div className="col-lg-5 col-md-5">
            <Input
              name="contact_npwp"
              placeholder="Enter NPWP"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contact_npwp || ""}
              invalid={
                validation.touched.contact_npwp && validation.errors.contact_npwp ? true : false
              }
            />

            {validation.touched.contact_npwp && validation.errors.contact_npwp ? (
              <FormFeedback type="invalid">{validation.errors.contact_npwp}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Legal Name <TooltipInfo string="legalName" /></Label>
          <div className="col-lg-5 col-md-5">
            <Input
              name="legal_name"
              placeholder="Enter Name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.legal_name || ""}
              invalid={
                validation.touched.legal_name && validation.errors.legal_name ? true : false
              }
            />

            {validation.touched.legal_name && validation.errors.legal_name ? (
              <FormFeedback type="invalid">{validation.errors.legal_name}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Contact Name <TooltipInfo string="contactName" /></Label>
          <div className="col-lg-5 col-md-5">
            <Input
              name="contact_name"
              placeholder="Enter Name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contact_name || ""}
              invalid={
                validation.touched.contact_name && validation.errors.contact_name ? true : false
              }
            />

            {validation.touched.contact_name && validation.errors.contact_name ? (
              <FormFeedback type="invalid">{validation.errors.contact_name}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Street Address <TooltipInfo string="streetAddress" /></Label>
          <div className="col-lg-5 col-md-5">
            <Input
              name="contact_address"
              placeholder="Enter Address"
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contact_address || ""}
              invalid={validation.touched.contact_address && validation.errors.contact_address}
              style={{ resize: "none" }} // Adding this inline style
            />

            {validation.touched.contact_address && validation.errors.contact_address ? (
              <FormFeedback type="invalid">{validation.errors.contact_address}</FormFeedback>
            ) : null}
          </div>
        </Row>
        {/* Country */}
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">Country <TooltipInfo string="country" /></Label>
          <div className="col-lg-5 col-md-5">
            <Dropdown
              value={selectedCountry || ''}
              onChange={changeCountry}
              options={countryList}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>
        {/* Region */}
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">Region <TooltipInfo string="region" /></Label>
          <div className="col-lg-5 col-md-5">
            <Select
              name='city_id'
              value={selectedRegion}
              onChange={changeRegion}
              options={regionList}
              classNamePrefix="select2-selection"
            />
          </div>
        </Row>
        <Row className="mb-3 ">
          <Label className="col-lg-3 col-md-3 col-form-label">City <TooltipInfo string="city" /></Label>
          <div className="col-lg-5 col-md-5">
            <Select
              name='city_id'
              value={selectedCity}
              onChange={changeCity}
              options={cityList}
              classNamePrefix="select2-selection"
            />
          </div>
        </Row>
        {/* Postcode */}
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Postal Code <TooltipInfo string="postalCode" /></Label>
          <div className="col-lg-3 col-md-3">
            <Input
              name="contact_postalcode"
              placeholder="Enter Postal Code"
              type="text"
              value={validation.values.contact_postalcode || ""}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.contact_postalcode && validation.errors.contact_postalcode ? true : false
              }
              onChange={(e) => {
                const { value } = e.target;
                const maxLength = 5;
                // Check if the input value is numeric and within the max length
                if (/^\d*$/.test(value) && value.length <= maxLength) {
                  validation.setFieldValue('contact_postalcode', value) // Update the value in the formik state(value)
                }
              }}

            />
            {validation.touched.contact_postalcode && validation.errors.contact_postalcode ? (
              <FormFeedback type="invalid">{validation.errors.contact_postalcode}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3 col-md-3">Contact Email <TooltipInfo string="contactEmail" /></Label>
          <div className="col-lg-4 col-md-4">
            <Input
              name="contact_email"
              placeholder="Enter Valid Email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contact_email || ""}
              invalid={
                validation.touched.contact_email && validation.errors.contact_email ? true : false
              }
            />
            {validation.touched.contact_email && validation.errors.contact_email ? (
              <FormFeedback type="invalid">{validation.errors.contact_email}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-lg-3 col-md-3 col-form-label">Contact Phone Number <TooltipInfo string="contactPhoneNumber" /></Label>
          <div className="col-lg-4 col-md-4">
            <Input
              name="contact_phone"
              placeholder="Enter Phone Number"
              type="text"
              maxLength={15}
              onChange={handleNumericInput}
              onBlur={validation.handleBlur}
              value={validation.values.contact_phone}
              invalid={
                validation.touched.contact_phone && validation.errors.contact_phone ? true : false
              }
            />
            {validation.touched.contact_phone && validation.errors.contact_phone ? (
              <FormFeedback type="invalid">{validation.errors.contact_phone}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3 col-md-3">Sustainability Report Email <TooltipInfo string="sustainabilityReportEmail" /></Label>
          <div className="col-lg-4 col-md-4">
            <Input
              name="sustainability_report_email"
              placeholder="Enter Valid Email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.sustainability_report_email || ""}
              invalid={
                validation.touched.sustainability_report_email && validation.errors.sustainability_report_email ? true : false
              }
            />
            {validation.touched.sustainability_report_email && validation.errors.sustainability_report_email ? (
              <FormFeedback type="invalid">{validation.errors.sustainability_report_email}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3 col-md-3">Monthly Report Email <TooltipInfo string="monthlyReportEmail" /></Label>
          <div className="col-lg-4 col-md-4">
            <Input
              name="monthly_report_email"
              placeholder="Enter Valid Email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.monthly_report_email || ""}
              invalid={
                validation.touched.monthly_report_email && validation.errors.monthly_report_email ? true : false
              }
            />
            {validation.touched.monthly_report_email && validation.errors.monthly_report_email ? (
              <FormFeedback type="invalid">{validation.errors.monthly_report_email}</FormFeedback>
            ) : null}
          </div>
        </Row>
        <Row className="mb-3">
          <Label className="col-form-label col-lg-3 col-md-3">State <TooltipInfo string="state" /></Label>
          <div className="col-lg-5 col-md-5">
            <Dropdown
              value={selectedState}
              onChange={changeState}
              options={[
                { name: 'Active', value: true },
                { name: 'Dormant', value: false }
              ]}
              optionLabel="name"
              className="h-1 payment-status-dropdown"
            />
          </div>
        </Row>

        <div className="d-flex flex-wrap gap-2 justify-content-end">
          <Button
            type="submit"
            color="primary"
            disabled={validation.isSubmitting}
          >
            Next
          </Button>{" "}
        </div>
      </Form>
    </React.Fragment>
  );
};

export default PartnerForm;