import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
import usePartner from "hooks/usePartner";
import { Button as PButton, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputSwitch } from 'primereact/inputswitch';
const PartnerExportCSV = ({ lazyState }) => {
    const { entityList, sectorList } = usePartner();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedAnonymous, setSelectedAnonymous] = useState(true);
    const [fieldList, setFieldList] = useState([]);
    const [headerFilter, setHeaderFilter] = useState([]);
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    //add new modal selector
    const [modal_center, setmodal_center] = useState(false);

    const tog_center = () => {
        setmodal_center(!modal_center);
        setFieldList([]);
    };
  
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partners_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`
  
    const headers = [
        { key: "name", label: "Partner Account Name" },
        { key: "abbreviation", label: "Entity Type" },
        { key: "sector_name", label: "Sector Type" },
        { key: "contact_ktp", label: "KTP", body: "", className: "" },
        { key: "contact_npwp", label: "NPWP", body: "", className: "" },
        { key: "legal_name", label: "Legal Name", body: "", className: "" },
        { key: "contact_name", label: "Contact Name", body: "", className: "" },
        { key: "address", label: "Street Address" },
        { key: "contact_country", label: "Country", body: "", className: "" },
        { key: "contact_region", label: "Region", body: "", className: "" },
        { key: "contact_city", label: "City", body: "", className: "" },
        { key: "contact_postalcode", label: "Postal Code", body: "", className: "" },
        { key: "email", label: "Contact Email" },
        { key: "phone", label: "Contact Phone Number" },
        { key: "sustainability_report_email", label: "Sustainability Report Email", body: "", className: "" },
        { key: "monthly_report_email", label: "Monthly Report Email", body: "", className: "" },
        { key: "partner_types", label: "Type", body: "", className: "" },
        { key: "lang", label: "Language", body: "", className: "" },
        { key: "username", label: "App Username", body: "", className: "" },
        { key: 'is_fixed_price', label: 'Fixed Price (Yes / No)', body: '', className: '' },
        { key: 'fixed_price', label: 'Price / Liter', body: '', className: '' },
        { key: 'expected_volume', label: 'Exp. Monthly Volume', body: '', className: '' },
        { key: 'direct_payment_enabled', label: 'Direct Payment (Yes / No)', body: '', className: '' },
        { key: "bank_country", label: "Bank Country", body: "", className: "" },
        { key: "bank_full_name", label: "Bank Name" },
        { key: "account", label: "Account Number", body: "", className: "" },
        { key: "bank_account_name", label: "Account Name", body: "", className: "" },
    ];
   
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const updateFieldList = (value) => {
        setFieldList(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
        handleSelectAllValue();
    };

    const handleSelectAllValue = () => {
        var status = true;
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                status = false;
            }
        });
        const selectAllCheckbox = document.getElementById('select-all');
        selectAllCheckbox.checked = status;
    };

    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setFieldList([]);
        const allCheckboxes = document.querySelectorAll('input[name="header[]"]');
        allCheckboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
            updateFieldList(checkbox.value);
        });

    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const filteredHeaders = headers.filter(header =>
                    fieldList.includes(header.key)
                );
                setHeaderFilter(filteredHeaders);
                await fetchDataReq();
                tog_center();
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });


    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}&exportData=export&isAnonymize=${selectedAnonymous}`;
        
            const response = await axiosApi.get(`/api/admin/marketing/partner?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                const entity = entityList.find((item) => item.value === obj.entity_type);
                const sector = sectorList.find((item) => item.value === obj.sector_type);

                obj.abbreviation = entity ? entity.abbr : obj.entity_type;
                obj.sector_name = sector ? sector.name : obj.sector_type;

                const { bank_account_name, country } = obj.payment ?? {};
                obj.bank_account_name = bank_account_name ?? '-';
                obj.bank_country = country ?? '-';

            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => tog_center()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headerFilter}
            >
            </CSVLink>
            <Modal isOpen={modal_center} toggle={tog_center} size="md" centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    <Label>Export as CSV</Label>
                </ModalHeader>

                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <Label className="form-label">Export Field</Label>
                            <FormGroup check>
                                <Input
                                    id="select-all"
                                    name="select-all"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                />
                                <Label htmlFor="select-all">Select All</Label>
                            </FormGroup>

                            <FormGroup className="d-flex flex-wrap">
                                {headers?.map((header) => (
                                    <FormGroup check key={header.key} style={{ flex: '0 0 50%' }}>
                                        <Input id={header.key} name="header[]" type="checkbox" value={`${header.key}`} onChange={(e) => {
                                            const clickedValue = e.target.value;
                                            updateFieldList(clickedValue);
                                        }} />
                                        <Label htmlFor={header.key}>{header.label}</Label>
                                        {validation.touched.header && validation.errors.header ? (
                                            <FormFeedback type="invalid">{validation.errors.header}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        {
                            <div className="mb-3">
                                <Label className="form-label">Anonymous</Label><br />
                                <InputSwitch checked={selectedAnonymous} onChange={(e) => setSelectedAnonymous(e.value)} />
                            </div>
                        }
                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <PButton type="submit" color="primary" disabled={validation.isSubmitting || fieldList.length === 0}>
                                Submit
                            </PButton>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>

    )
}

export default PartnerExportCSV