import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import deployedBoxes from "../../assets/images/services-icon/deployed-boxes.png";
import ucoCollected from "../../assets/images/services-icon/uco-collected.png";
import ucoRejected from "../../assets/images/services-icon/uco-rejected.png";
import averageDeposit from "../../assets/images/services-icon/average-deposit.png";
import depositPerMinute from "../../assets/images/services-icon/deposit-per-minute.png";

import { simplify, formatThousandSeparator } from "helpers/functions";

const OverviewCards = ({ datas }) => {
    const activeBoxes = datas?.boxes?.filter(box => box.status !== 'planned')??[];

    return (
        <Row>
            <Col lg={'5col'} md={6} sm={6}>
                <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                        <div className="mb-4">
                            <div className="float-start mini-stat-img me-3">
                                <img src={deployedBoxes} alt="" />
                            </div>
                            <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                UCOllect&nbsp;Boxes
                            </h5>
                            <h4 className="fw-medium font-size-22">
                                {datas?.boxes?.length || 0}{" "} ({activeBoxes?.length??0} active)
                            </h4>
                        </div>
                        <div className="pt-2">
                            <div className="float-end">
                                <Link to="#" className="text-white-50">
                                    <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1"></p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={'5col'} md={6} sm={6}>
                <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                        <div className="mb-4">
                            <div className="float-start mini-stat-img me-3">
                                <img src={ucoCollected} alt="" />
                            </div>
                            <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                UCO&nbsp;Accepted
                            </h5>
                            <h4 className="fw-medium font-size-22">
                                {datas?.length !== 0 ? datas?.count[0]?.acc_weight > 10000 ? simplify(datas?.count[0]?.acc_weight / 1000) + " t" : simplify(datas?.count[0]?.acc_weight || 0) + " kg" : 0+" kg"}{" "}
                            </h4>
                        </div>
                        <div className="pt-2">
                            <div className="float-end">
                                <Link to="#" className="text-white-50">
                                    <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1"> </p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={'5col'} md={6} sm={6}>
                <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                        <div className="mb-4">
                            <div className="float-start mini-stat-img me-3">
                                <img src={ucoRejected} alt="" />
                            </div>
                            <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                UCO&nbsp;Rejected
                            </h5>
                            <h4 className="fw-medium font-size-22">
                                {datas?.length !== 0 ? formatThousandSeparator(simplify(datas?.count[0]?.rej_weight || 0).toString()) : 0} kg{" "}
                            </h4>
                        </div>
                        <div className="pt-2">
                            <div className="float-end">
                                <Link to="#" className="text-white-50">
                                    <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1"> </p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={'5col'} md={6} sm={6}>
                <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                        <div className="mb-4">
                            <div className="float-start mini-stat-img me-3">
                                <img src={averageDeposit} alt="" />
                            </div>
                            <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                Average&nbsp;Deposit
                            </h5>
                            <h4 className="fw-medium font-size-22">
                                {datas?.length !== 0 ? simplify((datas?.count[0]?.total || 0) === 0 ? 0 : (datas?.count[0]?.acc_weight || 0) / datas?.count[0]?.total) : 0} kg{" "}
                            </h4>
                        </div>
                        <div className="pt-2">
                            <div className="float-end">
                                <Link to="#" className="text-white-50">
                                    <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1"> </p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={'5col'} md={6} sm={6}>
                <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                        <div className="mb-4">
                            <div className="float-start mini-stat-img me-3">
                                <img src={depositPerMinute} alt="" />
                            </div>
                            <h5 className="font-size-14 mt-0 text-white-50" style={{ overflowWrap: 'normal' }}>
                                Deposits&nbsp;/&nbsp;Day
                            </h5>
                            <h4 className="fw-medium font-size-22">
                                {datas?.length !== 0 ? (simplify(datas?.depositPerDay || 0, 2)) : 0}{" "}
                            </h4>
                        </div>
                        <div className="pt-2">
                            <div className="float-end">
                                <Link to="#" className="text-white-50">
                                    <i className=" mdi mdi-arrow-right h5 d-none"></i>
                                </Link>
                            </div>
                            <p className="text-white-50 mb-0 mt-1"> </p>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default OverviewCards