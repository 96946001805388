import { Button } from "reactstrap"

const { CURRENT_WITHDRAWAL_PLATFORM, ENV_IS_PROD } = require("constants/value")

const BalanceButton = ({ withdrawalPlatform, balance ,sgBalance}) => {
console.log("Sgb",sgBalance)
  if (withdrawalPlatform == "AIRWALLEX") { 
    const openAWX = () => {

      window.open("https://www.airwallex.com/app/login", "_blank")

    }
    return (
      <>
        <div className="my-2">
          <Button color="primary" onClick={openAWX}>Balance: {sgBalance}</Button>
        </div>
      </>
    )
  } else {
      const openXendit = () => {
  
        window.open("https://dashboard.xendit.co/", "_blank")
  
      }
      return (
        <>
          <div className="my-2">
            <Button color="primary" onClick={openXendit}>Balance: {balance}</Button>
          </div>
        </>
      )
  }
}

export default BalanceButton
