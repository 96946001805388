import React, { useEffect, useState } from "react"
import {
    Button,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
} from "reactstrap"
import InputMask from "react-input-mask"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from 'primereact/inputswitch';

import * as Yup from "yup"
import { useFormik } from "formik"

import { post, del, get, put } from "../../../../../helpers/api_helper"
import useAdminPartner from "hooks/useAdminPartner"
import useAuth from "hooks/useAuth"
import useAxiosPrivate from "hooks/useAxiosPrivate"

import "../../../../../assets/css/style.css"
import alfamartLogo from '../../../../../assets/images/partner/alfamartLogo.svg'
import mypertaminaLogo from '../../../../../assets/images/partner/mypertamina.svg'

const EditAdminPartnerModal = ({ admin }) => {
    const formatPhoneNumber = phone_number => {
        let rawPhoneNumber = null
        let rawPhonePrefix = "+62"

        if (phone_number) {
            phone_number.split(" ").forEach(element => {
                if (element.startsWith("+")) {
                    rawPhonePrefix = element
                } else {
                    rawPhoneNumber = element
                }
            })
        }

        return { rawPhoneNumber, rawPhonePrefix }
    }

    const axiosApi = useAxiosPrivate()
    const { showToast } = useAuth()
    const { setRefreshAdminPartners } = useAdminPartner()

    const { rawPhoneNumber, rawPhonePrefix } = formatPhoneNumber(
        admin?.phone_number
    )
    const [modal_center, setmodal_center] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(rawPhoneNumber)
    const [selectedPrefix, setSelectedPrefix] = useState(rawPhonePrefix)
    const [prefixList, setPrefixList] = useState([])
    const [otpButtonState, setOtpButtonState] = useState(false)
    const [levels, setLevels] = useState(admin.level.split(","))
    const [superAdmin, setSuperAdmin] = useState("")
    const [permissions, setPermissions] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [userDataSharing, setUserDataSharing] = useState(admin?.setting?.data_sharing ?? false);

    const handleResetOTP = async () => {
        //send an alert and handle if user choose yes
        //a javascript alert
        const confirmReset = window.confirm(
            "Are you sure you want to reset the OTP of this user?"
        )
        if (confirmReset) {
            // User clicked "OK"
            setOtpButtonState(true)
            //do a post request using axiosApi
            const formData = { id: admin._id }
            const response = await axiosApi.post(
                "/api/admin/resetOTP",
                formData
            )
            //check if response is succesful
            if (response.status == 200) {
                setRefreshAdminPartners(response.random)
                tog_center()
                showToast(response)
            } else {
                showToast(response)
            }
        }
    }

    const tog_center = () => {
        setmodal_center(!modal_center)
        removeBodyCss()
        const { rawPhoneNumber, rawPhonePrefix } = formatPhoneNumber(
            admin?.phone_number
        )
        setPhoneNumber(rawPhoneNumber)
        setSelectedPrefix(rawPhonePrefix)
        setShowPassword(false);
        setUserDataSharing(admin?.setting?.data_sharing ?? false)
        validation.resetForm()
    }

    const getPermissions = async () => {
        try {
            const response = await axiosApi.get("/api/admin/permission")
            response.data.result.forEach(element => {
                if (element.title.toLowerCase() == "superadmin")
                    setSuperAdmin(element.key)
            })
            setPermissions(response.data.result)
        } catch (err) {
            console.log(err)
        }
    }

    const getCountryCodes = async () => {
        try {
            const response = await axiosApi.get("/api/v1/app/countrylist")
            var prefixes = []

            if (response.data)
                response.data.forEach(element => {
                    var prefix = {
                        ...element,
                        value: element.phonePrefix,
                    }
                    prefixes.push(prefix)
                })
            setPrefixList(prefixes)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (modal_center) {
            getPermissions()
            getCountryCodes()
        }
    }, [modal_center])

    const removeBodyCss = () => {
        document.body.classList.add("no_padding")
    }

    const changePrefix = e => {
        setSelectedPrefix(e.value)
    }

    const updateLevel = value => {
        setLevels(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value]
            } else {
                return prevLevels.filter(level => level !== value)
            }
        })
    }

    const handlePhoneNumber = e => {
        let { name, value } = e.target
        if (value.startsWith("0")) {
            value = value.slice(1) // Remove the first character
        }
        setPhoneNumber(value)
        validation.setFieldValue(value) // Update formik's value
    }

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: admin._id,
            name: admin.name,
            // username: admin.username,
            email: admin.email,
            password: "",
            phone_number: "",
            data_sharing: admin?.setting?.data_sharing ?? false,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("This value is required"),
            // username: Yup.string().required("This value is required"),
            password: Yup.string(),
            phone_number: !phoneNumber
                ? Yup.string().required("This value is required")
                : Yup.string().nullable(),
            email: Yup.string()
                .email("Must be a valid Email")
                .max(255)
                .required("Email is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                var levelText = levels.join(",") // You can use any delimiter you prefer, e.g., ", " or " "

                const opts = {
                    ...values,
                    phone_number: phoneNumber
                        ? selectedPrefix + " " + phoneNumber
                        : "",
                }

                const response = await post("/api/admin/brand/update", opts)
                setRefreshAdminPartners(response.random)
                resetForm()
                tog_center()
                showToast(response)
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.state
                ) {
                    showToast(error.response.data)
                    validation.setErrors(error.response.data)
                } else {
                    let response = {}
                    response.state = "error"
                    response.toast = true
                    response.message = "Internal Server Error"
                    showToast(response)
                }
            }
        },
    })

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-cog"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>
                    Edit Partner Dashboard User &nbsp;&nbsp;
                    <img src={admin?.brand === 'mypertamina' ? mypertaminaLogo : alfamartLogo} alt="logo's partner" style={{ width: '90px' }} />
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >
                        {/* INPUT NAME */}
                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.name}
                                </FormFeedback>
                            ) : null}
                        </div>
                        {/* END OF INPUT NAME */}

                        {/* INPUT USERNAME */}
                        {/* <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                                name="username"
                                placeholder="Enter Username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                    validation.touched.username &&
                                    validation.errors.username
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.username}
                                </FormFeedback>
                            ) : null}
                        </div> */}
                        {/* END OF INPUT USERNAME */}

                        {/* INPUT PHONE NUMBER */}
                        <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <div className="d-flex">
                                <Dropdown
                                    value={selectedPrefix}
                                    onChange={changePrefix}
                                    options={prefixList}
                                    optionLabel="name"
                                    className="h-1 payment-status-dropdown me-1"
                                />
                                <InputGroup
                                    className={
                                        validation.touched.phone_number &&
                                        validation.errors.phone_number
                                            ? "is-invalid"
                                            : ""
                                    }
                                >
                                    <InputGroupText>
                                        {selectedPrefix}
                                    </InputGroupText>
                                    <InputMask
                                        placeholder="Enter Phone Number"
                                        value={phoneNumber}
                                        mask="999999999999999" // Mask for date format
                                        onChange={handlePhoneNumber}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.phone_number &&
                                            validation.errors.phone_number
                                                ? true
                                                : false
                                        }
                                        maskChar={null} // Removes default mask characters (like "_")
                                    >
                                        {inputProps => (
                                            <Input
                                                {...inputProps}
                                                id="masked-input"
                                                type="text"
                                            />
                                        )}
                                    </InputMask>
                                    {validation.touched.phone_number &&
                                    validation.errors.phone_number ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.phone_number}
                                        </FormFeedback>
                                    ) : null}
                                </InputGroup>
                            </div>
                        </div>
                        {/* END OF INPUT PHONE NUMBER */}

                        {/* INPUT EMAIL */}
                        <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                                name="email"
                                placeholder="Enter Valid Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.email}
                                </FormFeedback>
                            ) : null}
                        </div>
                        {/* END OF INPUT EMAIL */}

                        {/* INPUT LEVEL */}
                        {!admin.level.includes("superadmin") && (
                            <div className="mb-3">
                                <Label className="form-label">Level</Label>
                                <FormGroup
                                    className="d-flex flex-row gap-3"
                                    style={{ flexWrap: "wrap" }}
                                >
                                    {permissions?.map(permission => (
                                        <FormGroup
                                            check
                                            key={permission.key}
                                            style={{
                                                flex: "1 0 22%",
                                                maxWidth: "22%",
                                                boxSizing: "border-box",
                                            }}
                                        >
                                            <Input
                                                id={permission.key}
                                                name="permission[]"
                                                type="checkbox"
                                                value={permission.key}
                                                defaultChecked={levels.includes(
                                                    permission.key
                                                )}
                                                onChange={e => {
                                                    const clickedValue =
                                                        e.target.value // Get the value of the clicked checkbox
                                                    if (
                                                        clickedValue ==
                                                        superadmin
                                                    ) {
                                                        const allCheckboxes =
                                                            document.querySelectorAll(
                                                                'input[name="permission[]"]'
                                                            )
                                                        if (e.target.checked) {
                                                            allCheckboxes.forEach(
                                                                checkbox => {
                                                                    if (
                                                                        checkbox.value ==
                                                                        superadmin
                                                                    ) {
                                                                        updateLevel(
                                                                            checkbox.value
                                                                        )
                                                                    } else {
                                                                        checkbox.disabled = true
                                                                        if (
                                                                            checkbox.checked
                                                                        ) {
                                                                            checkbox.checked = false
                                                                            updateLevel(
                                                                                checkbox.value
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                            )
                                                        } else {
                                                            allCheckboxes.forEach(
                                                                checkbox => {
                                                                    if (
                                                                        checkbox.value ==
                                                                        superadmin
                                                                    ) {
                                                                        updateLevel(
                                                                            checkbox.value
                                                                        )
                                                                    } else {
                                                                        checkbox.disabled = false
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    } else {
                                                        if (e.target.checked) {
                                                            const clickedValue =
                                                                e.target.value // Get the value of the clicked checkbox
                                                            updateLevel(
                                                                clickedValue
                                                            )
                                                        } else {
                                                            const clickedValue =
                                                                e.target.value // Get the value of the clicked checkbox
                                                            updateLevel(
                                                                clickedValue
                                                            )
                                                        }
                                                    }
                                                }}
                                            />
                                            <Label htmlFor={permission.key}>
                                                {permission.title}
                                            </Label>
                                            {validation.touched.permission &&
                                            validation.errors.permission ? (
                                                <FormFeedback type="invalid">
                                                    {
                                                        validation.errors
                                                            .permission
                                                    }
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    ))}

                                    {/* {levelOptions?.map((level) => (
                                    <FormGroup check key={level.value}>
                                        <Input id={level.value} name="level" type="radio" value={level.value} defaultChecked={level.value === validation.values.level} onChange={validation.handleChange} />
                                        <Label htmlFor={level.value}>{level.label}</Label>
                                    </FormGroup>
                                ))} */}
                                </FormGroup>

                                {/* <FormGroup className="d-flex flex-row gap-3">
                                {levelOptions?.map((level) => (
                                    <FormGroup check key={level.value}>
                                        <Input id={level.value} name="level" type="radio" value={level.value} defaultChecked={level.value === validation.values.level} onChange={validation.handleChange} />
                                        <Label htmlFor={level.value}>{level.label}</Label>
                                    </FormGroup>
                                ))}
                            </FormGroup> */}
                            </div>
                        )}
                        {/* END OF INPUT LEVEL */}

                        {/* INPUT PASSWORD */}
                        <div className="mb-3">
                            <Label className="form-label">
                                Password (leave it blank if unchanged)
                            </Label>
                            <InputGroup>
                                <Input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                    invalid={
                                        validation.touched.password &&
                                        validation.errors.password
                                            ? true
                                            : false
                                    }
                                />
                                <InputGroupText role="button" onClick={() => setShowPassword(!showPassword)}>
                                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} />
                                </InputGroupText>
                                {validation.touched.password &&
                                validation.errors.password ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.password}
                                    </FormFeedback>
                                ) : null}
                            </InputGroup>
                        </div>
                        {/* END OF INPUT PASSWORD */}

                        {/* INPUT OTP */}
                        <div className="d-flex flex-wrap gap-2 justify-content-between">
                            {/* <Button
                                type="button"
                                color="danger"
                                disabled={otpButtonState}
                                onClick={() => handleResetOTP()}
                                style={{
                                    visibility: admin.otp_enabled
                                        ? "visible"
                                        : "hidden",
                                }}
                            >
                                Reset OTP
                            </Button> */}

                            <div className="d-flex align-items-center gap-2">
                                <Label className="form-label mb-0">User Data Sharing</Label>
                                <InputSwitch
                                    checked={userDataSharing}
                                    onChange={(e) => {
                                        setUserDataSharing(e.value)
                                        validation.setFieldValue("data_sharing", e.value)
                                    }}
                                />
                            </div>

                            <Button
                                type="submit"
                                color="primary"
                                disabled={validation.isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                        {/* END OF INPUT OTP */}
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default EditAdminPartnerModal
