import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
    removeCommas
} from "helpers/functions"
import dashImage from "../../../../assets/images/pickup/dash.png";
import grabImage from "../../../../assets/images/pickup/grab.png";

const ConfirmationModal = ({ isOpen, toggle, onConfirm, data, type, rawData, permissions=[] }) => {    

    let sortedData ,sortedRawData,partnerEmailAreEqual;
    //only defined this if type == "partner
    if (type == "partner") {
        sortedData= JSON.parse(data.partner_report_email_admin_role).slice().sort();
        sortedRawData = rawData?.partner_report_email_admin_role?.length>0 ? JSON.parse(rawData.partner_report_email_admin_role).slice().sort() : [];
        partnerEmailAreEqual  = JSON.stringify(sortedRawData) === JSON.stringify(sortedData);
    }



   const findTitleById = (id) => {
        if (permissions.length === 0) return 'Title not found';
        const menu = permissions.find(menuItem => menuItem.key === id);
        if (menu) {
            return menu.title;
        }

        return 'Title not found';
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Your Settings</ModalHeader>
            <ModalBody>
                {type == 'withdrawal' &&
                    <div className='d-flex flex-column gap-2'>
                        {/* WITHDRAWAL LIMITS */}
                        <div>
                            <h5>Withdrawal Limits :</h5>
                            {rawData.withdrawal_limit.min.IDR == removeCommas(data.min_idr)
                                ? <p> Minimum IDR : <b>{currencyFormatter(removeCommas(data.min_idr), 'IDR')}</b></p>
                                : <p>Minimum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.IDR, 'IDR')}</s> → <span className='text-primary'>{currencyFormatter(removeCommas(data.min_idr), 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.min.SGD == removeCommas(data.min_sgd)
                                ? <p>Minimum SGD : <b>{currencyFormatter(removeCommas(data.min_sgd), 'SGD')}</b></p>
                                : <p>Minimum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.min_sgd), 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.IDR == removeCommas(data.max_idr)
                                ? <p>Maximum IDR : <b>{currencyFormatter(removeCommas(data.max_idr, 'IDR'))}</b></p>
                                : <p>Maximum IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.max_idr), 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_limit.max.SGD == removeCommas(data.max_sgd)
                                ? <p>Maximum SGD : <b>{currencyFormatter(removeCommas(data.max_sgd), 'SGD')}</b></p>
                                : <p>Maximum SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_limit.max.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.max_sgd), 'SGD')}</span></p>
                            }
                        </div>
                        {/* END OF WITHDRAWAL LIMITS */}

                        {/* WITHDRAWAL THRESHOLDS */}
                        <div>
                            <h5>Withdrawal Thresholds</h5>
                            {rawData.withdrawal_threshold.min_withdraw_amount.IDR == removeCommas(data.withdrawal_threshold_min_idr)
                                ? <p>Minimum Amount IDR : <b>{currencyFormatter(removeCommas(data.withdrawal_threshold_min_idr), 'IDR')}</b></p>
                                : <p>Minimum Amount IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.withdrawal_threshold_min_idr), 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.min_withdraw_amount.SGD == removeCommas(data.withdrawal_threshold_min_sgd)
                                ? <p> Minimum Amount SGD : <b>{currencyFormatter(removeCommas(data.withdrawal_threshold_min_sgd), 'SGD')}</b></p>
                                : <p>Minimum Amount SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.min_withdraw_amount.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.withdrawal_threshold_min_sgd), 'SGD')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.IDR == removeCommas(data.withdrawal_threshold_fee_idr)
                                ? <p> Withdraw Fee IDR : <b>{currencyFormatter(removeCommas(data.withdrawal_threshold_fee_idr), 'IDR')}</b></p>
                                : <p>Withdraw Fee IDR : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.withdrawal_threshold_fee_idr), 'IDR')}</span></p>
                            }
                            {rawData.withdrawal_threshold.withdraw_fee.SGD == removeCommas(data.withdrawal_threshold_fee_sgd)
                                ? <p> Withdraw Fee SGD: <b>{currencyFormatter(removeCommas(data.withdrawal_threshold_fee_sgd), 'SGD')}</b></p>
                                : <p>Withdraw Fee SGD : <s className='text-danger'>{currencyFormatter(rawData.withdrawal_threshold.withdraw_fee.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.withdrawal_threshold_fee_sgd), 'SGD')}</span></p>
                            }
                        </div>
                        {/* END OF WITHDRAWAL THRESHOLDS */}

                        {/* AUTO WITHDRAWAL */}
                        <div>
                            <h5>Auto Withdrawal</h5>
                            {rawData.auto_withdrawal == data.withdrawal
                                ? <p><b>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.auto_withdrawal ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.withdrawal ? 'Enabled' : 'Disabled'}</span></p>
                            }
                        </div>
                        {/* END OF AUTO WITHDRAWAL */}

                        {/* VOLUME PARTNER PAYOUT */}
                        <div>
                            <h5> Volume Partner Payout</h5>
                            {rawData?.withdrawal_partner?.payout_time == data.partner_payout_time
                                ? <p>Time : <b>{data.partner_payout_time}</b></p>
                                : <p>Time : <s className='text-danger'>{rawData?.withdrawal_partner?.payout_time}</s> →  <span className='text-primary'>{data.partner_payout_time}</span></p>
                            }
                            {rawData?.withdrawal_partner.max_payout_amount?.IDR == removeCommas(data.max_partner_payout_idr)
                                ? <p> Maximum Payout IDR : <b>{currencyFormatter(removeCommas(data.max_partner_payout_idr), 'IDR')}</b></p>
                                : <p> Maximum Payout IDR : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_partner?.max_payout_amount?.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.max_partner_payout_idr), 'IDR')}</span></p>
                            }
                            {rawData?.withdrawal_partner?.max_payout_amount?.SGD == removeCommas(data.max_partner_payout_sgd)
                                ? <p> Maximum Payout SGD : <b>{currencyFormatter(removeCommas(data.max_partner_payout_sgd), 'SGD')}</b></p>
                                : <p> Maximum Payout SGD : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_partner?.max_payout_amount?.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.max_partner_payout_sgd), 'SGD')}</span></p>
                            }

                        </div>
                        {/* END OF VOLUME PARTNER PAYOUT */}

                        {/* VERIFIED USER WITHDRAWAL LIMIT */}
                        <div>
                            <h5> Verified User Withdrawal Limit </h5>
                            {rawData?.withdrawal_limit_verified?.IDR == removeCommas(data.verified_user_withdrawal_limit_idr)
                                ? <p> IDR : <b>{currencyFormatter(removeCommas(data.verified_user_withdrawal_limit_idr), 'IDR')}</b></p>
                                : <p> IDR : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_limit_verified?.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.verified_user_withdrawal_limit_idr), 'IDR')}</span></p>
                            }
                            {rawData?.withdrawal_limit_verified?.SGD == removeCommas(data.verified_user_withdrawal_limit_sgd)
                                ? <p> SGD : <b>{currencyFormatter(removeCommas(data.verified_user_withdrawal_limit_sgd), 'SGD')}</b></p>
                                : <p> SGD : <s className='text-danger'>{currencyFormatter(rawData?.withdrawal_limit_verified?.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(removeCommas(data.verified_user_withdrawal_limit_sgd), 'SGD')}</span></p>
                            }
                        </div>
                        {/* END OF VERIFIED USER WITHDRAWAL LIMIT */}
                    </div>
                }
                {type == 'general' &&
                    <div>
                        <div>
                            <h5>App Versions</h5>
                            <ul>
                            {rawData.version.android == data.android_v
                                ? <li>Android Version : <b>{data.android_v}</b></li>
                                : <li>Android Version : <s className='text-danger'>{rawData.version.android}</s> → <span className='text-primary'>{data.android_v}</span></li>
                            }
                            {rawData.version.ios == data.ios_v
                                ? <li>iOS Version : <b>{data.ios_v}</b></li>
                                : <li>iOS Version : <s className='text-danger'>{rawData.version.ios}</s> →  <span className='text-primary'>{data.ios_v}</span></li>
                            }
                            </ul>
                            <h5>Customer Service</h5>
                            <ul>
                            {rawData.customer_service?.id == data.whatsappIndonesia
                                ? <li>Indonesia : <b>{data.whatsappIndonesia}</b></li>
                                : <li>Indonesia: <s className='text-danger'>{rawData.customer_service?.id}</s> → <span className='text-primary'>{data.whatsappIndonesia}</span></li>
                            }
                            {rawData.customer_service?.sg == data.whatsappSingapore
                                ? <li>Singapore : <b>{data.whatsappSingapore}</b></li>
                                : <li>Singapore: <s className='text-danger'>{rawData.customer_service?.sg}</s> → <span className='text-primary'>{data.whatsappSingapore}</span></li>
                            }
                            {/* {rawData.customer_service?.my == data.whatsappMalaysia
                                ? <li>Malaysia : <b>{data.whatsappMalaysia}</b></li>
                                : <li>Malaysia: <s className='text-danger'>{rawData.customer_service?.my}</s> → <span className='text-primary'>{data.whatsappMalaysia}</span></li>
                            } */}
                        
                            </ul>
                        </div>
                    </div>
                }
                {type == 'pickup' &&
                 <div class="row">
                    <div class="col-6">
                        <h4><img className="w-25px" src={dashImage} /> Dash</h4>
                        <div>
                            <h5>Dash Pickup Service</h5>
                            <ul>
                            {rawData.pickup_dash.service_available == data.dashPickupAvailable
                                ? <li><b>{data.dashPickupAvailable ? 'Enabled' : 'Disabled'}</b></li>
                                : <li><s className='text-danger'>{rawData.pickup_dash.service_available ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.dashPickupAvailable ? 'Enabled' : 'Disabled'}</span></li>
                            }
                            </ul>
                        </div>
                    
                        <div>
                            <h5>Minimum Balance</h5>
                            <ul>
                            {rawData.pickup_dash.balance.min.IDR == data.dash_min_balance_idr
                                ? <li>IDR : <b>{currencyFormatter(data.dash_min_balance_idr, 'IDR')}</b></li>
                                : <li>IDR : <s className='text-danger'>{currencyFormatter(rawData.pickup_dash.balance.min.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.dash_min_balance_idr, 'IDR')}</span></li>}
                            {rawData.pickup_dash.balance.min.SGD == data.dash_min_balance_sgd
                                ? <li>SGD : <b>{currencyFormatter(data.dash_min_balance_sgd, 'SGD')}</b></li>
                                : <li>SGD : <s className='text-danger'>{currencyFormatter(rawData.pickup_dash.balance.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.dash_min_balance_sgd, 'SGD')}</span></li>
                            }
                            </ul>
                        </div>
                        <div>
                            <h5>Quantity (liter)</h5>
                            <ul>
                            {rawData.pickup_dash.quantity.min == data.dash_min_qty
                                ? <li> Minimum : <b>{data.dash_min_qty}</b></li>
                                : <li>Minimum : <s className='text-danger'>{rawData.pickup_dash.quantity.min}</s> →  <span className='text-primary'>{data.dash_min_qty}</span></li>
                            }
                            {rawData.pickup_dash.quantity.max == data.dash_max_qty
                                ? <li> Minimum : <b>{data.dash_max_qty}</b></li>
                                : <li>Minimum : <s className='text-danger'>{rawData.pickup_dash.quantity.max}</s> →  <span className='text-primary'>{data.dash_max_qty}</span></li>
                            }
                            </ul>
                        </div>
                        <div>
                            <h5>Maximum Distance (km): </h5>
                            <ul>
                            {rawData.pickup_dash.distance.max == data.dash_max_distance
                                ? <li><b>{data.dash_max_distance}</b></li>
                                : <li> <s className='text-danger'>{rawData.pickup_dash.distance.max}</s>  →  <span className='text-primary'>{data.dash_max_distance}</span></li>
                            }
                            </ul>
                        </div>
                        <div>
                            <h5>Fixed Price: </h5>
                            <ul>
                            {rawData.pickup.fixed_price_enabled == data.dash_fixed_price_enabled
                                ? <li>Status: <b>{data.dash_fixed_price_enabled ? 'Enabled' : 'Disabled'}</b></li>
                                : <li>Status: <s className='text-danger'>{rawData.pickup.fixed_price_enabled ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.dash_fixed_price_enabled ? 'Enabled' : 'Disabled'}</span></li>
                            }
                            {data.dash_fixed_price_enabled && 
                                <>
                                {rawData.pickup_dash.fixed_price == data.dash_fixed_price 
                                    ? <li>Fixed Price (IDR): <b>{data.dash_fixed_price}</b></li>
                                    : <li>Fixed Price (IDR):  <s className='text-danger'>{rawData.pickup_dash.fixed_price}</s>  →  <span className='text-primary'>{data.dash_fixed_price}</span></li>
                                }
                                </>
                            }
                            </ul>
                        </div>
                  
                    </div>
                    <div class="col-6">
                        <h4><img className="w-25px" src={grabImage} /> Grab</h4>
                        <div>
                        <div class="col-12">
                    <div>
                            <h5>Grab Pickup Service</h5>
                            <ul>
                            {rawData.pickup.service_available == data.pickupAvailable
                                ? <li><b>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</b></li>
                                : <li><s className='text-danger'>{rawData.pickup.service_available ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.pickupAvailable ? 'Enabled' : 'Disabled'}</span></li>
                            }
                            </ul>
                        </div>
                    </div>
                            <h5>Minimum Balance</h5>
                            <ul>
                            {rawData.pickup.balance.min.IDR == data.min_balance_idr
                                ? <li>IDR : <b>{currencyFormatter(data.min_balance_idr, 'IDR')}</b></li>
                                : <li>IDR : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.IDR, 'IDR')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_idr, 'IDR')}</span></li>}
                            {rawData.pickup.balance.min.SGD == data.min_balance_sgd
                                ? <li>SGD : <b>{currencyFormatter(data.min_balance_sgd, 'SGD')}</b></li>
                                : <li>SGD : <s className='text-danger'>{currencyFormatter(rawData.pickup.balance.min.SGD, 'SGD')}</s> →  <span className='text-primary'>{currencyFormatter(data.min_balance_sgd, 'SGD')}</span></li>
                            }
                            </ul>
                        </div>
                        <div>
                            <h5>Quantity (liter)</h5>
                            <ul>
                            {rawData.pickup.quantity.min == data.min_qty
                                ? <li> Minimum : <b>{data.min_qty}</b></li>
                                : <li>Minimum : <s className='text-danger'>{rawData.pickup.quantity.min}</s> →  <span className='text-primary'>{data.min_qty}</span></li>
                            }
                            {rawData.pickup.quantity.max == data.max_qty
                                ? <li> Minimum : <b>{data.max_qty}</b></li>
                                : <li>Minimum : <s className='text-danger'>{rawData.pickup.quantity.max}</s> →  <span className='text-primary'>{data.max_qty}</span></li>
                            }
                            </ul>
                        </div>
                        <div>
                            
                            <h5>Maximum Distance (km): </h5>
                            <ul>
                            {rawData.pickup.distance.max == data.max_distance
                                ? <li><b>{data.max_distance}</b></li>
                                : <li> <s className='text-danger'>{rawData.pickup.distance.max}</s>  →  <span className='text-primary'>{data.max_distance}</span></li>
                            }
                            </ul>
                        </div>
                  
                    </div>
              
                    </div>
                }
                {type == 'partner' &&
                    <div>
                        <div>
                            <h5>Send Report</h5>
                            {rawData.partner_send_report == data.sendReport
                                ? <p><b>{rawData.partner_send_report ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.partner_send_report ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.sendReport ? 'Enabled' : 'Disabled'}</span></p>
                            }
                            <h5>Automatic Reward Payouts</h5>
                            {rawData.partner_auto_reward_payout == data.autoRewardPayout
                                ? <p><b>{rawData.partner_auto_reward_payout ? 'Enabled' : 'Disabled'}</b></p>
                                : <p><s className='text-danger'>{rawData.partner_auto_reward_payout ? 'Enabled' : 'Disabled'}</s> →  <span className='text-primary'>{data.autoRewardPayout ? 'Enabled' : 'Disabled'}</span></p>
                            }
                            <h5>Partner Report BCC Recipients</h5>

                            {partnerEmailAreEqual
                                ?
                                <b>
                                    <ul>
                                        {sortedRawData.map((item, index) => (
                                            <li key={index}>{findTitleById(item)}</li>
                                        ))}
                                    </ul>
                                </b>

                                :
                                <div className='d-flex flex-row gap-2'>
                                    <s className='text-danger'>
                                        <ul>
                                            {sortedRawData.map((item, index) => (
                                                <li key={index}>{findTitleById(item)}</li>
                                            ))}
                                        </ul>
                                    </s> →
                                    <div className='text-primary'>
                                        <ul>
                                            {sortedData.map((item, index) => (
                                                <li key={index}>{findTitleById(item)}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Submit</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
