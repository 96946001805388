import { Card, CardBody, Col, Row } from "reactstrap"
import useAuth from "hooks/useAuth"
import FillingLevel from "./charts/FillingLevel"
import { simplify, formatThousandSeparator } from "helpers/functions"
import WeightChart from "./charts/WeightChart"
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBox from "hooks/useBox";

const Weight = ({ lazyState, groupFilter, refresh, boxIdFilter }) => {
    const axiosApi = useAxiosPrivate()
    const { statusFilter } = useBox()

    const [currentMonthWeight, setCurrentMonthWeight] = useState(0);
    const [lastMonthWeight, setLastMonthWeight] = useState(0);

    const [currentMonthWeightApproved, setCurrentMonthWeightApproved] = useState(0);
    const [currentMonthWeightNotApproved, setCurrentMonthWeightNotApproved] = useState(0);

    const [lastMonthWeightApproved, setLastMonthWeightApproved] = useState(0);
    const [lastMonthWeightNotApproved, setLastMonthWeightNotApproved] = useState(0);

    const [currentMonthName, setCurrentMonthName] = useState('');
    const [lastMonthName, setLastMonthName] = useState('');
    const [avgFillingPerDayThisMonth, setAvgFillingPerDayThisMonth] = useState(0);
    const [avgFillingPerDayLastMonth, setAvgFillingPerDayLastMonth] = useState(0);


    const [graphOptions, setGraphOptions] = useState(null)
    const [series, setSeries] = useState([
        {
            name: 'Weight',
            data: [],
        }
    ])
    const getTransactionGraph = async () => {
        try {
            var response;
            let statusQuery = '';

            switch (statusFilter) {
                case 'any':
                    statusQuery = '';
                    break;
                case 'closed':
                    statusQuery = '&isOpenStatus=false&status=active';
                    break;
                case 'open':
                    statusQuery = '&isOpenStatus=true&status=active';
                    break;
                case 'maintenance':
                    statusQuery = '&status=maintenance';
                    break;
                case 'planned':
                    statusQuery = '&status=planned';
                    break;
                case 'offline':
                    statusQuery = '&status=offline';
                    break;
                default:
                    statusQuery = '';
            }

            setGraphOptions(null)
            if (boxIdFilter) {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${boxIdFilter}${statusQuery}`);
            } else if (groupFilter && groupFilter != "all") {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${lazyState?.boxArr}&group=${groupFilter}${statusQuery}`);
            } else {
                response = await axiosApi.get(`/api/admin/engineer/getTransactionGraph?startDate=${lazyState?.startDate}&endDate=${lazyState?.endDate}&boxArr=${lazyState?.boxArr}${statusQuery}`);
            }
            const currentReport = response.data.response?.currentReport;
            if (currentReport.length > 0) {
                const currentMonth = new Date().getMonth() + 1;
                var previousMonth = currentMonth - 1;
                if (currentMonth == 1) {
                    previousMonth = 12
                }
                const filteredThisMonth = currentReport.filter(item => item._id.month === currentMonth);
                const filteredLastMonth = currentReport.filter(item => item._id.month === previousMonth);

                // MODIFIED THE DATA //
                const totalWeightThisMonth = filteredThisMonth.length > 0 ? filteredThisMonth[0].totalWeight : 0;
                const totalWeightLastMonth = filteredLastMonth.length > 0 ? filteredLastMonth[0].totalWeight : 0;

                const totalWeightThisMonthApproved = filteredThisMonth.length > 0 ? filteredThisMonth[0].totalWeightApproved : 0;
                const totalWeightLastMonthApproved = filteredLastMonth.length > 0 ? filteredLastMonth[0].totalWeightApproved : 0;

                const totalWeightThisMonthNotApproved = filteredThisMonth.length > 0 ? filteredThisMonth[0].totalWeightNotApproved : 0;
                const totalWeightLastMonthNotApproved = filteredLastMonth.length > 0 ? filteredLastMonth[0].totalWeightNotApproved : 0;
                
                const _currentMonthName = filteredThisMonth.length > 0 ? filteredThisMonth[0].monthName : 0;
                const _lastMonthName = filteredLastMonth.length > 0 ? filteredLastMonth[0].monthName : 0;

                const _avgFillingPerDayThisMonth = filteredThisMonth.length > 0 ? filteredThisMonth[0].averagePerDay : 0;
                const _avgFillingPerDayLastMonth = filteredLastMonth.length > 0 ? filteredLastMonth[0].averagePerDay : 0;


                // SET DATA TO THE STATE
                setCurrentMonthWeightApproved(totalWeightThisMonthApproved)
                setCurrentMonthWeightNotApproved(totalWeightThisMonthNotApproved)
                
                setLastMonthWeightApproved(totalWeightLastMonthApproved)
                setLastMonthWeightNotApproved(totalWeightLastMonthNotApproved)

                setCurrentMonthWeight(totalWeightThisMonth)
                setLastMonthWeight(totalWeightLastMonth)

                setCurrentMonthName(_currentMonthName)
                setLastMonthName(_lastMonthName)

                setAvgFillingPerDayThisMonth(_avgFillingPerDayThisMonth)
                setAvgFillingPerDayLastMonth(_avgFillingPerDayLastMonth)
            } else {
                setLastMonthWeight(0)
                setCurrentMonthWeight(0)

            }
            setSeries([
                {
                    name: 'Weight',
                    data: response.data.response.chartWeight,
                }
            ])

            setGraphOptions(
                {
                    colors: ['#70ad47'],
                    chart: {
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false,
                        },
                        type: 'area',
                        stroke: {
                            curve: 'smooth',
                            width: 0.1,
                        },
                    },
                    legend: {
                        show: false
                    },

                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    grid: {
                        borderColor: '#f8f8fa',
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: response.data.response.chartPeriod,
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: true,
                            rotate: -60,
                            rotateAlways: response.data.response.chartPeriod.length > 20 ? true : false,
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                return simplify(value ?? 0)
                            }
                        }
                    }
                }
            )



        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        getTransactionGraph();
    }, [lazyState, refresh, groupFilter, boxIdFilter]);

    const { thisMonth, lastMonth } = useAuth();
    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Monthly UCO Collected</h4>
                        <Row>
                            <Col lg={7}>
                                <div>
                                    {graphOptions && <WeightChart series={series} options={graphOptions} />
                                    }

                                </div>
                            </Col>
                            <Col lg={5}>
                                <Row>
                                    <Col md={6}>
                                        <div className="text-center">
                                            <p className="text-muted mb-0">This month</p>
                                            <p className="font-size-17">{`(${currentMonthName})`}</p>
                                            <h3>{formatThousandSeparator(simplify(currentMonthWeight || 0, 0).toString())} kg</h3>
                                            <p className="font-size-16 mb-4">
                                                {`${formatThousandSeparator(simplify(avgFillingPerDayThisMonth || 0, 0).toString())} kg/day`}
                                            </p>
                                            <FillingLevel
                                                approved={
                                                    ((currentMonthWeightApproved || 0) / (currentMonthWeightApproved + currentMonthWeightNotApproved || 1)) * 100
                                                }
                                                notApproved={
                                                    (((currentMonthWeightNotApproved || 0) / (currentMonthWeightApproved + currentMonthWeightNotApproved || 1)) * 100)
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-center">
                                            <p className="text-muted mb-0">Last month</p>
                                            <p className="font-size-17">{`(${lastMonthName})`}</p>
                                            <h3>{formatThousandSeparator(simplify(lastMonthWeight || 0, 0).toString())} kg</h3>
                                            <p className="font-size-16 mb-4">
                                                {`${formatThousandSeparator(simplify(avgFillingPerDayLastMonth || 0, 0).toString())} kg/day`}
                                            </p>
                                            <FillingLevel
                                                approved={
                                                    ((lastMonthWeightApproved || 0) / (lastMonthWeightApproved + lastMonthWeightNotApproved || 1)) * 100
                                                }
                                                notApproved={
                                                    ((lastMonthWeightNotApproved || 0) / (lastMonthWeightApproved + lastMonthWeightNotApproved || 1)) * 100
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Weight