import moment from "moment"
import { Link } from "react-router-dom"
export const simplify = (value, decimal = 2) => {
  return (
    Math.round((value + Number.EPSILON) * Math.pow(10, decimal)) /
    Math.pow(10, decimal)
  )
}

export const currencyFormatter = (value, currency = "USD") => {
  let result
  switch (currency) {
    case "USD":
      result = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(value)
      break

    case "SGD":
      result = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(value)
      break

    case "IDR":
      result = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value)
      break

    default:
      result = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value)
      break
  }

  return result
}

export const IDRFormatter = amount => {
  return `Rp ${currencyFormatter(amount ?? 0, "IDR")}`
}

export const startTime = data => {
  return moment(data.TA_Start_Time).format("MM/DD/YYYY HH:mm:ss")
}

export const createTime = data => {
  return moment(data.createdAt).format("MM/DD/YYYY HH:mm:ss")
}

export const endTime = data => {
  return moment(data.TA_End_Time).format("MM/DD/YYYY HH:mm:ss")
}

export const inputDateTime = data => {
  return moment(data.createdAt).format("MM/DD/YYYY")
}

export const inputDateMonth = (date, format = "MM/YYYY") => {
  return moment(date).format(format)
}

export const inputDateTimeHour = data => {
  return moment(data.createdAt).format("MM/DD/YYYY HH:mm:ss")
}

export const methodId = data => {
  if (data?.source == 'partner') {
    return "Partner Payment"

  } else {
    return data.mtId || "-"

  }
}

export const userAddress = data => {
  if (!data.address) return "-"
  const { full_address, city, country, postcode } = data.address
  const formatAddress = `${full_address}, ${city}, ${country}, ${postcode}`
  return formatAddress
}

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }
}

export const balanceFormatter = (value, currency) => {
  const formattedValue = currencyFormatter(value, currency)
  let result
  switch (currency) {
    case "IDR":
      result = `Rp ${formattedValue}`
      break
    case "USD":
      result = `$ ${formattedValue}`
      break
    case "SGD":
      result = `S$ ${formattedValue}`
      break
    default:
      result = `${formattedValue}`
      break
  }

  return result
}

export const convertTimeFromUTC = async (arrTime, range) => {
  let currentWeek = moment().isoWeek()
  if (currentWeek % 2 == 0) {
    //if even
    currentWeek = moment().subtract(1, "week").isoWeek()
  }

  const hoursInDay = 24
  const daysInWeek = 7
  const weeksInMonth = 4
  const monthsInYear = 12
  const daysInMonth = moment().utc().daysInMonth()

  let categories = []
  let formatter = []

  switch (range) {
    case "24h":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[hoursInDay - 1 - i] = moment()
            .subtract(i, "hour")
            .hour()
            .toString()
          formatter[hoursInDay - 1 - i] = `${moment()
            .subtract(i, "hour")
            .startOf("hour")
            .format("HH:mm")} - ${moment()
            .subtract(i, "hour")
            .endOf("hour")
            .format("HH:mm")}`
        })
      )
      break
    case "7d":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[daysInWeek - 1 - i] = moment()
            .subtract(i, "day")
            .format("dddd")
          formatter[daysInWeek - 1 - i] = moment()
            .subtract(i, "day")
            .format("ddd, DD MMM YYYY")
        })
      )
      break
    case "1m":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[daysInMonth - 1 - i] = moment()
            .subtract(i, "day")
            .format("DD")
          formatter[daysInMonth - 1 - i] = moment()
            .subtract(i, "day")
            .format("ddd, DD MMM YYYY")
        })
      )
      break
    case "3m":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[3 * weeksInMonth - 1 - i] = moment()
            .subtract(i, "week")
            .format("DD MMM")
          formatter[3 * weeksInMonth - 1 - i] = `${moment()
            .subtract(i, "week")
            .startOf("isoWeek")
            .format("DD MMM YYYY")} - ${moment()
            .subtract(i, "week")
            .endOf("isoWeek")
            .format("DD MMM YYYY")}`
        })
      )
      break
    case "6m":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[(6 * weeksInMonth) / 2 - 1 - i] = moment()
            .subtract(i * 2, "week")
            .format("DD MMM")
          formatter[(6 * weeksInMonth) / 2 - 1 - i] = `${moment()
            .isoWeek(currentWeek)
            .subtract(i * 2, "week")
            .startOf("isoWeek")
            .format("DD MMM YYYY")} - ${moment()
            .isoWeek(currentWeek + 1)
            .subtract(i * 2, "week")
            .endOf("isoWeek")
            .format("DD MMM YYYY")}`
        })
      )
      break
    case "12m":
      await Promise.all(
        arrTime.map(async (time, i) => {
          categories[monthsInYear - 1 - i] = moment()
            .subtract(i, "month")
            .format("DD MMM")
          formatter[monthsInYear - 1 - i] = `${moment()
            .subtract(i, "month")
            .startOf("month")
            .format("DD")} - ${moment()
            .subtract(i, "month")
            .endOf("month")
            .format("DD MMM YYYY")}`
        })
      )
      break
  }

  return {
    categories,
    formatter,
  }
}

export const getFormattedUTCDate = today => {
  const year = today.getUTCFullYear()
  const month = String(today.getUTCMonth() + 1).padStart(2, "0")
  const day = String(today.getUTCDate()).padStart(2, "0")
  const hours = String(today.getUTCHours()).padStart(2, "0")
  const minutes = String(today.getUTCMinutes()).padStart(2, "0")
  const seconds = String(today.getUTCSeconds()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  return formattedDate
}
export const numberFormatter = (value) => {
  let result = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
  return result
}

export const formatTimeToUTC = (d) => {
  const year = d.getUTCFullYear();
  const month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = d.getUTCDate().toString().padStart(2, '0');
  const hours = d.getUTCHours().toString().padStart(2, '0');
  const minutes = d.getUTCMinutes().toString().padStart(2, '0');
  const seconds = d.getUTCSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const statusUco = (data) => {
  var badgetText = 'danger';
  var statusText = data.uco_unit.status_name;
  if (data.uco_unit.status_id == 1) {
    badgetText = 'success';
  }
  var attributesText = [];
  var attributes = data.uco_unit.uco_attributes || [];

  attributes.forEach((attribute) => {
    attributesText[attribute.uco_unit_status_id] = {
      code: attribute.incident_code,
      name: attribute.label,
    };
  });

  var attributesBadgeText = 'info';
  return (
    <div>
      <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
        {statusText}
      </span>
      {attributesText.length > 0 && (
        <div>
          {attributesText.map((attribute, index) => (
            <Link to={`/incidents`} state={attribute.code}>
              <span key={index} className={`badge bg-${attributesBadgeText} text-t-${attributesBadgeText}`} style={{ marginRight: 5 }}>
                {attribute.name}
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}
export const statusUcoIncident = (data) => {
    var badgetText = 'danger';
    var statusText = data.uco_unit.status_name;
    if (data.uco_unit.status_id == 1) {
      badgetText = 'success';
    }
    var attributesText = [];
  var attributes = data.uco_unit.uco_attributes || [];

    attributes.forEach((attribute) => {
      attributesText[attribute.uco_unit_status_id] = {
        code: attribute.incident_code,
        name: attribute.label,
      };
    });

    var attributesBadgeText = 'info';
    return (
      <div>
        <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
          {statusText}
        </span>
        {attributesText.length > 0 && (
          <div>
            {attributesText.map((attribute, index) => (
              <a href={`/incidents?id=${attribute.code}`} >
                <span key={index} className={`badge bg-${attributesBadgeText} text-t-${attributesBadgeText}`} style={{ marginRight: 5 }}>
                  {attribute.name}
                </span>
              </a>
            ))}
          </div>
        )}
      </div>
    )
  }
export const badgeAttributes = (data) => {

  var attributesBadgeText = 'info';
  var attributesText = [];

  var attributes = data.uco_attributes || [];
  attributes.forEach((attribute) => {
    attributesText[attribute.uco_unit_status_id] = {
      code: attribute.incident_code,
      name: attribute.label,
    };
  });

  return (
    <div>
      {attributesText.length > 0 ? (
        <div>
          {attributesText.map((attribute, index) => (
            <Link to={`/incidents`} state={attribute.code}>
              <span key={index} className={`badge bg-${attributesBadgeText} text-t-${attributesBadgeText}`} style={{ marginRight: index == attributesText.length - 1 ? 0 : 5 }}>
                {attribute.name}
              </span>
            </Link>
          ))}
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
    </div>
  )
}
export const badgeAttributesIncident = (data) => {

  var attributesBadgeText = 'info';
  var attributesText = [];

  var attributes = data.uco_attributes || [];

  attributes.forEach((attribute) => {
    attributesText[attribute.uco_unit_status_id] = {
      code: attribute.incident_code,
      name: attribute.label,
    };
  });

  return (
    <div>
      {attributesText.length > 0 ? (
        <div>
          {attributesText.map((attribute, index) => (
            <a href={`/incidents?id=${attribute.code}`} >
              <span key={index} className={`badge bg-${attributesBadgeText} text-t-${attributesBadgeText}`} style={{ marginRight: index == attributesText.length - 1 ? 0 : 5 }}>
                {attribute.name}
              </span>
            </a>
          ))}
        </div>
      ) : (
        <div>&nbsp;</div>
      )}
    </div>
  )
}

export const formatThousandSeparator = (value) => {
  if (!value) return "";
  
  // Split the number into integer and decimal parts
  const parts = value.replace(/,/g, "").split(".");

  // Format the integer part with commas
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer part with the decimal part (if any)
  return parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
};

export const formatDate = value => {
  return moment(value).format("MM/DD/YYYY")
}

export const handleThousandSeparatorChange = (e, validation) => {
  let { name, value } = e.target;

  // Allow only digits and decimal points
  value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

  // Ensure only one decimal point can be entered
  const dotCount = (value.match(/\./g) || []).length;
  if (dotCount > 1) {
    value = value.substring(0, value.length - 1); // Remove the extra dot
  }

  // Ensure value is more than 0
  if (value === "" || parseFloat(value) <= 0) {
    value = ""; // Optional: Reset value to empty if it's not valid
  } 

  const formattedValue = formatThousandSeparator(value);
  validation.setFieldValue(name, formattedValue); // Update formik's value
  
};

export const removeCommas = (value) => value.toString().replace(/,/g, "");

export const formatDateTime = (dateString) => {
  return dateString ? moment(dateString).format('MM/DD/YYYY HH:mm:ss') : ''
}

export const formatDateWithNoUTC = (dateObject) =>{

  let year = dateObject.getFullYear();
  let month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  let day = dateObject.getDate().toString().padStart(2, '0');
  let hours = dateObject.getHours().toString().padStart(2, '0');
  let minutes = dateObject.getMinutes().toString().padStart(2, '0');
  let seconds = dateObject.getSeconds().toString().padStart(2, '0');

  // Construct the formatted string
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00`;

}