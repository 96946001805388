import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";

const TransactionHistoryModal = ({ data, partnerLazyState, totalTxAmount, totalTrxValue }) => {
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('')
    const [modal_center, setmodal_center] = useState(false);
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;

    const checkBox = (data) => {
        return data?.box[0]?.name || 'deleted box'
    }

    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }

    const roundedVol = (data) => {
        return simplify(data.TA_UCO_Volume + data?.TA_Slops_Volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.TA_UCO_Weight + data?.TA_Slops_Weight)
    }

    const transactionAmount = (data) => {
        return balanceFormatter(data?.amount || 0, data?.user[0]?.currency)
    }
    const totalAmount = (data) => {
        return balanceFormatter(data?.totalAmount || 0, data?.user[0]?.currency)
    }


    const bonusAmount = (data) => {
        return balanceFormatter(data?.bonus || 0, data?.user[0]?.currency)
    }

    const columns = [
        { field: 'TA_ID', header: 'Transaction ID', body: '' },
        { field: 'box_name', header: 'Box', body: checkBox },
        { field: 'TA_UCO_Volume', header: 'Volume (liter)', body: roundedVol },
        { field: 'TA_UCO_Weight', header: 'Weight (kg)', body: roundedWeight },
        { field: 'TA_Start_Time', header: 'Date', body: startTime },
        // { field: 'amount', header: 'Amount', body: transactionAmount },
        { field: 'totalAmount', header: 'Amount', body: totalAmount },

    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }
    const showExtraData = (data, exDataName) => {
        const val = data?.extraData[exDataName];
        if (val === 0) {
            return '0';
        }
        return val ?? null
    }

    const boxFullAddress = (data) => {
        return data?.box[0]?.address || '-'
    }

    const boxAddressCoordinate = (data) => {
        console.log("ini location", data.box[0].location.latitude)
        if (data?.box[0]?.location.latitude) {
            return data?.box[0]?.location.latitude + ', ' + data?.box[0]?.location.longitude
        } else {
            return '-'
        }
    }

    const checkUser = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }

    const checkUsername = (data) => {
        return data?.user[0]?.username || 'deleted user'
    }

    const usernameAddress = (data) => {
        return data?.user[0]?.address + ', ' + data?.user[0]?.city + ' ' + data?.user[0]?.postcode + ', ' + data?.user[0]?.country || '-'
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Transaction ID : {data.TA_ID}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <label>Box :</label>
                        <ul>
                            <li>ID : {data.boxId}</li>
                            <li>Name : {checkBox(data)}</li>
                            <li>Address : {boxFullAddress(data)}</li>
                            <li>Coordinate : {boxAddressCoordinate(data)}</li>
                        </ul>

                        <label>User :</label>
                        <ul>
                            <li>ID :
                                <Link to={'/userlist'} state={data.TA_USER_ID}> {data.TA_USER_ID} </Link>
                            </li>
                            <li>Name : {checkUser(data)}</li>
                            <li>Username : {checkUsername(data)}</li>
                            <li>Address : {usernameAddress(data)}</li>

                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>The amount stored on box :</label>
                        <ul>
                            UCO :
                            <li>Weight : {simplify(data.TA_UCO_Weight + data?.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_UCO_Volume + data?.TA_Slops_Volume)} liter</li>
                        </ul>
                        <ul>
                            Slops :
                            <li>Weight : {simplify(data.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_Slops_Volume)} liter</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Transaction Time : </label>
                        <ul>
                            <li>Start : {startTime(data)}</li>
                            <li>End : {endTime(data)}</li>
                            <li>Duration : {duration(data)}</li>
                            <li>Amount : {transactionAmount(data)}</li>
                            <li>Bonus : {bonusAmount(data)}</li>
                        </ul>
                        {data?.extraData &&
                            <><label>Extra Information</label><ul>
                                {showExtraData(data, "hydrocarbon_level") &&
                                    <li>Hydrocarbon Level : {showExtraData(data, "hydrocarbon_level")}</li>}
                                {showExtraData(data, "alcohol_level") &&
                                    <li>Alcohol Level : {showExtraData(data, "alcohol_level")}</li>}
                                {showExtraData(data, "raw_max_level") &&
                                    <li>Raw Max Level : {showExtraData(data, "raw_max_level")}</li>}
                                {showExtraData(data, "raw_interface_level") &&
                                    <li>Raw Interface Level : {showExtraData(data, "raw_interface_level")}</li>}
                                {showExtraData(data, "density") &&
                                    <li>Density : {showExtraData(data, "density")} kg/m³</li>}
                                {showExtraData(data, "detail_transaction") &&
                                    <li>Transaction Detail : {showExtraData(data, "detail_transaction")}</li>}
                            </ul></>
                        }
                    </div>
                </div>
            </div>
        );
    };

    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            const requestBody = {
                ids: data?.userList,
                rows: lazyState.config.rows,
                skip: lazyState.config.first,
                searchText: lazyState.searchText,
                startDate: partnerLazyState.startDate,
                endDate: partnerLazyState.endDate,
            };
    
            const response = await axiosApi.post('/api/admin/support/partner-transaction', requestBody);
            const result = response.data.result;
            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setlazyState(initialLazyState);
        setSearch('');
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const getTransactionDisplayValue = () => {
        if (totalTxAmount !== undefined) {
            return balanceFormatter(totalTxAmount, 'IDR');
        } else if (totalTrxValue !== undefined) {
            return data?.totalTrx;
        }
        return null;
    }

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    return (
        <>
            <span className="text-primary" style={{ cursor: 'pointer' }} onClick={tog_center}>
                {/* {data.totalTrx + " (" + balanceFormatter(data.totalAmount || 0, 'IDR') + ")"} */}
                {getTransactionDisplayValue()}
            </span>
            <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
                <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="card-title m-0">Transaction History | Partner: {data?.name}</h4>
                        <div className="d-flex align-items-center gap-3 me-2">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                            </span>
                            <TransactionHistoryExportCSV lazyState={lazyState} data={data} partnerLazyState={partnerLazyState} />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="min-modal-body">
                    <DataTable
                        lazy
                        value={loading ? items : trans}
                        stripedRows
                        paginator
                        rows={lazyState.config.rows}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        size={'normal'}
                        onPage={onPage}
                        totalRecords={totalRecords}
                        first={lazyState.config.first}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                        scrollable
                        scrollHeight='500px'
                        expandedRows={loading ? null : expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowToggle={onRowToggle}
                        dataKey={loading ? '' : 'TA_ID'}
                    >
                        <Column expander={true} style={{ width: '3rem' }} />
                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                        ))}
                    </DataTable>
                </ModalBody>
            </Modal>
        </>
    )
}

export default TransactionHistoryModal
