import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Button,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap"
import { PButton } from "primereact/button"
import { getFormattedUTCDate } from "helpers/functions"
import moment from "moment"
import { DayPickerRangeController } from "react-dates"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";

const ExportIntakesModal = () => {
  const { showToast } = useAuth();
  const axiosApi = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalIntakesOpen, setIsModalIntakesOpen] = useState(false);
  const [options, setOptions] = useState({
    startDate: null,
    endDate: null,
    focusedInput: "startDate",
  })

  const handleToggleModal = () => {
    setIsModalIntakesOpen(!isModalIntakesOpen)
    removeBodyCss()
  }

  const onDatesChange = ({ startDate, endDate }) => {
    setOptions(prevOptions => ({
      ...prevOptions,
      startDate,
      endDate,
    }))
  }

  const onFocusChange = focusedInput => {
    setOptions(prevOptions => ({
      ...prevOptions,
      focusedInput: !focusedInput ? "startDate" : focusedInput,
    }))
  }

  const isOutsideRange = day =>
    moment.utc().local().diff(day.startOf("day")) < 0

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const convertStringToDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);

    const dateObject = new Date(year, month - 1, day);
    return dateObject;
  }

  const convertStringToDateWithEndTime = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);

    const dateObject = new Date(year, month - 1, day);
    dateObject.setHours(23, 59, 59, 999)
    return dateObject;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const startDateFilter = options.startDate && options.startDate.format('DD-MM-YYYY');

    if (options.endDate == null) {
        options.endDate = options.startDate
    }
    const endDateFilter = options.endDate ? options.endDate.format('DD-MM-YYYY') : options.startDate.format('DD-MM-YYYY')

    const startDate = getFormattedUTCDate(convertStringToDate(startDateFilter))
    const endDate = getFormattedUTCDate(convertStringToDateWithEndTime(endDateFilter))

    let _filters = { startDate, endDate };
    _filters.startDate = startDate;
    _filters.endDate = endDate;

    try {
      setIsLoading(true)
      const response = await axiosApi.get(`/api/erp/inventory/uco-export-transaction?start_date=${startDate}&end_date=${endDate}`, {
        responseType: 'blob'
      })

      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: 'text/csv'
      }));
      const link = document.createElement('a');
      link.href = url;

      const fileName = `UCO Units Transactions ${startDateFilter} - ${endDateFilter}`
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      showToast(response.data)
      setIsModalIntakesOpen(false)
      setOptions({
        startDate: null,
        endDate: null,
        focusedInput: 'startDate'
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsModalIntakesOpen(false)
      if (error.response && error.response.data && error.response.data.state) {
        showToast(error.response.data);
      }
      showToast(error)
    }
  }

  // FORMAT StartDate & EndDate
  let startDateString = options.startDate && options.startDate.format('MMM DD YYYY');
  let endDateString = options.endDate && options.endDate.format('MMM DD YYYY');

  return (
    <>
      <Button
        color="primary"
        size='sm'
        onClick={handleToggleModal}
        style={{ height: '35px' }}
      >
        Export Intakes
      </Button>

      <Modal isOpen={isModalIntakesOpen} toggle={handleToggleModal} size="lg" centered>
        <ModalHeader className="mt-0" toggle={handleToggleModal}>
          Intakes Period
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-around gap-2">
              <div>
                <DayPickerRangeController
                  startDate={options.startDate}
                  endDate={options.endDate}
                  onDatesChange={onDatesChange}
                  focusedInput={options.focusedInput}
                  onFocusChange={onFocusChange}
                  isOutsideRange={isOutsideRange}
                  numberOfMonths={2}
                />
                <Row className="align-items-center">
                  <Col>
                      <Input type="text" name="start_date" disabled placeholder="Start Date" value={startDateString} readOnly />
                  </Col> to
                  <Col>
                      <Input type="text" name="end_date" disabled placeholder="End Date" value={endDateString} readOnly />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-2 justify-content-end mt-3">
              <Button type="submit" color="primary" disabled={!startDateString || isLoading}>
                {isLoading ? <span className="mdi mdi-loading mdi-spin"></span> : 'Submit'}
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ExportIntakesModal
